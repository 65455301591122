import React, { useEffect, useState } from "react";
import { Col, Row, Table} from "react-bootstrap";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import {
DatatableWrapper,
Filter,
Pagination,
PaginationOptions,
TableBody,
TableHeader
} from 'react-bs-datatable';
import moment from 'moment';
import Main from "../../components/layout/Main";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
//React Icon
import { BsChevronRight } from "react-icons/bs";


const ReportList = () => {


const [body, setBody] = useState([]);

useEffect(() => {
  async function init() {
    const reports = await WhiteObjectApi.fetchReports();
    if (reports) 
    setBody(reports);
  }
  init();
}, []);

const header = [
    { title: 'Report Name', prop: 'name', isFilterable: true ,
    cell: (row) => (
      <Link
        to={"/reports/" + row.id}
        state={row}
      >
        {row.name}
      </Link>
    )
},
    { title: 'Created Date', prop: 'createddate', cell: (row) => (moment(row.createddate).format('DD-MM-YYYY')),isFilterable: true },
    { title: 'LastModified Date', prop: 'lastmodifieddate',cell: (row) => (moment(row.lastmodifieddate).format('DD-MM-YYYY')), isFilterable: true },
]

const labels = {
    beforeSelect: " "
  }
return (
<Main>
<>
      <Helmet>
        {/* <title>Report List</title> */}
      </Helmet>
  </>
<Row className="g-0">
<Col lg={2} className="mx-2">
<Link to="/home" style={{"text-decoration":"none"}}>Home</Link>
      <BsChevronRight/>
      Reports
      {/* <i className="fa-solid fa-chevron-right"></i>  */}
</Col>
<Col lg={12} className = "p-lg-4">

  {body ?
    <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
      initialState: {
        rowsPerPage: 10,
        options: [5, 10, 15, 20]
      }
    }}>
      <Row className="mb-4">
        <Col
          xs={12}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Filter />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-start align-items-start"
        >
          <PaginationOptions labels={labels} />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
        {/* <Button className="btn-sm" variant="outline-primary" >New Report</Button> */}
        </Col>
      </Row>
      <Table striped className="data-table">
        <TableHeader />
        <TableBody />
      </Table>
      <Pagination />

    </DatatableWrapper> : ''}
</Col>
<Col lg={2}></Col>
</Row>
</Main>
)
}

export default ReportList
