import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "./EnquiryForm.css"; // Ensure this file exists for custom styling
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from "moment"; //Added by shivani 6 aug bug 64

const LeadEdit = () => {
  const [validated, setValidated] = useState(false);
  const [enquiryData, setEnquiryData] = useState({});
  const [locationFetched, setLocationFetched] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [enquiryFormHide, setEnquiryFormHide] = useState(true);
  const [latitudev1, setLatitudev1] = useState(null);
  const [longitudev1, setLongitudev1] = useState(null);
  const [addressv1, setAddressv1] = useState(null);
  const [locationLink, setLocationLink] = useState(""); // Added state for location link
  const [timeSlot, setTimeSlot] = useState(''); //Added by shivani on 6 aug
  const [typeOfOrder, setTypeOrder] = useState('');  //Added by shivani on 6 aug

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setEnquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    enquiryData.time_slot = timeSlot;
    enquiryData.type_of_order = typeOfOrder;
    enquiryData.title = "Customer Enquiry";
    enquiryData.createdbyid = "59b02983-536c-4899-9c2e-86c7145b29b9";
    enquiryData.lastmodifiedbyid = "59b02983-536c-4899-9c2e-86c7145b29b9";

    const result = await WhiteObjectApi.createContactEnquiry(enquiryData);

    if (result) {
      setEnquiryFormHide(false);
      setSuccessMessage(true);
    } else {
      alert("Submission failed. Please try again.");
    }
  };

  const handleRadioChange = (event) => {
    setTypeOrder(event.target.value);
  };

  const handleTimeSlotChange = (event) => {
    setTimeSlot(event.target.value);
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const locationLink = `https://www.google.com/maps/?q=${latitude},${longitude}`;
          
          // Log the received coordinates and link for debugging
          console.log("Latitude: ", latitude);
          console.log("Longitude: ", longitude);
          console.log("Google Maps Link: ", locationLink);
  
          setEnquiryData({ ...enquiryData, location_link: locationLink });
          setLocationLink(locationLink);
          setLocationFetched(true);
        },
        (error) => {
          // Log the error for debugging
          console.error("Error fetching location: ", error);
          setLocationFetched(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLocationFetched(false);
    }
  };
  

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google || !window.google.maps) {
        const existingScript = document.querySelector(
          'script[src*="maps.googleapis.com"]'
        );
        if (!existingScript) {
          const googleMapScript = document.createElement("script");
          googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDtztwOW6bufAlbBWfO1k5FdZTkpUP_gmI&callback=initMap`;
          googleMapScript.async = true;
          googleMapScript.defer = true;
          window.document.body.appendChild(googleMapScript);
        }
      } else {
        window.initMap();
      }
    };

    window.initMap = () => {
      myMapFunction();
    };

    loadGoogleMapsScript();
  }, [latitudev1, longitudev1, addressv1]);

  const myMapFunction = async () => {
    if (!window.google) {
      console.error("Google Maps API is not loaded.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();

    if (latitudev1 && longitudev1) {
      const location = new window.google.maps.LatLng(latitudev1, longitudev1);
      const mapProp = {
        center: location,
        zoom: 14,
      };

      const map = new window.google.maps.Map(
        document.getElementById("googleMapaddress"),
        mapProp
      );

      const marker = new window.google.maps.Marker({
        map,
        position: location,
        title: addressv1,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: addressv1,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    } else if (addressv1) {
      geocoder.geocode({ address: addressv1 }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          setLatitudev1(location.lat());
          setLongitudev1(location.lng());

          const mapProp = {
            center: location,
            zoom: 14,
          };

          const map = new window.google.maps.Map(
            document.getElementById("googleMapaddress"),
            mapProp
          );

          const marker = new window.google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          setAddressv1(results[0].formatted_address);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    } else {
      console.error("Address is not provided.");
    }
  };
 
  //Added by shivani on 6 aug start
  const checkValidation = () => {
    let formErrors = {};
    if (!enquiryData.firstname) formErrors.firstname = "First Name is required";
    if (!enquiryData.lastname) formErrors.lastname = "Last Name is required";
    if (!enquiryData.phone) formErrors.phone = "Phone is required";
    if (!enquiryData.street) formErrors.street = "Complete Address is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  
  const getTodayDate = () => { //Added By shivani
    return moment().format("YYYY-MM-DD");
  };
  

  return (
    <Container className="d-flex justify-content-center align-items-center">
      {enquiryFormHide && (
        <Row className="w-100">
          <Col lg={8} className="mx-auto">
            <Form
              className="mt-3"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <img
                    src="whiteObjectFormLogo.png"
                    alt="Logo"
                    className="header-img-right"
                  />
                  <h5 className="mb-0 text-center flex-grow-1">Enquiry Form</h5>
                  <img
                    src="naveen.png"
                    alt="Naveen"
                    className="header-img-left"
                  />
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6} className="d-flex">
                      <Form.Group controlId="formBasicFirstName">
                        <Form.Label className="form-view-label">
                          First Name
                        </Form.Label>
                        <Form.Control
                          style={{ height: "36px", width: "218px" }}
                          required
                          type="text"
                          name="firstname"
                          placeholder="Enter First Name"
                          onChange={handleChange}
                          isInvalid={!!errors.firstname}  //Added by shivani on 6 aug
                          />
                          {/*  //Added by shivani on 6 aug */}
                          <Form.Control.Feedback type="invalid">
                            {errors.firstname}
                          </Form.Control.Feedback>
                  </Form.Group>
                    {/* Added by shivani on 6 aug bug 61  end*/}
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicLastName">
                        <Form.Label
                          className="form-view-label"
                          style={{ marginLeft: "10px" }}
                        >
                          Last Name
                        </Form.Label>
                        <Form.Control
                          style={{
                            height: "36px",
                            marginLeft: "10px",
                            width: "100%",
                          }}
                          required
                          type="text"
                          name="lastname"
                          placeholder="Enter Last Name"
                          onChange={handleChange}
                          isInvalid={!!errors.lastname}  //Added by shivani on 6 aug
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className="mt-2">
                      <Form.Group controlId="formBasicLastName">
                        <Form.Label className="form-view-label">
                          Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter Your Email"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mt-2">
                      <Form.Group controlId="formBasicLastName">
                        <Form.Label
                          style={{ marginLeft: "10px" }}
                          className="form-view-label"
                        >
                          Phone
                        </Form.Label>
                        <Form.Control
                          style={{
                            height: "36px",
                            marginLeft: "10px",
                            width: "100%",
                          }}
                          type="text" //Added by shivani  6 aug bug 62
                          name="phone"
                          placeholder="Enter Your Phone Number"
                          onChange={handleChange}
                          maxLength={10} //Added by shivani 6 aug bug 62
                          pattern="\d{10}"//Added by shivani  6 aug bug 62
                          isInvalid={!!errors.phone}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className="mt-2">
                      <Form.Group controlId="formBasicLastName">
                        <Form.Label className="form-view-label">
                          Complete Address
                        </Form.Label>
                        <Form.Control
                          style={{
                            height: "36px",
                            // marginLeft: "10px",
                            width: "100%",
                          }}
                          required
                          type="text"
                          name="street"
                          placeholder="Enter Your Site Address"
                          onChange={handleChange}
                          isInvalid={!!errors.street}  //Added by shivani on 6 aug
                          />
                          {/*  //Added by shivani on 6 aug */}
                          <Form.Control.Feedback type="invalid">
                            {errors.street}
                          </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mt-2">
                      <Form.Group controlId="formBasicLastName">
                        <Form.Label
                          style={{ marginLeft: "10px" }}
                          className="form-view-label"
                        >
                          No. Of Windows
                        </Form.Label>
                        <Form.Control
                          style={{
                            marginLeft: "10px",
                          }}
                          type="number"
                          name="qty_windows"
                          placeholder="Enter No. Of Windows"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className="mt-2">
                      <Form.Group controlId="formVisitType" className="mt-3">
                        <Form.Label style={{ marginLeft: "10px" }}>
                          Type Of Order
                        </Form.Label>
                        <div
                          className=""
                          style={{
                            justifyContent: "space-between",
                            marginLeft: "10px",
                          }}
                        >
                          <Form.Check
                            type="radio"
                            label="Commercial"
                            name="type_of_order"
                            id="Commercial"
                            className="me-3"
                            value="Commercial"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Residential"
                            name="type_of_order"
                            id="Residential"
                            value="Residential"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Hospital"
                            name="type_of_order"
                            id="Hospital"
                            value="Hospital"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Hotel"
                            name="type_of_order"
                            id="Hotel"
                            value="Hotel"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Factory"
                            name="type_of_order"
                            id="Factory"
                            value="Factory"
                            onChange={handleRadioChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mt-2">
                      <Form.Group controlId="formVisitType" className="mt-3">
                        <Form.Label style={{ marginLeft: "10px" }}>
                          Time Slot Of Visit
                        </Form.Label>
                        <div
                          className=""
                          style={{
                            justifyContent: "space-between",
                            marginLeft: "10px",
                          }}
                        >
                          <Form.Check
                            type="radio"
                            label="9 Am To 12 Noon"
                            name="time_slot"
                            id="9 Am To 12 Noon"
                            value="9 Am To 12 Noon"
                            onChange={handleTimeSlotChange}
                          />
                          <Form.Check
                            type="radio"
                            label="12 Noon To 3 Pm"
                            name="time_slot"
                            id="12 Noon To 3 Pm"
                            value="12 Noon To 3 Pm"
                            onChange={handleTimeSlotChange}
                          />
                          <Form.Check
                            type="radio"
                            label="3 Pm To 6 Pm"
                            name="time_slot"
                            id="3 Pm To 6 Pm"
                            value="3 Pm To 6 Pm"
                            onChange={handleTimeSlotChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Specific"
                            name="time_slot"
                            id="Specific"
                            value="Specific"
                            onChange={handleTimeSlotChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                  {timeSlot === "Specific" && (
                      <Col lg={6} className="mt-2">
                        <Form.Group controlId="formBasicLastName">
                          <Form.Label className="form-view-label">
                            Specific Time
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="specific_time"
                            placeholder="Enter Specific Time"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    <Col lg={6} className="mt-2">
                      <Form.Group controlId="formBasicLastName">
                        <Form.Label className="form-view-label">
                          Site Visit Date
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="site_visit_date"
                          placeholder="Select Site Visit Date"
                          // max={getTodayDate()} //Added by shivani 6 aug bug 64
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Button
                        variant="success"
                        type="button"
                        onClick={handleGetLocation}
                        className={timeSlot === "Specific" ?'mt-2' : 'mt-4'} //Added by shivani 6 aug bug 75
                      >
                        Get Location
                      </Button>
                      {locationFetched && locationLink && (
                        <div className="mt-2">
                          <a href={locationLink} target="_blank" rel="noopener noreferrer">
                            View Location on Google Maps
                          </a>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    
                    
                    <Col lg={6} className="mt-4">
                      {/* //Added  target="_blank" by shivani 6 aug bug 63 */}
                      <a href="https://www.saps.in/tapmecards/whiteobject/naveen.pdf"  target="_blank"> 
                        Details White Object
                      </a>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Button
                    className="d-flex justify-content-end align-items-end float-end" //Added by shivani 6 aug bug 74

                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Card className="text-center mt-3">
          <Card.Body>
          <div className="d-flex justify-content-center mt-3">
              <div className="blink green-tick rounded-circle">✔️</div>
            </div>
            {/* Added by shivani 6 aug bug 66 */}
            <h5>Thank you for reaching out. Your enquiry details has been submitted, and our team will contact you soon.</h5>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default LeadEdit;