import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import { isMobile, BrowserView } from 'react-device-detect';
import jwt_decode from "jwt-decode";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "../InfoPill";
import Main from "../layout/Main";
import { Helmet } from "react-helmet";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from '../../constants/CONSTANT';
import { Project_Stage_Status } from "../../constants/CONSTANT";

const LeadList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projectStatusArray, setProjectStatusArray] = useState(constants.Project_Stage_Status);
  const [userInfo, setUserInfo] = useState({});
  const [leadname, setLeadName] = useState();
  const [selectedProjectName, setSelectedProjectName] = useState(""); // State to hold selected project name

  useEffect(() => {
    async function fetchData() {
      const projects = await WhiteObjectApi.fetchProject();
      if (projects) {
        setBody(projects);
        setFilteredProjects(projects); // Initialize filtered projects with all projects

        // If projectName is in location state, filter projects by that name
        if (location.state?.projectName) {
          setSelectedProjectName(location.state.projectName);
          const filtered = projects.filter((project) => project.project_name.toLowerCase() === location.state.projectName.toLowerCase());
          setFilteredProjects(filtered);
        }
      } else {
        setBody([]);
        setFilteredProjects([]);
      }
    }

    fetchData();
  }, [location.state?.projectName]);

  const onFilterType = (event) => {
    const selectedStatus = event.target.value;
    if (selectedStatus === "") {
      setFilteredProjects(body); // Reset to show all projects
    } else {
      const filtered = body.filter((project) => project.project_status.toLowerCase() === selectedStatus.toLowerCase());
      setFilteredProjects(filtered);
    }
  };

  const onProjectNameFilter = (event) => {
    const selectedProjectName = event.target.value;
    setSelectedProjectName(selectedProjectName); // Update selected project name state
    if (selectedProjectName === "") {
      setFilteredProjects(body); // Reset to show all projects
    } else {
      const filtered = body.filter((project) => project.project_name.toLowerCase() === selectedProjectName.toLowerCase());
      setFilteredProjects(filtered);
    }
  }

  const getStatusClass = (status) => {
    const statusObj = Project_Stage_Status.find((value) => value.label === status);
    return statusObj ? statusObj.backgroundColor : '#FFFFFF';
  };

  const header = [
    {
      title: "Project Name",
      prop: "project_name",
      isFilterable: true, isSortable: true,
      cell: (row) => (
        
        <Link to={"/projects/" + row.id} state={row}>
          {row.project_name}
        </Link>
      ),
    },
    {
      title: "Contact Name",
      prop: "contactname",
      isFilterable: true, isSortable: true,
    },
    {
      title: "Lead Name",
      prop: "leadname",
      isFilterable: true, isSortable: true,
    },
    {
      title: "Project Status",
      prop: "project_status",
      isFilterable: true, isSortable: true,
      cell: (row) => (
        // <span bg={getStatusClass(row.project_status)} >
        //   {row.project_status}
        // </span>

        <span className="badge" style={{ backgroundColor: getStatusClass(row.project_status) ,display: "block", paddingBottom: "5px"}}>
          {row.project_status}
        </span>
      ),
    },
    {
      title: "Expected Delivery Date",
      prop: "expected_date_delivery",
      isSortable: true, isFilterable: true,
      cell: (row) => (
        <span>
          {row.expected_date_delivery ? moment(row.expected_date_delivery).format('DD MMM, yyyy') : 
          ""}
        </span>
      ),
    },
    {
      title: "Quote Approval Date",
      prop: "quote_approval_date",
      isSortable: true, isFilterable: true,
      cell: (row) => (
        <span>
          {row.quote_approval_date ? moment(row.quote_approval_date).format('DD MMM, yyyy') : 
          ""}
        </span>
      ),
    },
    {
      title: "Created Date",
      prop: "createddate",
      isSortable: true, isFilterable: true,
      cell: (row) => (
        <span>
          {moment(row.createddate).format('DD MMM, yyyy')}
        </span>
      ),
    },
    {
      title: "Assigned",
      prop: "ownername",
      isFilterable: true, isSortable: true,
      cell: (row) => (
        <Link to={"/users/" + row.projectmanagerid} state={row} className="align-items-center justify-content-start">
          <img src="https://www.w3schools.com/bootstrap4/img_avatar3.png" className="rounded-circle" style={{ height: "30px", width: "30px", objectFit: "cover" }} alt="" />
          <span className="mx-2">{row.ownername}</span>
        </Link>
      ),
    },
  ];

  const createProject = () => {
    navigate(`/projects/e`);
  };

  const labels = {
    beforeSelect: " ",
  };

  return (
    <Main>
      <Helmet>{LIST_TAB_TITLE.ProjectList}</Helmet>
      <Row className="g-0">
        <Col lg={12} className="px-4">
          <DatatableWrapper
            body={filteredProjects}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col xs={6} lg={3} className="d-flex flex-col justify-content-end align-items-end">
                <Filter />
              </Col>
              <Col xs={12} sm={6} lg={6} className="d-flex flex-col justify-content-start align-items-center">
                <PaginationOptions labels={labels} />
                <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                  <Form.Select aria-label="Enter status" name="projectstatus" onChange={onFilterType}>
                    <option value="">--Select Type--</option>
                    {projectStatusArray.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mx-3 mt-4" controlId="formBasicName">
                  <Form.Select aria-label="Enter project name" name="projectname" onChange={onProjectNameFilter} value={selectedProjectName}>
                    <option value="">--Select Project Name--</option>
                    {body.map((project, index) => (
                      <option value={project.project_name} key={index}>
                        {project.project_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <BrowserView>
                  <div style={{ position: "absolute", marginBlock: "-22px" }}>
                    <InfoPill left="Total" right={filteredProjects?.length} />
                  </div>
                </BrowserView>
              </Col>
              <Col xs={12} sm={6} lg={3} className="d-flex flex-col justify-content-end align-items-end">
                <Button className="btn-sm" variant="outline-primary mx-2" onClick={() => createProject(true)}>
                  New Project
                </Button>
              </Col>
            </Row>
            {body ? (
              <Table striped className="data-table" responsive="sm">
                <TableHeader />
                <TableBody />
              </Table>
            ) : (
              <ShimmerTable row={10} col={8} />
            )}
            <Pagination />
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </Main>
  );
};

export default LeadList;