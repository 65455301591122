export const API_BASE_URL = `${window.location.protocol}//${window.location.hostname}/ibs`;
// export const API_BASE_URL = "http://localhost:3003";
export const SOCKET_BASE_URL = "https://crm.whiteobject.in"; // Server Sub Domain
// export const SOCKET_BASE_URL = "http://103.175.163.63/"; //Server IP Addess
// export const SOCKET_BASE_URL = "http://localhost:3003";
// Only Create Permission Added By Farhan Khan on 23-oct-2023
// export const CREATE_ALL = "CREATE_ALL";

export const VIEW_LEAD = "VIEW_LEAD";
export const VIEW_PROPERTY = "VIEW_PROPERTY";
export const EDIT_LEAD = "EDIT_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";
export const VIEW_PRODUCT = "VIEW_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const MODIFY_ALL = "MODIFY_ALL";
export const VIEW_ALL = "VIEW_ALL";
export const VIEW_ORDER = "VIEW_ORDER";
export const VIEW_USER = "VIEW_USER";
export const VIEW_CONTACT = "VIEW_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const VIEW_ACCOUNT = "VIEW_ACCOUNT";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const VIEW_BUSINESS = "VIEW_BUSINESS";
export const EDIT_BUSINESS = "EDIT_BUSINESS";
export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const VIEW_INCIDENT = "VIEW_INCIDENT";
export const CREATE_ALL = "CREATE_ALL";
export const SUPER_ADMIN = "SUPER_ADMIN";

//FILE TYPES
export const PDF = "pdf";
export const DOC = "doc";
export const DOCX = "docx";
export const XLS = "xls";
export const XLSX = "xlsx";
export const CSV = "csv";
export const PNG = "png";
export const JPG = "jpg";
export const JPEG = "jpeg";

// export const LEAD_STATUS_VALUES = [{label : 'Open - Not Contacted', islast : false}, {label : 'Working Contacted', islast : false} , {label : 'Closed - Converted', islast : true}, {label : 'Closed - Not Converted', islast : true}];
export const LEAD_STATUS_VALUES = [
  {
    label: "Open - Not Contacted",
    sortorder: 1,
    is_converted: false,
    is_lost: false,
    backgroundColor: "#008080", // Teal
  },
  {
    label: "Working - Contacted",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
    backgroundColor: "#4682B4", // Steel Blue
  },
  {
    label: "Call",
    sortorder: 3,
    is_converted: true,
    is_lost: false,
    backgroundColor: "#5F9EA0", // Cadet Blue
  },
  {
    label: "Showroom Visit",
    sortorder: 4,
    is_converted: false,
    is_lost: false,
    backgroundColor: "#3CB371", // Medium Sea Green
  },
  {
    label: "Follow up",
    sortorder: 5,
    is_converted: false,
    is_lost: false,
    backgroundColor: "#6B8E23", // Olive Drab
  },
  {
    label: "Project",
    sortorder: 6,
    is_converted: false,
    is_lost: false,
    backgroundColor: "#CD853F", // Peru
  },
  {
    label: "Archive",
    sortorder: 7,
    is_converted: false,
    is_lost: false,
    backgroundColor: "#CD853F", // Peru
  },
  // {
  //   label: "Quotation Rejected",
  //   sortorder: 7,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#CD5C5C", // Indian Red
  // },
  // {
  //   label: "Converted",
  //   sortorder: 8,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#BC8F8F", // Rosy Brown
  // },
  // {
  //   label: "Advance Received",
  //   sortorder: 9,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#DAA520", // Goldenrod
  // },
  // {
  //   label: "Production Transfered",
  //   sortorder: 10,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#FF57A6", // Slate Blue
  // },
  // {
  //   label: "Production Stage",
  //   sortorder: 11,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#9370DB", // Medium Purple
  // },
  // {
  //   label: "Material Ordered",
  //   sortorder: 12,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#BA55D3", // Medium Orchid
  // },
  // {
  //   label: "Production Phase One",
  //   sortorder: 13,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#E9967A", // Dark Salmon
  // },
  // {
  //   label: "Production Phase Two",
  //   sortorder: 14,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#F08080", // Light Coral
  // },
  // {
  //   label: "Production Phase Three",
  //   sortorder: 15,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#6495ED", // Sandy Brown
  // },
  // {
  //   label: "Production Phase Four",
  //   sortorder: 16,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#BDB76B", // Dark Khaki
  // },
  // {
  //   label: "Production Phase Five",
  //   sortorder: 17,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#778899", // Light Slate Gray
  // },
  // {
  //   label: "Dispatched",
  //   sortorder: 18,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#7B68EE", // Medium Slate Blue
  // },
  // {
  //   label: "Installation",
  //   sortorder: 19,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#6495ED", // Cornflower Blue
  // },
  // {
  //   label: "Payment",
  //   sortorder: 20,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#66CDAA", // Medium Aquamarine
  // },
  // {
  //   label: "Project Completed",
  //   sortorder: 21,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#20B2AA", // Light Sea Green
  // },
  // {
  //   label: "Reparing Ticket",
  //   sortorder: 22,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#DB7093", // Pale Violet Red
  // },
  // {
  //   label: "Reparing Ticket Complete",
  //   sortorder: 23,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#9F99AC", // Adjusted for your colors
  // },
  // {
  //   label: "Just Entry",
  //   sortorder: 24,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#48162C", // Adjusted for your colors
  // },
  // {
  //   label: "Archived",
  //   sortorder: 25,
  //   is_converted: false,
  //   is_lost: false,
  //   backgroundColor: "#FFC300", // Adjusted for your colors
  // },
];

export const Product_CATEGORY_VALUES = [
  {
    label: "Software",
    sortorder: 1,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Hardware",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Fashion",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Retail",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Goods",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Furniture",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Stones",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Policy",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
  {
    label: "Others",
    sortorder: 2,
    is_converted: false,
    is_lost: false,
  },
];

export const Repair_CATEGORY_VALUES = [
  {
    label: "Ticket Created",
    sortorder: 1,
    backgroundColor: "#DB7093", // Pale Violet Red
  },

  {
    label: "In Discussion",
    sortorder: 2,
    backgroundColor: "#DB7093", // Pale Violet Red
  },
  {
    label: "Not Working",
    sortorder: 3,
    backgroundColor: "#66CDAA", // Medium Aquamarine
  },
  {
    label: "Advance Recived",
    sortorder: 4,
    backgroundColor: "#20B2AA", // Light Sea Green
  },
  {
    label: "Work Started",
    sortorder: 5,
    backgroundColor: "#6495ED", // Cornflower Blue
  },
  {
    label: "Work Completed",
    sortorder: 6,
    backgroundColor: "#E9967A", // Dark Salmon
  },
];

export const Project_Stage_Status = [
  {
    label: "Quote Sent",
    sortorder: 1,
    backgroundColor: "#483D8B", // Dark Slate Blue
  },
  {
    label: "Quote Rejected",
    sortorder: 2,
    backgroundColor: "#09C58A", // Saddle Brown
  },
  {
    label: "Quote Approved",
    sortorder: 3,
    backgroundColor: "#8B4513", // Saddle Brown
  },
  {
    label: "Advance Received",
    sortorder: 4,
    backgroundColor: "#2E8B57", // Sea Green
  },
  {
    label: "Transfer To Production",
    sortorder: 5,
    backgroundColor: "#FF6347", // Tomato
  },

  {
    label: "Production InProgress",
    sortorder: 6,
    backgroundColor: "#10B6D5", // Dodger Blue
  },
  {
    label: "Production Completed",
    sortorder: 7,
    backgroundColor: "#1E90FF", // Dodger Blue
  },
  {
    label: "Part Dispatched",
    sortorder: 8,
    backgroundColor: "#D43434", // Dodger Blue
  },
  {
    label: "Dispatched",
    sortorder: 9,
    backgroundColor: "#DAA520", // Goldenrod
  },
  {
    label: "Installation Started",
    sortorder: 10,
    backgroundColor: "#FF8C00", // Dark Orange
  },
  {
    label: "Installation Completed",
    sortorder: 11,
    backgroundColor: "#C71585", // Medium Violet Red
  },
  {
    label: "Fabricator Gets a Signed Slip",
    sortorder: 12,
    backgroundColor: "#8B008B", // Dark Magenta
  },
  {
    label: "Payment Received",
    sortorder: 13,
    backgroundColor: "#4682B4", // Steel Blue
  },
  {
    label: "Project Complete",
    sortorder: 14,
    backgroundColor: "#6A5ACD", // Slate Blue
  },
];

export const LIST_TAB_TITLE = {
  Home: "White Object System",
  // "ContactList":"Contact List",
  // "ContactEdit":"Contact Create",
  // "ContactCreate":"Contact Edit",
  // "ContactView":"Contact View",
  // "LeadList":"Lead List",
  // "LeadEdit":"Lead Create",
  // "LeadCreate":"Lead Edit",
  // "LeadView":"Lead View",
  // "ProductList":"Product List",
  // "ProductEdit":"Product Create",
  // "ProductCreate":"Product Edit",
  // "ProjectList":"Project List",
  // "ProjectEdit":"Project Create",
  // "ProjectCreate":"Project Edit",
  // "ProjectView":"Project View",
};

export const Project_Stage_Name = [
  {
    label: "Raw Material Purchase",
    sortorder: 1,
  },
  {
    label: "Material Finishing",
    sortorder: 2,
  },
  {
    label: "Stiching",
    sortorder: 3,
  },
  {
    label: "Order Dispatching",
    sortorder: 4,
  },
  {
    label: "Order Finishing",
    sortorder: 5,
  },

  {
    label: "Feeding and mixing",
    sortorder: 1,
  },
  {
    label: "Moulding and pressing",
    sortorder: 2,
  },
  {
    label: "Curing",
    sortorder: 3,
  },
  {
    label: "Polishing",
    sortorder: 4,
  },
  {
    label: "Quality Testing",
    sortorder: 5,
  },
  {
    label: "Tagging",
    sortorder: 6,
  },

  {
    label: "Dryer",
    sortorder: 1,
  },
  {
    label: "Pressing",
    sortorder: 2,
  },
  // {
  //     label: "A/c Room",
  //     sortorder: 3,
  // },
  {
    label: "Cutting",
    sortorder: 3,
  },
  {
    label: "Sanding",
    sortorder: 4,
  },
  {
    label: "Quality Check",
    sortorder: 5,
  },
  // {
  //     label: "BSR (Stock Ready)",
  //     sortorder: 7,
  // },
  {
    label: "Dispatch",
    sortorder: 6,
  },
];

export const HEADERS = Project_Stage_Status.map((stage, index) => {
  const colors = [
    { backgroundColor: "rgba(23, 162, 184, .8)", color: "#000" }, // for "Raw Material Purchase"
    { backgroundColor: "rgba(255, 165,  1, .8)", color: "#000" }, // for "Pressing"
    { backgroundColor: "#EA7276", color: "#fff" }, // for "Cutting"
    { backgroundColor: "#B69DC5", color: "#fff" }, // for "Sanding"
    { backgroundColor: "#cfb593", color: "#fff" }, // for "Quality Check"
    { backgroundColor: "rgba(0, 137, 89, .8)", color: "#000" }, // for "Dispatch"
  ];

  return {
    sequence: index,
    headerName: stage.label,
    className: "kanban-column-title",
    backgroundColor: colors[index % colors.length].backgroundColor,
    color: colors[index % colors.length].color,
    borderColor: "rgba(23, 162, 184, .3)",
    columnClassName: "kanban-column",
    columnBox: "kanban-box",
  };
});

export function getSequnceHeaders(data) {
  if (data) {
    return (
      data &&
      data?.sort((a, b) =>
        b.sequence === null || a.sequence < b.sequence
          ? -1
          : a.sequence === null || a.sequence > b.sequence
          ? 1
          : a.sequence === b.sequence
          ? 0
          : Infinity
      )
    );
  }
}

export const ChartColors = [
  "#6096B4",
  "#93BFCF",
  "#E6C229",
  "#FF7F00",
  "#D62728",
  "#93cfa9",
  "#c893cf",
  "#9a93cf",
  "#cacf93",
  "#29e66e",
  "#a429e6",
];
