/** @format */

import Header from "../layout/common/Header";
import { Outlet, Link } from "react-router-dom";
import { useEffect,useState } from "react";
const Main = ({ socket }) => {
  const [leadCreationIndicator,setLeadCreationIndicator]=useState(0);
  
  useEffect(() => {

  }, []);

  return (
    <div className="wrapper">
      
      <div id="content">
       <Header socket={socket} leadCreationIndicator={leadCreationIndicator}/>
        <Outlet context={[leadCreationIndicator,setLeadCreationIndicator]}/>
      </div>
    </div>

  );
};

export default Main;