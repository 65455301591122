import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Confirm from "../Confirm";
import TaskEdit from "./TaskEdit";
import TaskView from "./TaskView";
import Permissionhelper from "../common/Permissionhelper";
import * as constants from "../../constants/CONSTANT";

const RelatedListTask = ({ parent, refreshTaskList,table }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [task, setTask] = React.useState("");
  const [modalShowTask, setModalShowTask] = React.useState(false);
  const [modalShowTaskView, setModalShowTaskView] = React.useState(false);
  const [isFollowUp, setModalShowFollowUp] = React.useState(false);


  // Create table headers consisting of 4 columns.

  const [body, setBody] = useState([]);
  useEffect(() => {
    console.log(parent);
    taskList();

    if(table === 'Lead'){
      setModalShowFollowUp(true);
    }
  }, []);

  useEffect(() => {
    taskList();
  }, [refreshTaskList]);

  const taskList = () => {
    async function init() {
      let tasks = await WhiteObjectApi.fetchTasks(parent.id);

      //console.log(tasks);
      if (tasks && tasks?.length > 0) {
        setBody(tasks);
      } else {
        setBody([]);
      }
    }
    init();
  };
  const handleDelete = (row) => {
    setModalShow(true);
    setTask(row);
  };

  const labels = {
    beforeSelect: " ",
  };

  const deleteTask = async () => {
    ////console.log('task.id',task.id)
    const result = await WhiteObjectApi.deleteTask(task.id);
    ////console.log('result',result)
    if (result.success) {
      setTask("");
      setModalShow(false);
      taskList();
    }
  };

  const submitTasks = () => {
    setModalShowTask(false);
    taskList();
  };

  const editTask = (row) => {
    setModalShowTask(true);
    setTask(row);
  };

  const viewTask = (row) => {
    setModalShowTaskView(true);
    setTask(row);
  };

  const Taskheader = [
    {
      title: "Title",
      prop: "title",
      cell: (row) => (
        <Link onClick={() => viewTask({ row })} state={parent}>
          {row.title}
        </Link>
      ),
    },
    { title: "Type", prop: "type" },
    { title: "Assigned", prop: "ownername" },

    { title: "Status", prop: "status" },
    { title: "Priority", prop: "priority" },

    {
      title: "Target Date",
      prop: "targetdate",
      cell: (row) => moment(row.targetdate).format("DD-MM-YYYY"),
    },
    {
      title: "Created Date",
      prop: "createddate",
      cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button className="btn-sm mx-2" onClick={() => editTask({ row })}>
            <i className="fa-regular fa-pen-to-square"></i>
          </Button>
          {Permissionhelper.checkPermission(constants.SUPER_ADMIN) && (
            <Button
              className="btn-sm mx-2"
              variant="danger"
              onClick={() => handleDelete(row)}
            >
              <i class="fa-regular fa-trash-can"></i>
            </Button>
          )}
        </>
      ),
    },
  ];

  const FollowUpheader = [
    {
      title: "FollowUp Title",
      prop: "title",
      cell: (row) => (
        <Link onClick={() => viewTask({ row })} state={parent}>
          {row.title}
        </Link>
      ),
    },
    { title: "Type", prop: "type" },
    { title: "Assigned", prop: "ownername" },

    { title: "Status", prop: "status" },
    { title: "Priority", prop: "priority" },

    {
      title: "Target Date",
      prop: "targetdate",
      cell: (row) => moment(row.targetdate).format("DD-MM-YYYY"),
    },
    {
      title: "Created Date",
      prop: "createddate",
      cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button className="btn-sm mx-2" onClick={() => editTask({ row })}>
            <i className="fa-regular fa-pen-to-square"></i>
          </Button>
          {Permissionhelper.checkPermission(constants.SUPER_ADMIN) && (
            <Button
              className="btn-sm mx-2"
              variant="danger"
              onClick={() => handleDelete(row)}
            >
              <i class="fa-regular fa-trash-can"></i>
            </Button>
          )}
        </>
      ),
    },
  ];

  const headers = isFollowUp ? FollowUpheader :Taskheader

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteTask={deleteTask}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="task"
        />
      )}
      {modalShowTask && (
        <TaskEdit
          show={modalShowTask}
          onHide={() => setModalShowTask(false)}
          parentid={parent.id}
          task={task}
          table="lead"
          submitTasks={submitTasks}
        />
      )}

      {modalShowTaskView && (
        <TaskView
          show={modalShowTaskView}
          onHide={() => setModalShowTaskView(false)}
          parentid={parent.id}
          task={task}
          table="lead"
          submitTasks={submitTasks}
        />
      )}

      {body ? (
        <DatatableWrapper
          body={body}
          headers={headers}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5,
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            ></Col>
          </Row>
          <Table striped className="related-list-table" responsive="sm">
            <TableHeader />
            <TableBody />
          </Table>
          {body.length > 0 && <Pagination />}
          {/*  //Added by shivani */}
        </DatatableWrapper>
      ) : (
        ""
      )}
    </>
  );
};

export default RelatedListTask;
