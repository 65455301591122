import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import Confirm from '../Confirm';
// import PricebookEdit from './PricebookEdit';

const RelatedListPricebook = ({ parent, refreshPricebookList }) => {
  const [modalShow, setModalShow] = useState(false);
  const [pricebook, setPricebook] = useState('');
  const [modalShowPricebook, setModalShowPricebook] = useState(false);
  const [modalShowPricebookView, setModalShowPricebookView] = useState(false);

  const [body, setBody] = useState([]);

  useEffect(() => {
    pricebookList();
  }, []);

  useEffect(() => {
    pricebookList();
  }, [refreshPricebookList]);

  const pricebookList = () => {
    async function init() {
      const token = localStorage.getItem("token");
      const userInfo = jwt_decode(token);  // Decode the JWT token to get user info
      console.log("userInfo",userInfo)
      if(userInfo.userrole != "SUPER_ADMIN"){
        let pricebooks = await WhiteObjectApi.fetchProjectByLeadId(parent.id);
      
          if (pricebooks && pricebooks.length > 0) {
            // Filter projects based on logged-in user's ID and projectmanagerid
            const filteredPricebooks = pricebooks.filter(
              (project) => project.projectmanagerid === userInfo.id
            );
            setBody(filteredPricebooks);
          } else {
            setBody([]);
          }
      }else{
        let pricebooks = await WhiteObjectApi.fetchProjectByLeadId(parent.id);
      
        if (pricebooks && pricebooks.length > 0) {
          // Filter projects based on logged-in user's ID and projectmanagerid
          // const filteredPricebooks = pricebooks.filter(
          //   (project) => project.projectmanagerid === userInfo.id
          // );
          setBody(pricebooks);
        } else {
          setBody([]);
        }
      }
      
    }
    init();
  }

  const handleDelete = (row) => {
    setModalShow(true);
    setPricebook(row);
  }

  const deletePricebook = async () => {
    const result = await WhiteObjectApi.deletePriceBook(pricebook.id);
    if (result) {
      setPricebook('');
      setModalShow(false);
      pricebookList();
    }
  }

  const submitPricebooks = () => {
    setModalShowPricebook(false);
    pricebookList();
  }

  const editPricebook = (row) => {
    setModalShowPricebook(true)
    setPricebook(row.row);
  }

  const viewPricebook = (row) => {
    setModalShowPricebookView(true)
    setPricebook(row);
  }

  const header = [
    {
      title: "Project Name",
      prop: "project_name",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <Link to={"/projects/" + row.id} state={row}>
          {row.project_name}
        </Link>
      ),
    },
    { title: 'Project Status', prop: 'project_status', isSortable: false },
    { title: 'Project Code', prop: 'project_code', isSortable: true },
    { title: 'Contact User', prop: 'contactname', isSortable: true },
    { title: 'Assigned User', prop: 'projectmanagername', isSortable: true },
    { title: 'Created Date', prop: 'createddate', cell: (row) => (moment(row.createddate).format('DD-MM-YYYY')), isSortable: true },
    { title: 'Created By', prop: 'createdbyname' },
  ];

  return (
    <>
      {modalShow &&
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deletePricebook={deletePricebook}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="pricebook"
        />
      }

      {body ?
        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5
          }
        }}>
          <Row className="mb-4">
            <Col xs={12} sm={6} lg={4}></Col>
            <Col xs={12} sm={6} lg={4}></Col>
            <Col xs={12} sm={6} lg={4}></Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody />
          </Table>
          {body.length > 0 && <Pagination />}
        </DatatableWrapper> : ''}
    </>
  );
}

export default RelatedListPricebook;