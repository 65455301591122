import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ReportPDF from "./ReportPDF";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import csvDownload from "json-to-csv-export";
import Main from "../../components/layout/Main";
import Helmet from "react-helmet";
import { FaFilePdf, FaFileCsv } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";

const ReportView = () => {
  const [arrName, setArrName] = useState([]);
  const location = useLocation();
  const report = location.state;
  const [body, setBody] = useState([]);
  const [refreshList, setRefreshList] = useState(Date.now());
  const [values, setValues] = useState([]);
  const [fields, setFields] = useState([]);
  const [state, setState] = useState(location.state ? location.state : {});

  useEffect(() => {
    console.log("state",state)
    async function init() {
      const result = await WhiteObjectApi.fetchReportsById(report);
      if (result && result.length) {
        setState(result[0])
        console.log("Fetched result:", result);
        const labels = labelList(Object.keys(result[0]));
        setArrName(labels);
        setBody(result);
      } else {
        setBody([]);
      }
    }

    init();
  }, [report]);

  const labelList = (arry) => {
    return arry.map(date => {
      let label = date
        .split('_')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' ');

      return { label, name: date };
    });
  };

  const header = arrName.map(data => ({
    title: data.label,
    prop: data.name,
    isFilterable: true,
  }));

  const ReportDataCSV = {
    data: body,
    filename: "Report",
    delimiter: ",",
    headers: Object.keys(body[0] || {}),
  };

  const pdfRecorder = () => {
    setRefreshList(Date.now());
    setTimeout(pdf, 500);
  };

  const pdf = () => {
    const pdfTable = document.getElementById("divToPrint2");
    const html = htmlToPdfmake(pdfTable.innerHTML, { tableAutoSize: true });
    const documentDefinition = {
      pageOrientation: 'landscape',
      content: [html],
      pageBreakBefore: currentNode => currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1,
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  };

  return (
    <Main>
      <Helmet>
        {/* <title>Report View</title> */}
      </Helmet>
      <Row className="g-0">
        <Col lg={4} className="mx-2">
          <Link to="/home" style={{ textDecoration: "none" }}>Home</Link>
          <BsChevronRight />
          <Link to="/reports" style={{ textDecoration: "none", marginLeft: "2px" }}>
            Reports <b>{report.name}</b>
          </Link>
        </Col>
        <Col lg={12} className="p-lg-4">
          {body.length ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{ initialState: { rowsPerPage: 20 } }}
            >
              <Row className="mb-4">
                <Col xs={3} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                  <Filter />
                </Col>
                <Col xs={6} sm={6} lg={7} className="d-flex justify-content-end">
                  <Button className="btn-sm mr-2" variant="outline-success" onClick={() => csvDownload(ReportDataCSV)}>
                    <FaFileCsv style={{ fontSize: '24px' }} />
                  </Button>
                  <div style={{ width: '30px' }}></div>
                  <Button className="btn-sm" variant="outline-danger" onClick={pdfRecorder}>
                    <FaFilePdf style={{ fontSize: '24px' }} />
                  </Button>
                </Col>
              </Row>
              <Table striped className="data-table" responsive="sm">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : null}
        </Col>
        <Col lg={2}></Col>
        <div className="App container mt-5" style={{ display: "none" }}>
          <div id="divToPrint2">
          <ReportPDF header={header} data={body} reportname={report.name} />
          </div>
        </div>
      </Row>
    </Main>
  );
};

export default ReportView;