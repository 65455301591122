import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from "axios";
import PubSub from "pubsub-js";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import SpinnerOverlay from "./SpinnerOverlay"; //Added by shivani
import moment from "moment";

const FilesCreate = (props) => {
  const [validated, setValidated] = useState(false); //Added by shivani
  const [files, setFiles] = useState([]);
  const [fileDescription, setFileDescription] = useState("");
  const [folderName, setFolderName] = useState("");
  const [quoteStatus, setQuoteStatus] = useState("");
  const [showQuoteStatus, setShowQuoteStatus] = useState(false);
  const [loading, setLoading] = useState(false); //Added by shivani

  useEffect(() => {
    if (props.table === "Product") {
      setFolderName("ProductImages");
    }
  }, [props.table]);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles(selectedFiles);
    console.log("Selected Files:", selectedFiles);
  };
  
  const handleFolderChange = (event) => {
    const selectedFolder = event.target.value;
    setFolderName(selectedFolder);
    setShowQuoteStatus(selectedFolder === "Quotation");
    if (selectedFolder === "Quotation") {
      setQuoteStatus("PendingApproved");
    }
    console.log("Folder Name:", selectedFolder);
  };

  // Added validation by Shivani mehra starts
 const checkRequredFields = () => {
  if (
    files.length > 0 && folderName
  ) {
    return false;
  }
  return true;
};
  
  const handleQuoteStatusChange = (event) => {
    const selectedQuoteStatus = event.target.value;
    setQuoteStatus(selectedQuoteStatus);
    console.log("Quote Status:", selectedQuoteStatus);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Added validation by Shivani mehra starts
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }
    let formData = new FormData();
      // Added validation by Shivani mehra ends
    setLoading(true); //Added by shivani
    // Append files
    for (let i = 0; i < files.length; i++) {
      formData.append(`files${i}`, files[i]);
    }
  
    // Append other form data
    // formData.append("description", fileDescription);
    formData.append("folder_name", folderName);
       
    if (showQuoteStatus) {
      console.log("quoteStatus",quoteStatus)
      formData.append("quote_status", quoteStatus);
    }
  
    // Log formData to console for debugging
    console.log("Form Data:", formData);
  
    //Added by shivani start
    try {

      if(quoteStatus === "Approved"){
        let obj = {}
        obj.id = props?.parent.id;
        // obj.quote_approval_date = moment().startOf('day').toISOString();
        obj.quote_approval_date = moment().toISOString();
        await WhiteObjectApi.saveProject(obj)
      }
      const result = await WhiteObjectApi.createFile(props.parent.id, formData);
      if (result) {
        // PubSub.publish("RECORD_SAVED_TOAST", {
        //   title: "Record Saved",
        //   message: "Record saved successfully",
        // });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        props.submitFiles();
      }
    } catch (error) {
      
     setTimeout(() => {
      setLoading(false);
    }, 2000);
      console.log("File upload failed", error);
    } finally {
    
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    
  };
  //Added by shivani end
  };
  

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload Files
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <SpinnerOverlay loading={loading} />
       {/* Added form tag by shivani */}
       <Form
              className="mt-3"
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
            >
              {/* End by shivani */}
              <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Select Files</Form.Label>
          <Form.Control required type="file" multiple onChange={handleFileChange} />
             {/* // Shivani start */}
             <Form.Control.Feedback type="invalid">
                        Please select files
                      </Form.Control.Feedback>
                      {/* Shivani end */}
        </Form.Group>

           <Form.Group>
              <Form.Label className="form-view-label">Folder Name</Form.Label>
              <Form.Select
                aria-label="Select Folder"
                name="folder_name"
                onChange={handleFolderChange}
                required
                value={folderName}
                disabled={props.table === "Product"}
              >
                <option value="">--Select Folder--</option>
                <option value="Quotation">Quotation</option>
                <option value="Measurement">Measurement</option>
                <option value="WorkCompletionCertificate">Work Completion Certificate</option>
                <option value="ProductImages">Product Images</option>
                <option value="ContactImages">Contact Images</option>
                <option value="RepairTicket">Repair Ticket</option>
                <option value="Check-In-Documents">Check-In-Documents</option>
              </Form.Select>
            {/* // Shivani start */}
            <Form.Control.Feedback type="invalid">
                        Please provide folder name
                      </Form.Control.Feedback>
                      {/* Shivani end */}
            </Form.Group>

        {showQuoteStatus && (
          <Form.Group>
            <Form.Label className="form-view-label mt-2">
              Quote Status
            </Form.Label>
            <Form.Select
              aria-label="Select Quote Status"
              name="quote_status"
              onChange={handleQuoteStatusChange}
              value={quoteStatus}
            >
              <option value="PendingForApproved">Pending For Approved</option>
              <option value="Approved">Approved</option>
              <option value="Declined">Declined</option>
            </Form.Select>
          </Form.Group>
        )}

        <Form.Group controlId="formBasicDescription">
          <Form.Label className="form-view-label mt-2">Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            placeholder="Enter description"
            onChange={(e) => setFileDescription(e.target.value)}
          />
        </Form.Group>
         {/* end form tag by shivani */}
         </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="submit">
          <Button variant="success" disabled={loading} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilesCreate;
