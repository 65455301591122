import jwt_decode from "jwt-decode";

const helper = {
    checkPermission(perm) {
        let userInfo = jwt_decode(localStorage.getItem('token'));

        if(!userInfo.permissions)
            return false;
        else
        return userInfo.userrole === perm;
            
          
    }
}

export default helper