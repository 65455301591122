import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const SpinnerOverlay = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className="spinner-overlay">
      <div className="spinner-container">
        <ClipLoader size={50} color={"#ffffff"} />
      </div>
    </div>
  );
};

export default SpinnerOverlay;
