import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import { ShimmerTable } from "react-shimmer-effects";
import {isMobile, MobileView, BrowserView} from 'react-device-detect';

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import Main from "../layout/Main";
import { Helmet } from "react-helmet";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";


const AccountList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  useEffect(() => {
    async function init() {
      const accounts = await WhiteObjectApi.fetchAccounts();
      if (accounts) {
        setBody(accounts);
      } else {
        setBody([]);
      }
    }
    init();
  }, []);

  // Create table headers consisting of 4 columns.
  const header = [];
  if(!isMobile){
    header.push(
    {
      title: "Account Name",
      prop: "name",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/accounts/" + row.id} state={row}>
          {row.name}
        </Link>
      ),
    },
    { title: "Street", prop: "street", isFilterable: true },
    { title: "City", prop: "city", isFilterable: true },
    { title: "Phone", prop: "phone", isFilterable: true },
    { title: "Email", prop: "email", isFilterable: true },
    { title: "Website", prop: "website", isFilterable: true },)
  }else{
    //for mobile device
    
    header.push(
      {
        title: "Info",
        prop: "name",
        isFilterable: true,isSortable: true,
        cell: (row) => (
          <div className="mobilecard" >
           <Link to={"/accounts/" + row.id} state={row} style={{width: "100%"}}>
          {row.name}
        </Link>
        
            <span><i class="fa-solid fa-phone"></i> {row.phone}</span>
            <span style={{width: "80%"}}><i class="fa-solid fa-globe"></i>{row.website}</span>
            
          </div>
        ),
      },
      )
  }

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createAccount = () => {
    navigate(`/accounts/e`);
  };

  return (
    <Main>
      <>
        <Helmet>{LIST_TAB_TITLE.AccountView}</Helmet>
      </>
    <Row className="g-0">
     
      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
              <PaginationOptions labels={labels} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={() => createAccount(true)}
              >
                New Account
              </Button>
            </Col>
          </Row>
          {body ? (
            <Table striped className="data-table" responsive = "sm">
              <TableHeader />

              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}

          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
    </Main>
  );
};
export default AccountList;
