import React, { useState } from 'react'
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Confirm = (props) => {
  //eslint-disable-next-line
  const [show, setShow] = useState(true);
  //eslint-disable-next-line
  const deleteContact = () => {
    props.deleteContact();
  }
  //eslint-disable-next-line
  const deleteSubject = () => {
    props.deleteSubject();
  }
  //eslint-disable-next-line
  const deleteClass = () => {
    props.deleteClass();
  }
  //eslint-disable-next-line
  const deleteCourse = () => {
    props.deleteCourse();
  }
  //eslint-disable-next-line
  const deleteCourseRegistration = () => {
    props.deleteCourseRegistration();
  }
  //eslint-disable-next-line
  const deleteFile = () => {
    props.deleteFile();
  }
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Alert show={show} variant="danger" className='mb-0'>
        <Alert.Heading>{props.title}</Alert.Heading>
        <p>
          {props.message}
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          {props.table === "contact" &&
            <Button onClick={props.deleteContact} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "pricebook" &&
            <Button onClick={props.deletePricebook} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "repair" &&
            <Button onClick={props.deleteRepairTicket} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "task" &&
            <Button onClick={props.deleteTask} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "archive" &&
            <Button onClick={props.archiveLeads} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "file" &&
            <Button onClick={props.deleteFile} variant="danger" className='mx-2'>
             Yes
            </Button>
          }
          {props.table === "payment" &&
            <Button onClick={props.deletepayment} variant="danger" className='mx-2'>
             Yes
            </Button>
          }
          {/* Added by Farhan Khan on 16-Sep-2023 Active Inactive Registrations */}
          {props.table === "courseregistrationsInactiveTable" &&
            <Button onClick={props.inactiveCourseRegistration} variant="danger" className='mx-2'>
             Yes
            </Button>
          } {props.table === "courseregistrationsActiveTable" &&
            <Button onClick={props.inactiveCourseRegistration} variant="danger" className='mx-2'>
            Yes .
            </Button>
        }
          {props.table === "Product" &&
            <Button onClick={props.deleteProduct} variant="danger" className='mx-2'>
            Yes
            </Button>
          }
          {props.table === "lead" &&
            <Button onClick={props.deleteLead} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "Project" &&
            <Button onClick={props.deleteProject} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          <Button onClick={props.onHide} variant="light" className="text-">
            No
          </Button>
        </div>
      </Alert>
    </Modal>
  )
}

export default Confirm