import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import moment from "moment";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import PubSub from "pubsub-js";

const TaskEdit = (props) => {
    const [validated, setValidated] = useState(false);
    const [option, setOption] = useState();
    const [location, setLocation] = useState(useLocation());
    const [navigate, setNavigate] = useState(useNavigate());
    const [task, setTask] = useState(props.task ? props.task.row : { parentid: props.parentid });
    const [taskTargetDate, setTaskTargetDate] = useState();
    const [currentTargetDate, setCurrentTargetDate] = useState();

    useEffect(() => {
        let userInfo = jwt_decode(localStorage.getItem('token'));

        let tempValue = {};
        tempValue.value = userInfo.id;
        tempValue.label = userInfo.username;
        setOption(tempValue);

        if (props.task) {
            let temp = {};
            temp.value = props.task.row.ownerid;
            temp.label = props.task.row.ownername;
            setOption(temp);
        }

        if (props.parentid !== null && props.task !== null) {
            let current = new Date();
            task.lastmodifieddate = moment(current).format('YYYY-MM-DD');
        }

        setSelectedUser(task.ownername);
    }, []);

    const [show, setShow] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [optionUsers, setOptionUsers] = useState([]);
    const [ownerList, setOwnerList] = useState([]);
    const [defaultOwner, setDefaultOwner] = useState([]);
    const [defaultTargetDate, setDefaultTargetDate] = useState(new Date());

    useEffect(() => {
        async function init() {
            const result = await WhiteObjectApi.fetchUsers();
            if (result) {
                let ar = result.map((item) => {
                    return {
                        value: item.id,
                        label: item.username
                    };
                });
                setOptionUsers(ar);
            } else {
                setOptionUsers([]);
            }
        }
        init();
        console.log("tasktask",task)
    }, []);

    const handleUsers = (event) => {
        setOption(event);
        setSelectedUser(event);
        setTask({ ...task, ownerid: event.value, ownername: event.label });
    }

    const handleChange = (e) => {
        if (e.target.name === "targetdate") {
            // Set the date to the start of the day in the Indian time zone (IST)
            const selectedDate = moment.tz(e.target.value, 'Asia/Kolkata').startOf('day').format('YYYY-MM-DD');
            setTask({ ...task, targetdate: selectedDate });
        } else {
            setTask({ ...task, [e.target.name]: e.target.value });
        }
    };

    const checkRequiredFields = () => {
        if (task.title && task.title.trim() !== '' && task.status && task.status.trim() !== '' && task.priority && task.priority.trim() !== '' && task.ownerid && task.ownerid.trim() !== '') {
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(task.targetdate === undefined){
            //console.log('if call')
            console.log("Task Edit Date Undefined",task.targetdate)
            task.targetdate = moment(task.targetdate).toISOString();
        }
        
        // Added By Farhan Khan on 12-Sep-2024 this is using for Asia Kolkata timezone so that we can get right date on records.
        if (task.targetdate && !moment(task.targetdate, 'YYYY-MM-DD', true).isValid()) {
            task.targetdate = moment.tz(task.targetdate, 'Asia/Kolkata').startOf('day').format('YYYY-MM-DD');
        }
        
        task.ownerid = option.value;
        task.ownername = option.label;
    
        if (checkRequiredFields()) {
            setValidated(true);
            return;
        }
    
        if (task.id) {
            const result = await WhiteObjectApi.saveTask(task);
            if (result) {
                submitTasksData();
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record Save successfully' });
            }
        } else {
            const result = await WhiteObjectApi.createTask(task);
            console.log("Create Task API Result", result);
            if (result) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record Save successfully' });
                submitTasksData();
            }
        }
    };

    const submitTasksData = () => {
        console.log("Props Tasks",props);
        props.submitTasks();
    };

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props?.table === 'Lead' ? <>Edit Task</> : <>Edit Follow Up</> }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                    <Row>
                        <Col lg={12}>
                            <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit} controlId="taskEdit">
                                <Row>
                                    <Alert variant="danger" show={show} className="error-alert">
                                        Please Select Assign Staff
                                    </Alert>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                                
                                                {props.table === 'Lead' ?<>Title</>:<>Follow Up</>}
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="title"
                                                placeholder="Enter title"
                                                value={task.title}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicType">
                                            <Form.Label className="form-view-label" htmlFor="formBasicType">
                                                Type
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Enter Payment Status"
                                                name="type"
                                                value={task.type}
                                                onChange={handleChange}
                                            >
                                                <option value=''>--Select--</option>
                                                <option value="Call">Call</option>
                                                <option value="Task">Task</option>
                                                <option value="Email">Email</option>
                                                <option value="Meeting">Meeting</option>
                                                <option value="Notes">Notes</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicPriority">
                                            <Form.Label className="form-view-label" htmlFor="formBasicPriority">
                                                Priority
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter Priority" name="priority" value={task.priority} onChange={handleChange}>
                                                <option value="">Select Priority</option>
                                                <option value="Low">Low</option>
                                                <option value="Normal">Normal</option>
                                                <option value="High">High</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label className="form-view-label" htmlFor="formBasicStatus">
                                                Status
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter status" name="status" value={task.status} onChange={handleChange}>
                                                <option value="">Select Status</option>
                                                <option value="Not Started">Not Started</option>
                                                <option value="In Progress">In Progress</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Waiting on someone else">Waiting on someone else</option>
                                                <option value="Deferred">Deferred</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTargetdate">
                                            <Form.Label className="form-view-label" htmlFor="formBasicTargetdate">
                                                Target date
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="date"
                                                name="targetdate"
                                                placeholder="Enter targetdate"
                                                value={moment(task.targetdate).format('YYYY-MM-DD')}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicOwner">
                                            <Form.Label className="form-view-label" htmlFor="formBasicOwner">
                                                Assign Staff
                                            </Form.Label>
                                            <Select
                                                required
                                                name="ownerid"
                                                value={option}
                                                className="custom-select username"
                                                onChange={handleUsers}
                                                options={optionUsers}
                                                getOptionValue={(option) => option.value}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mx-3" controlId="formBasicDescription">
                                            <Form.Label className="form-view-label" htmlFor="formBasicDescription">
                                                Description
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="description"
                                                placeholder="Enter description"
                                                value={task.description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit}>Save</Button>
                <Button onClick={props.onHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskEdit;
