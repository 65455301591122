import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import PubSub from "pubsub-js";
import Select from "react-select";
import CityState from "../../constants/CityState.json";
import * as constants from "../../constants/CONSTANT";
import Main from "../layout/Main";
import { Helmet } from "react-helmet";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import moment from "moment";

const ContactEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionAccount, setOptionAccount] = useState({});
  const [name, setName] = useState("");
  const [state, setState] = useState([]); //Added by shivani
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [option, setoption] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  //const [contact, setContact] = useState(location.state);
  const [contact, setContact] = useState(location.state ? location.state : {});
  const [dobError, setDobError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [whatsappError, setWhatsappError] = useState("");
  const [showValidationMessage, setShowValidationMessage] = useState(false); //Added by shivani
  useEffect(() => {
    if (contact.id) {
      let temp = {};
      temp.value = contact.accountid;
      temp.label = contact.accountname;
      setoption(temp);
    }

    

    let st = [];
      CityState.forEach((item) => {
        let obj = {
        value: item.state,
        label: item.state
        };
        st.push(obj);
      });

      const uniqueStates = [...new Set(st.map(item => item.value))];

      const uniqueObjects = uniqueStates.map(state => ({
        value: state,
        label: state
      }));

      uniqueObjects.sort((a, b) => a.label.localeCompare(b.label));

      setState(uniqueObjects);


    let fetchedAccounts = [];
    accounts.map((item) => {
      fetchedAccounts.push({
        label: item.firstname,
        population: 4780127,
        capital: "Montgomery",
        region: "South",
      });
    });
    setOptions(fetchedAccounts);
  }, [accounts]);

  //Added by shivani on 6 aug start
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setContact(prevContact => ({ ...prevContact, [name]: value }));
  };

  const handleTimeSlotChange = (e) => {
    const { value } = e.target;
    setContact(prevContact => ({ ...prevContact, time_slot: value }));
  };
  //Added by shivani on 6 aug end
 
  const handleState = (e) => {
    setCities([]);
    contact.city = ''; //Added by shivani bug 56 1 july
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    filteredCities.sort((a, b) => a.label.localeCompare(b.label)); //Added By Shivani
    setCities(filteredCities);
    setContact({ ...contact, state: e.value });
  };

  const handleSelectListChange = (value, name) => {
    ////console.log("contact:", contact);
    setContact({ ...contact, [name]: value.value });
    setSelectedCity(value.value);
  };

  // {
  //   contact.id !== null && name === "" ? (
  //     setName(contact.firstname + " " + contact.lastname)
  //   ) : (
  //     <h1></h1>
  //   );
  // }
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "date_of_birth") {
      const selectedDate = moment(value);
      const today = moment();
      if (selectedDate.isAfter(today)) {
        setDobError("Date of Birth cannot be a future date.");
      } else {
        setDobError("");
      }
    }

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    }

    if (name === "phone" || name === "whatsapp_no") {
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(value)) {
        if (name === "phone") {
          setPhoneError("Phone number must be 10 digits.");
        } else {
          setWhatsappError("WhatsApp number must be 10 digits.");
        }
      } else {
        if (name === "phone") {
          setPhoneError("");
        } else {
          setWhatsappError("");
        }
      }
    }

    setContact({ ...contact, [name]: value });
  };

  const checkRequredFields = () => {
    if (
      contact.firstname &&
      contact.firstname.trim() !== "" &&
      contact.lastname &&
      contact.lastname.trim() !== "" &&
      contact.phone &&
      contact.phone.trim() !== "" &&
      contact.title &&
      contact.title.trim() !== "" 
    ) {
      return false;
    }
    setShowValidationMessage(true);
    // document.querySelector(".username").style.border = "1px solid #dc3545";
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkRequredFields() || dobError || emailError || phoneError || whatsappError) {
      setValidated(true);
      return;
    }

    //========= Logic to perform Create or Edit ======
    let result = {};
    if (contact.id) {
      result = await WhiteObjectApi.saveContact(contact);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/contacts/${contact.id}`, { state: contact });
      }
    } else {
      result = await WhiteObjectApi.createContact(contact);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/contacts/${result.id}`, { state: result });
      }
    }
  };

  const handleCancel = () => {
    if (contact.id) {
      navigate("/contacts/" + contact.id, { state: contact });
    } else {
      navigate(`/contacts`);
    }
  };
  return (
    <Main>
      <>
        <Helmet>{LIST_TAB_TITLE.ContactEdit}</Helmet>
      </>
      <Container className="view-form">
        <Row>
          <Col></Col>
          <Col lg={8} className="pb-1 pt-2">
            <Link className="nav-link" to="/contacts">
              Home <i className="fa-solid fa-chevron-right"></i>{" "}
              <div style={{ color: "#23468c", display: "inline" }}>
                Contacts
              </div>
            </Link>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col lg={8}>
            <Form
              className="mt-3"
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
            >
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  {contact.id ? (
                    <h5>Edit Contact {contact.firstname}</h5>
                  ) : (
                    <h5>Create Contact</h5>
                  )}
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button className="btn-sm mx-2" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="ibs-edit-form pb-3 ">
                
                <Row lg={12}>
                  <Col lg={6} className="d-flex ">
                    <Form.Group
                      className="mx-2"
                      controlId="formBasicsalutation"
                    >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicsalutation"
                      >
                        Salutation
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        as="select"
                        name="salutation"
                        value={contact.salutation}
                        onChange={handleChange}
                      >
                        <option value="">---Select---</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Dr.">Dr.</option>
                        <option value="Prof.">Prof.</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formBasicFirstName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        First Name
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px", width: "307px" }} //Added by shivani
                        required
                        type="text"
                        name="firstname"
                        placeholder="Enter First Name"
                        value={contact.firstname}
                        onChange={handleChange}
                      />
                        {/* Added by shivani  */}
                        <Form.Control.Feedback type="invalid">
                             Please provide a First name.
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group controlId="formBasicLastName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                        style={{ marginLeft: "10px" }}
                      >
                        Last Name
                      </Form.Label>
                      <Form.Control
                        style={{
                          height: "36px",
                          marginLeft: "15px", //Added by shivani
                          width: "93%", //Added by shivani
                        }}
                        required
                        type="text"
                        name="lastname"
                        placeholder="Enter Last Name"
                        value={contact.lastname}
                        onChange={handleChange}
                      />
                      {/* Added by shivani  */}
                         <Form.Control.Feedback type="invalid" className="ps-3">
                             Please provide a Last name.
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicEmail">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEmail"
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        value={contact.email}
                        onChange={handleChange}
                        isInvalid={!!emailError}
                        // required //Added by shivani
                        />
                        {/*  //Added by shivani */}
                        <Form.Control.Feedback type="invalid">
                          {emailError || 'Please provide a email address.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicEmail">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicEmail"
                      >
                        Date of Birth
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="date"
                        name="date_of_birth"
                        placeholder="Select Date Of Birth"
                        value={
                          contact.date_of_birth
                            ? moment(contact.date_of_birth).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={handleChange}
                        isInvalid={!!dobError} // Mark the field as invalid if there's an error
                      />
                      <Form.Control.Feedback type="invalid">{dobError}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  
                </Row>
                <Row>
                <Col>
                <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Phone
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        required
                        type="text" //Added by shivani
                        name="phone"
                        placeholder="Enter Phone"
                        value={contact.phone}
                        onChange={handleChange}
                        maxLength={10} //Added by shivani
                        pattern="\d{10}"//Added by shivani
                        isInvalid={!!phoneError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {phoneError}
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                <Col>
                <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        WhatsApp No.
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text" //Added by shivani
                        name="whatsapp_no"
                        placeholder="Enter WhatsApp Number"
                        value={contact.whatsapp_no}
                        onChange={handleChange}
                        maxLength={10} //Added by shivani
                        pattern="\d{10}"//Added by shivani
                        isInvalid={!!whatsappError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {whatsappError}
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  </Row>
                <Row>
                <Col>
                    <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Reference
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="reference"
                        placeholder="Enter Reference"
                        value={contact.reference}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicTitle">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicTitle"
                      >
                        Contact Type
                      </Form.Label>
                      {/* Added by shivani start */}
                      <Form.Select
                       placeholder="Select Contact Type"
                                    aria-label="Select Contact Type"
                                    name="title"
                                    value={contact.title}
                                    onChange={handleChange}
                                    required // Added by shivani
                                  >
                        <option value="">---Select---</option>
                        <option value="Contractor">Contractor</option>
                        <option value="Architect">Architect</option>
                        <option value="Supplier">Supplier</option>
                        <option value="Customer">Customer</option>
                        <option value="Dealer">Dealer</option>
                        <option value="Customer Enquiry">Customer Enquiry</option>
                        </Form.Select>
                           {/* Added by shivani  */}
                           <Form.Control.Feedback type="invalid">
                             Please provide a contact type.
                        </Form.Control.Feedback>
                           {/* Added by shivani end */}
                    </Form.Group>
                  </Col>
                  {/* //Added by shivani on 6 aug start */}
                  {contact.title ===  "Customer Enquiry" && (
                    <>
                      <Col lg={6}>
                 <Form.Group className="mx-3" controlId="formBasicTitle">
                 <Form.Label className="form-view-label"> No. Of Windows</Form.Label>
                      
                        <Form.Control
                          type="number"
                          name="qty_windows"
                          placeholder="Enter No. Of Windows"
                          onChange={handleChange}
                          value={contact.qty_windows}
                        />
                      </Form.Group>
                    </Col>
                 <Col lg={6}>
                 <Form.Group className="mx-3" controlId="formBasicTitle">
                     <Form.Label className="form-view-label">Date Of Site Visit</Form.Label>
                     <Form.Control
                       type="date"
                       name="site_visit_date"
                       placeholder="Select Site Visit Date"
                       onChange={handleChange}
                       value={
                        contact.site_visit_date
                          ? moment(contact.site_visit_date).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                     />
                   </Form.Group>
                 </Col>
                     <Col lg={6}>
                      <Form.Group className="mx-3" controlId="formBasicTitle">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicTitle"
                      > Type Of Order
                      </Form.Label>

                       <div style={{ justifyContent: "space-between", marginLeft: "10px" }} className="mt-1">
                         <Form.Check
                           type="radio"
                           label="Commercial"
                           name="type_of_order"
                           id="Commercial"
                           value="Commercial"
                           onChange={handleRadioChange}
                           checked={contact.type_of_order === 'Commercial'}
                           className="me-3"
                         />
                         <Form.Check
                           type="radio"
                           label="Residential"
                           name="type_of_order"
                           id="Residential"
                           value="Residential"
                           checked={contact.type_of_order === 'Residential'}
                           onChange={handleRadioChange}
                         />
                         <Form.Check
                           type="radio"
                           label="Hospital"
                           name="type_of_order"
                           id="Hospital"
                           value="Hospital"
                           checked={contact.type_of_order === 'Hospital'}
                           onChange={handleRadioChange}
                         />
                         <Form.Check
                           type="radio"
                           label="Hotel"
                           name="type_of_order"
                           id="Hotel"
                           value="Hotel"
                           checked={contact.type_of_order === 'Hotel'}
                           onChange={handleRadioChange}
                         />
                         <Form.Check
                           type="radio"
                           label="Factory"
                           name="type_of_order"
                           id="Factory"
                           value="Factory"
                           checked={contact.type_of_order === 'Factory'}
                           onChange={handleRadioChange}
                         />
                       </div>
                     </Form.Group>
                   </Col>
                   <Col lg={6}>
                   <Form.Group className="mx-3" controlId="formBasicTitle">
                     <Form.Label>
                       Time Slot Of Visit
                     </Form.Label>
                     <div style={{ justifyContent: "space-between", marginLeft: "10px"
                     }}>
                       <Form.Check
                         type="radio"
                         label="9 Am To 12 Noon"
                         name="time_slot"
                         id="9 Am To 12 Noon"
                         value="9 Am To 12 Noon"
                         checked={contact.time_slot === '9 Am To 12 Noon'}
                         onChange={handleTimeSlotChange}
                          className="me-3"
                       />
                       <Form.Check
                         type="radio"
                         label="12 Noon To 3 Pm"
                         name="time_slot"
                         id="12 Noon To 3 Pm"
                         value="12 Noon To 3 Pm"
                         checked={contact.time_slot === '12 Noon To 3 Pm'}
                         onChange={handleTimeSlotChange}
                       />
                       <Form.Check
                         type="radio"
                         label="3 Pm To 6 Pm"
                         name="time_slot"
                         id="3 Pm To 6 Pm"
                         value="3 Pm To 6 Pm"
                         checked={contact.time_slot === '3 Pm To 6 Pm'}
                         onChange={handleTimeSlotChange}
                       />
                       <Form.Check
                         type="radio"
                         label="Specific"
                         name="time_slot"
                         id="Specific"
                         value="Specific"
                         checked={contact.time_slot === 'Specific'}
                         onChange={handleTimeSlotChange}
                       />
                     </div>
                   </Form.Group>
                 </Col>
               
                 {contact.time_slot === "Specific" &&  (<Col lg={6} className="mt-2">
                 <Form.Group className="mx-3" controlId="formBasicTitle">
                     <Form.Label className="form-view-label">Specific Time</Form.Label>
                     <Form.Control
                       type="text"
                       name="specific_time"
                       placeholder="Enter Specific Time"
                       onChange={handleChange}
                       value={contact.specific_time}
                     />
                   </Form.Group>
                 </Col>)}
               </>
                    )}
                    {/* //Added by shivani on 6 aug end */}
                  <Col lg={6} className="mt-1" >
                  <Form.Group className="mx-3" controlId="formBasicPhone">
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Location Link
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="location_link"
                                        placeholder="Enter Location Link"
                                        value={contact.location_link}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter location_link.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicStreet">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicStreet"
                      >
                        Complete Address
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="street"
                        placeholder="Enter Street"
                        value={contact.street}
                        onChange={handleChange}
                      />
                          {/* Added by shivani  */}
                          <Form.Control.Feedback type="invalid">
                             Please provide a street.
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicState">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicState"
                      >
                        State
                      </Form.Label>
                      <Select
                        placeholder="State"
                        value={state.find(option => option.value === contact.state)} // Added by shivani
                        onChange={handleState}
                        options={state}
                        // className="custom-select username" // Added by shivani
                        //value={salutationValue}
                      ></Select>
                        {/* Added by shivani start */}
                        {/* {showValidationMessage && !contact.state && (
                                      <div className="invalid-feedback d-block">
                                        Please provide a state
                                      </div>
                                    )} */}
                                    {/* added by shivani ends */}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicCity">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCity"
                      >
                        City
                      </Form.Label>
                      <Select
                        options={cities}
                        onChange={(e) => {
                          handleSelectListChange(e, "city");
                        }}
                        name="city"
                        value={cities.find(option => option.value === contact.city) || { label: contact.city, value: contact.city }} // Added by shivani
                        placeholder="Enter City"
                        // className="custom-select username" // Added by shivani
                      />
                        {/* Added by shivani start */}
                        {/* {showValidationMessage && !contact.city && (
                                      <div className="invalid-feedback d-block">
                                        Please provide a city
                                      </div>
                                    )}
                                    added by shivani ends */}
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicPin">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPin"
                      >
                        Pincode
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="pincode"
                        placeholder="Enter PinCode"
                        value={contact.pincode}
                        onChange={handleChange}
                        // required //Added by shivani
                      />
                        {/* Added by shivani  */}
                        <Form.Control.Feedback type="invalid">
                             Please provide a pincode.
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicCountry">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicCountry"
                      >
                        Country
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        type="text"
                        name="country"
                        placeholder="Enter Country"
                        value={contact.country}
                        onChange={handleChange}
                        // required //Added by shivani
                      />
                         {/* Added by shivani  */}
                         <Form.Control.Feedback type="invalid">
                             Please provide a country.
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {/*<Row className="justify-content-md-center">
                            <Col lg={8}>
                                <Button  className="mx-3" variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Col>
                </Row>*/}
              </Row>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Main>
  );
};

export default ContactEdit;