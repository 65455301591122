import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "../File/FilesCreate";
import RelatedListFiles from "../File/RelatedListFiles";
import Path from "../common/Path";
import moment from "moment";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from "../../constants/CONSTANT";
import RelatedListPriceBook from "../PriceBook/RelatedListPriceBook";
import PubSub from "pubsub-js";
import jwt_decode from "jwt-decode";
import Helmet from "react-helmet";
import Main from "../layout/Main";
import PriceBookCreate from "../PriceBook/PriceBookCreate";
import Image from 'react-bootstrap/Image';
import Permissionhelper from "../common/Permissionhelper";

const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [lead, setLead] = useState(location.state ? location.state : {});
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [refreshTaskList, setRefreshPriceBook] = useState();
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalPriceBook, setModalPriceBook] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [relatedListPriceBook, setrelatedListPriceBook] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [imageData, setImageData] = useState(); 

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem("token")));
    fetchLead();
  }, []);

  const fetchLead = () => {
    // Fetch lead details and image data
    if (!lead.id && location.hasOwnProperty("pathname") && location.pathname.split("/").length >= 3) {
      lead.id = location.pathname.split("/")[2];
    }
  
    async function initProduct() {
      try {
        let result = await WhiteObjectApi.fetchProductById(lead.id);
        console.log("Lead data:", result);
  
        let productImageResult = await WhiteObjectApi.fetchPrimaryImages(lead.id);
        console.log("Product image result:", productImageResult);
        if(productImageResult){
          let productImageData = await WhiteObjectApi.downloadFiles(productImageResult.id,"ProductImages");
        console.log("Product image data:", productImageData);
  
        // Convert Blob to base64 string
        let reader = new FileReader();
        reader.readAsDataURL(productImageData);
        reader.onloadend = () => {
          let base64data = reader.result;
          console.log("Base64 Product Image:", base64data);
          setImageData(base64data);
        };
  
        }
  
        
        if (result) {
          setLead(result);
          setData(result);
        } else {
          setLead({});
        }
      } catch (error) {
        console.error("Error fetching lead:", error);
      }
    }
  
    initProduct();
  };
  
  

  const deleteProduct = async () => {
    // Delete product function
    const result = await WhiteObjectApi.deleteProduct(location?.state.id);
    if (result) {
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Deleted ",
        message: "Record Deleted successfully",
      });
      navigate(`/products`);
    }
  };

  const editLead = () => {
    navigate(`/Products/${lead.id}/e`, { state: lead });
  };

  const handleSelect = (key) => {
    // Handle tab selection
    if (key === "pricebook") {
      setrelatedListPriceBook(true);
      setRelatedListFiles(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setrelatedListPriceBook(false);
    }
  };

  const submitFiles = () => {
    // Submit files function
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalPriceBook(false);
    fetchLead(); //Added by shivani
  };

  const submitPriceBooks = () => {
    // Submit price books function
    setModalPriceBook(false);
    setModalShowEmail(false);
    setRefreshPriceBook(Date.now());
  };

  return (
    <Main>
      <>
        <Helmet>
          <title>{LIST_TAB_TITLE.ProductView}</title>
        </Helmet>
      </>
      <Container>
        {lead && (
          <>
            {modalShow && (
              <Confirm
                show={modalShow}
                onHide={() => setModalShow(false)}
                deleteProduct={deleteProduct}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="Product"
              />
            )}
            <Row className="view-form">
              <Col lg={11} className="pb-3 pt-2">
                <Link className="nav-link" to="/Products">
                  Home <i className="fa-solid fa-chevron-right"></i>{" "}
                  <div style={{ color: "#23468c", display: "inline" }}>
                    Product
                  </div>
                </Link>
              </Col>
              <Col></Col>
            </Row>
            <Row className="view-form gx-5 mx-auto">
              <Col lg={8}>
                <Row className="view-form-header align-items-center">
                  <Col lg={8}>
                    Product
                    <h5>{lead.Product_name}</h5>
                  </Col>
                  <Col lg={4} className="d-flex justify-content-end">
                    <Button
                      className="btn-sm mx-2"
                      onClick={() => editLead(true)}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </Button>
                    {Permissionhelper.checkPermission(constants.SUPER_ADMIN) && 
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => setModalShow(true)}
                    >
                      Delete
                    </Button>
                  }
                    {/* <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowEmail(true)}
                      variant="success"
                      title="Send Email"
                    >
                      <i class="fa-regular fa-envelope mx-2"></i>
                    </Button> */}
                  </Col>
                </Row>

                <Row className="py-3 ibs-edit-form">
                  <Col lg={6}>
                    <label>Product Name</label>
                    <span>{lead.product_name}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Product Code</label>
                    <span>{lead.product_code}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Product Description</label>
                    <span>{lead.product_description}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Source</label>
                    <span>{lead.source}</span>
                  </Col>
                  {/* <Col lg={6}>
                    <label>Product External Source Name</label>
                    <span>{lead.external_source_name}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Product External Source Contact</label>
                    <span>{lead.external_source_contact}</span>
                  </Col> */}
                  {lead.source === 'external' && (
                    <>
                    <Col lg={6}>
                        <label>External Source Contact</label>
                        <span>{lead.external_source_contact}</span>
                      </Col>
                      
                      <Col lg={6}>
                        <label>Expected Date of Arrival</label>
                        <span>{lead.expected_date_of_arrival ? moment(lead.expected_date_of_arrival).format(
                          "DD MMM, yyyy"
                        ) : ""}</span>
                      </Col>
                      <Col lg={6}>
                        <label>Source Order Date</label>
                        <span>{lead.source_order_date ? moment(lead.source_order_date).format(
                          "DD MMM, yyyy"
                        ) : ""}</span>
                      </Col>
                      <Col lg={6}>
                        <label>PI Number</label>
                        <span>{lead.pi_number}</span>
                      </Col>
                    </>
                  )}
                  <Col lg={12}>
                    <label>Created Date</label>
                    <span>
                      {moment(lead.createddate).format("DD MMM, yyyy")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col>
                {imageData && <Image style={{height :"300px",width : "300px"}} src={imageData} fluid />}
              </Col>
            </Row>

            <Card bg="light" text="light" className="mb-2 mt-4">
              <Card.Header className="d-flex justify-content-between">
                <Tabs
                  defaultActiveKey="files"
                  id="uncontrolled-tab-example"
                  onSelect={(key) => handleSelect(key)}
                >
                  <Tab eventKey="files" title="Files"></Tab>
                  <Tab eventKey="pricebook" title="Pricebook"></Tab>
                </Tabs>

                {relatedListFiles && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowFile(true)}
                  >
                    New File
                  </Button>
                )}
                {relatedListPriceBook && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalPriceBook(true)}
                  >
                    New PriceBook
                  </Button>
                )}
                {modalShowFile && (
                  <FilesCreate
                    show={modalShowFile}
                    onHide={() => setModalShowFile(false)}
                    parent={lead}
                    table="Product"
                    submitFiles={submitFiles}
                  />
                )}

                {modalPriceBook && (
                  <PriceBookCreate
                    show={modalPriceBook}
                    onHide={() => setModalPriceBook(false)}
                    table="lead"
                    submitPriceBooks={submitPriceBooks}
                    parentId={lead.id}
                  />
                )}
              </Card.Header>
              <Card.Body>
                {relatedListFiles && (
                  <RelatedListFiles
                    refreshFileList={refreshFileList}
                    parent={lead}
                    table="Product"
                    submitFiles = {submitFiles} //Added by shivani
                  />
                )}
                {relatedListPriceBook && (
                  <RelatedListPriceBook
                    refreshTaskList={refreshTaskList}
                    parent={lead}
                  />
                )}
              </Card.Body>
            </Card>
          </>
        )}
      </Container>
    </Main>
  );
};

export default LeadView;