import * as constants from "../constants/CONSTANT";
import authApi from "./authApi";

import { getAxios, getAxiosFile,getAxiosMultipart } from "../utils/helper";
const WhiteObjectApi = {
  //************* User****************************/

  //***********User Location Api*****************/
  async getCurrentUserTrackingRec() {
    const token = localStorage.getItem("token");
   
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/track/currentrecord",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    
    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    ////console.log("current record from api  in fetch =>", result);
    if (result) {
      console.log('Result in fetch =>', result)
      return result;
    }
    return null;
  },

  async createCheckInRecord(locationRecord) {
    const token = localStorage.getItem("token");
    console.log("UserLocationRecord =>", locationRecord);

    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(locationRecord),
    });

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    ////console.log("result in fetch =>", result);
    if (result) {
      //////console.log('result in fetch => ', result)
      return result;
    }
    return null;
  },
  async fetchUsertrackingsWithstaffId(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings/staff/" + staffId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    ////console.log('result', result)
    if (result.length > 0) {
      return result;
    }
    return null;

  },


  async fetchSystemNotifications(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/notifications", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    ////console.log('result', result)
    if (result && result.length > 0) {
      return result;
    }
    return null;

  },


  async handlCheckOut(locationRecord) {
    const token = localStorage.getItem("token");
    ////console.log("handlCheckOut => ", locationRecord);
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/" + locationRecord.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(locationRecord),
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    ////console.log("rsult in checkout => ", result);
    if (result) {
      return result;
    }
    return null;
  },
  
  //.................... Fetch Users ...................................
  async fetchUsers() {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/auth/users"}`
    );
    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },

  //.................... Fetch Login Users ...................................
  async getLoginUserData() {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/auth/getuser"}`
    );
    if (data.status === 401) {
      authApi.logout();
    }

    if (data) {
      return data;
    }
    return null;
  },

  async createUser(user) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/auth/createuser",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(user),

      });
      //console.log('response',response)
    return await response.json();

  },

  //.............. Fetch Lead By Id .............................
  async fetchUserById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    console.log('response',result)
    return result;
  },

  //.................... Save Login Users ...................................
  async saveUser(user) {
    const instance = getAxios();
    const { data } = await instance.put(
      `${constants.API_BASE_URL + "/api/auth/" + user.id}`,
      user
    );

    if (data) {
      return data;
    }
    return null;
  },

  //------------- Save Staff Member Profile ---------------------------
  async saveStaffMemberEditProfile(userid,selectedFiles, staffMember) {
    const formData = new FormData();
    formData.append('file', selectedFiles);
    formData.append('staffRecord', staffMember);
    const instance = getAxiosFile();
    const { data } = instance.put(
      `${constants.API_BASE_URL + "/api/auth/" + userid + "/profile"}`,formData
    ); 
    if (data){
      return data;
    }
    return null;
  },

  //.................... Update Login Users ...................................
  async updateUser(user) {
    const instance = getAxios();
    const { data } = await instance.put(
      `${constants.API_BASE_URL + "/api/auth/change/updatepassword"}`,
      user
    );
    if (data) {
      return data;
    }
    return null;
  },


  async fetchSystemNotifications(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/notifications", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    ////console.log('result', result)
    if (result && result.length > 0) {
      return result;
    }
    return null;

  },
  async fetchCompanySetting(settingName) {
    ////console.log('--settingName--', settingName)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/settings/" + settingName, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });
  },
  // --------------------------------- Report ----------------------------------------

  async fetchReports() {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/reports"}`
    );
    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },
  
  async fetchReportsById(row) {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/reports/" + row.id}`
    );
    if (data.status === 401) {
      authApi.logout();
    }
    if (data) {
      return data;
    }
    return null;
  },


  //   //************************ leads ***********************************//

//   //................... Create leads ................................
  async createLead(leads) {
    const instance = getAxios();
    const { data } = await instance.post(
      `${constants.API_BASE_URL + "/api/leads/"}`,
      leads
    );

    if (data) {
      return data;
    }
    return null;
  },

  async saveLead(leads) {
    const instance = getAxios();
    const { data } = await instance.put(
      `${constants.API_BASE_URL + "/api/leads/" + leads.id}`,
      leads
    );
    if (data) {
      return data;
    }
    return null;
  },

  //................... Fetch All leads Converted ................................
  async fetchLeadConverted() {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/leads/leadconverted"}`
    );

    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },

  //................... Fetch All leads Not Converted ................................
  async fetchLeadNotConverted(filterDateData) {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL}/api/leads/closeconverted/${filterDateData.month}/${filterDateData.year}`      
    );

    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },
   //................... Fetch All Contacts Related to leads  ................................
   async fetchContactsLeadByid(id) {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/leads/contacts/" + id}`
    );

    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },

  //................... Fetch All leads ................................
  async fetchLead() {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/leads/"}`
    );

    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },

   //................... Fetch All leads (Today Follow-Up) ................................
   async fetchFollowUpLeads() {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/tasks/followups"}`
    );

    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },



   //................... Fetch All Leads Counts ................................
   async fetchLeadCount(filterDate) {
    console.log("Filter Date on Api",filterDate)
    const instance = getAxios();
    const { data } = await instance.get(
      // `${constants.API_BASE_URL + "/api/leads/total"}`
      `${constants.API_BASE_URL}/api/leads/total/${filterDate.month}/${filterDate.year}`
    );

    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },

  //................... Fetch All Leads User Wise ................................
  async fetchLeadUserWise(filterDate) {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL}/api/leads/userwise/${filterDate.month}/${filterDate.year}`
    );

    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },

  //................... Fetch All Leads Current Month Wise ................................
  async fetchLeadCurrentMonth(filterDate) {
    console.log("HelloWorld",filterDate)
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL}/api/leads/currentmonth/${filterDate.month}/${filterDate.year}`
    );

    if (data.status === 401) {
      authApi.logout();
    }

    if (data.length > 0) {
      return data;
    }
    return null;
  },

  
  //.............. Fetch leads By Id .............................
  async fetchLeadById(id) {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/leads/" + id}`
    );
    if (data.status === 401) {
      authApi.logout();
    }
    if (data) {
      return data;
    }
    return null;
  },

    
  // .......... Delete Lead .............................
  async deleteLead(id) {
    const instance = getAxios();
    const { data } = await instance.delete(
      `${constants.API_BASE_URL + "/api/leads/" + id}`
    );
    if (data) {
      return data;
    }
    return null;
  },
  // *************** Messages *******************

  // .......... All Messages .............................
  async fetchMessages(pid) {
    const instance = getAxios();
    const { data } = await instance.get(
      `${constants.API_BASE_URL + "/api/messages/" + pid + "/*"}`
    );
    if (data) {
      return data;
    }
    return null;
  },
  // ******************Files ************************************
  
  async fetchFiles(parentId,folderName) {
    console.log('--foldername in APi Side--', folderName)
    const token = localStorage.getItem("token"); 
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + parentId + "/" + folderName + "/all", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });

    const result = await response.json();
    ////console.log('result', result);
    ////console.log(response)
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  async fetchPrimaryImages(parentId,folderName) {
    console.log('--foldername in APi Side--', folderName)
    const token = localStorage.getItem("token"); 
    let response = await fetch(constants.API_BASE_URL + "/api/files/primaryphotos/" + parentId , {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });

    const result = await response.json();
    ////console.log('result', result);
    ////console.log(response)
    if (result) {
      return result;
    }
    return null;

  },

  //************Download File ************ */
  async downloadFiles(fileid,foldername) {
    ////console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      `${constants.API_BASE_URL}/api/files/${fileid}/${foldername}/download`,

      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    return fileBody;

  },
  async createFile(pid, formData) {
    console.log("FormData",formData)
 
    const token = localStorage.getItem("token");
    
    try {
        let response = await fetch(constants.API_BASE_URL + "/api/files/" + pid, {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": token,
            },
            body: formData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            // Handle different response statuses
            const errorData = await response.json();
            throw new Error(`Error ${response.status}: ${errorData.message}`);
        }
    } catch (error) {
        console.error('File upload failed', error);
        throw error;
    }
},

//----Service Form File Create --------------------------------

async createFileService(pid, formData) {
   
  console.log("FormData",formData)
 
    // const token = localStorage.getItem("token");
    
    try {
        let response = await fetch(constants.API_BASE_URL + "/api/files/fileservice/" + pid, {
            method: "POST",
            mode: "cors",
            // headers: {
            //     "Authorization": token,
            // },
            body: formData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            // Handle different response statuses
            const errorData = await response.json();
            throw new Error(`Error ${response.status}: ${errorData.message}`);
        }
    } catch (error) {
        console.error('File upload failed', error);
        throw error;
    }
},

// async createFileService(pid, formData) {
//   console.log("FormData",formData)

 
//   try {
//       let response = await fetch(constants.API_BASE_URL + "/api/files/fileservice" + pid, {
//           method: "POST",
//           mode: "cors",
//           body: formData,
//       });

//       if (response.ok) {
//           return await response.json();
//       } else {
//           // Handle different response statuses
//           const errorData = await response.json();
//           throw new Error(`Error ${response.status}: ${errorData.message}`);
//       }
//   } catch (error) {
//       console.error('File upload failed', error);
//       throw error;
//   }
// },

  //************************ files ***********************************//

  async saveFiles(file) {
    console.log("File on Update",file);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(file),
      }
    );

    return await response.json();

  },
  //eslint-disable-next-line
  async deleteFile(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return await response.json();
  },

 

  // *******************************Projects******************************************
  //   //************************ Project ***********************************//

//   //................... Create Project ................................
async createProject(projects) {
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/projects/"}`,
    projects
  );

  if (data) {
    return data;
  }
  return null;
},

async saveProject(projects) {
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/projects/" + projects.id}`,
    projects
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Fetch All projects (Non Archive Records)................................
async fetchProject() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All projects (All Records Service Form)................................
// async fetchProjectsListServiceForm(invoiceNo) {
//   const instance = getAxios();
//   const { data } = await instance.get(
//     `${constants.API_BASE_URL}/api/projects/all?invoiceno=${invoiceNo}`
//   );

//   if (data.status === 401) {
//     authApi.logout();
//   }

//   if (data.length > 0) {
//     return data;
//   }
//   return null;
// },

async fetchProjectsListServiceForm(invoiceNo) {
   
  let response = await fetch(
    `${constants.API_BASE_URL}/api/projects/service/all?invoiceno=${invoiceNo}`,
    {
      method: "GET",  // Use uppercase "GET" for clarity
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`, // Uncomment and adjust if using an auth token
      },
    }
  );
  
  
  const result = await response.json();
  ////console.log("current record from api  in fetch =>", result);
  if (result) {
    console.log('Result in fetch =>', result)
    return result;
  }
  return null;
},


//................... Fetch All projects (All Records)................................
async fetchProjectsList(invoiceNo) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL}/api/projects/all?invoiceno=${invoiceNo}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},



//................... Fetch All Projects Current Month ................................
async fetchProjectCurrentMonth(filterDate) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL}/api/projects/currentmonth/${filterDate.month}/${filterDate.year}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All Projects Total ................................
async fetchProjectTotal(filterDate) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL}/api/projects/total/${filterDate.month}/${filterDate.year}`
  );

  if (data.status === 401) {  
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All Projects Total Revenue ................................
async fetchProjectRevenueTotal(filterDate) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL}/api/projects/totalrevenue/${filterDate.month}/${filterDate.year}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All Projects Product Stages ................................
async fetchProjectProductStages() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/productstages"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//.............. Fetch Projects By Id .............................
async fetchProjectById(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},


//.............. Fetch Projects By  Lead Id .............................
async fetchProjectByLeadId(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/lead" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

//.............. Fetch Projects By Lead Id .............................
async fetchProjectByLeadId(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/leads/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},


//.............. Fetch Projects By Lead Id (For Dashboard).............................
async fetchProjectByLeadsId(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/lead/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

  
// .......... Delete Projects .............................
async deleteProject(id) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/projects/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},


// *******************************Projects Project Product Line Item******************************************
  
//   //................... Create Project Product Line Item ................................
async createProjectProductLineItem(projects) {
  console.log("projects",projects)
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/productslineitems/"}`,
    projects
  );

  if (data) {
    return data;
  }
  return null;
},

async saveProjectProductLineItem(projects) {
  console.log("id on save lineItem",projects)
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/productslineitems/" + projects.id}`,
    projects
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Fetch All Project Product Line Item ................................
async fetchProjectProductLineItem() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/productslineitems/"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},


//.............. Fetch Project Product Line Item By Id .............................
async fetchProjectProductLineItemById(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/productslineitems/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

  
// .......... Delete Project Product Line Item .............................
async deleteProjectProductLineItem(id) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/productslineitems/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},

// *******************************Product Starts here**********************************
  
//   //................... Create Product ................................
async createProduct(products) {
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/products/"}`,
    products
  );

  if (data) {
    return data;
  }
  return null;
},

async saveProduct(products) {
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/products/" + products.id}`,
    products
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Fetch All Product ................................
async fetchProduct() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/products/"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All Product With PriceBook ................................
async fetchProductPriceBooks() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/products/productpricebooks"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},


//.............. Fetch Product By Id .............................
async fetchProductById(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/products/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

//.............. Fetch Product By Project Id .............................
async fetchProductByProjectId(id) {
  const instance = getAxios();
  const { data } = await instance.get(`${constants.API_BASE_URL + "/api/products/projects/" + id}`);
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

  
// .......... Delete Product   .............................
async deleteProduct(id) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/products/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},


// *******************************Pricebooks here**********************************
  
//   //................... Create Pricebooks ................................
async createPricebook(pricebooks) {
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/pricebooks/"}`,
    pricebooks
  );

  if (data) {
    return data;
  }
  return null;
},

async savePricebook(pricebooks) {
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/pricebooks/" + pricebooks.id}`,
    pricebooks
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Fetch All Pricebooks ................................
async fetchPricebooks() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/pricebooks/"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},


//.............. Fetch Priebook By Id .............................
async fetchPriebookById(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/pricebooks/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

//.............. Fetch Pricebooks By Product Id .............................
async fetchPricebookByProductId (id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/pricebooks/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

// .......... Delete Priebook   .............................
async deletePriceBook(id) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/pricebooks/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},

// *******************************Account******************************************
  
//   //................... Create Account ................................
async createAccount(projects) {
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/accounts/"}`,
    projects
  );

  if (data) {
    return data;
  }
  return null;
},

async saveAccount(accounts) {
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/accounts/" + accounts.id}`,
    accounts
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Fetch All Account ................................
async fetchAccounts() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/accounts/"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},


//.............. Fetch Account By Id .............................
async fetchAccountById(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/accounts/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

  
// .......... Delete Accounts .............................
async deleteAccounts(id) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/accounts/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},
// *******************************Messages******************************************
  
//   //................... Create Messages ................................
async createMessage(messages) {
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/messages/"}`,
    messages
  );

  if (data) {
    return data;
  }
  return null;
},

// *******************************Contact******************************************
  
//   //................... Create Contact ................................
async createContact(contacts) {
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/contacts/"}`,
    contacts
  );

  if (data) {
    return data;
  }
  return null;
},


//   //................... Create Contact (External Enquiry Form)................................
async createContactEnquiry(enquiryDetails) {
   
  let response = await fetch(
    constants.API_BASE_URL + "/api/contacts/enquiry",
    {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(enquiryDetails),
    }
  );
  
  const result = await response.json();
  ////console.log("current record from api  in fetch =>", result);
  if (result) {
    console.log('Result in fetch =>', result)
    return result;
  }
  return null;
},

async saveContact(contacts) {
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/contacts/" + contacts.id}`,
    contacts
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Fetch All Contact ................................
async fetchContacts() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/contacts/"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All Supplier Contact ................................
async fetchContactsSupllier() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/contacts/supplier"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All Contact ................................
async fetchContact() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/contacts/"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},


//.............. Fetch Contact By Id .............................
async fetchContactById(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/contacts/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

  
// .......... Delete Contact .............................
async deleteContacts(id) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/contacts/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},

// *****************************Tasks*******************************
//************************ Task ***********************************//
async fetchTasks(pid) {
  const token = localStorage.getItem("token");
  ///"+pid+"/*
  let response = await fetch(constants.API_BASE_URL + "/api/tasks/" + pid + "/*", {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }

  });
  ////console.log(response)
  const result = await response.json();
  ////console.log(result);
  if (result.length > 0) {
    ////console.log(result)
    return result;
  }
  return null;

},
async createTask(task) {
  const token = localStorage.getItem("token");
  let response = await fetch(constants.API_BASE_URL + "/api/tasks/",
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(task),

    });

  return await response.json();

},

async fetchMessages(pid) {
  const token = localStorage.getItem("token");
  ///"+pid+"/*
  let response = await fetch(constants.API_BASE_URL + "/api/messages/" + pid + "/*", {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }

  });
  ////console.log(response)
  const result = await response.json();
  ////console.log(result);
  if (result.length > 0) {
    ////console.log(result)
    return result;
  }
  return null;

},

async fetchUnreadMessages() {
  const token = localStorage.getItem("token");
  ///"+pid+"/*
  let response = await fetch(constants.API_BASE_URL + "/api/messages/unread", {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }

  });
  ////console.log(response)
  const result = await response.json();
  //console.log(result);
  if (result.length > 0) {
    ////console.log(result)
    return result;
  }
  return null;

},

async markReadMessage(notificationid) {
  const token = localStorage.getItem("token");
  ///"+pid+"/*
  let response = await fetch(constants.API_BASE_URL + "/api/messages/markread/" + notificationid, {
    method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
  });
  ////console.log(response)
  const result = await response.json();
  //console.log(result);
  if (result.length > 0) {
    ////console.log(result)
    return result;
  }
  return null;

},

async deletePushNotification(notificationid) {
  const token = localStorage.getItem("token");
  ///"+pid+"/*
  let response = await fetch(constants.API_BASE_URL + "/api/messages/markdelete/" + notificationid, {
    method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
  });
  ////console.log(response)
  const result = await response.json();
  //console.log(result);
  if (result.length > 0) {
    ////console.log(result)
    return result;
  }
  return null;

},


async sendEmailTask(task) {
  const token = localStorage.getItem("token");
  let response = await fetch(constants.API_BASE_URL + "/api/tasks/sendemail",
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(task),

    });

  return await response.json();

},

async fetchAllMeetings(today) {
  const token = localStorage.getItem("token");

  let response = await fetch(constants.API_BASE_URL + "/api/tasks/meetings/today", {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }

  });
  ////console.log(response)
  const result = await response.json();
  ////console.log(result);
  if (result.length > 0) {

    return result;
  }
  return null;

},
// -------Fetch Delted History of Archive Records -------------------
async fetchDeletedHistoryArchive() {
  const token = localStorage.getItem("token");

  let response = await fetch(constants.API_BASE_URL + "/api/tasks/deleted", {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }

  });
  ////console.log(response)
  const result = await response.json();
  ////console.log(result);
  if (result.length > 0) {

    return result;
  }
  return null;

},

async saveTask(task) {
  ////console.log(task.id)
  const token = localStorage.getItem("token");
  let response = await fetch(
    constants.API_BASE_URL + "/api/tasks/" + task.id,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      },
      body: JSON.stringify(task),
    }
  );

  return await response.json();

},

async deleteTask(id) {
  const token = localStorage.getItem("token");
  let response = await fetch(
    constants.API_BASE_URL + "/api/tasks/" + id,
    {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      }
    }
  );

  return await response.json();

},

async deleteMessage(id) {
  const token = localStorage.getItem("token");
  let response = await fetch(
    constants.API_BASE_URL + "/api/messages/" + id,
    {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      }
    }
  );

  return await response.json();

},
async fetchTasksWithoutParent() {
  const token = localStorage.getItem("token");

  let response = await fetch(constants.API_BASE_URL + "/api/tasks/opentasks", {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }

  });
  ////console.log(response)
  const result = await response.json();
  ////console.log(result);
  if (result.length > 0) {

    return result;
  }
  return null;

},
async fetchAllMeetings(today) {
  const token = localStorage.getItem("token");

  let response = await fetch(constants.API_BASE_URL + "/api/tasks/meetings/today", {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }

  });
  ////console.log(response)
  const result = await response.json();
  ////console.log(result);
  if (result.length > 0) {

    return result;
  }
  return null;
},
// *******************************Payments******************************************
  
//   //................... Create Payments ................................
async createPayment(payments) {
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/payments/"}`,
    payments
  );

  if (data) {
    return data;
  }
  return null;
},

async savePayment(payments) {
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/payments/" + payments.id}`,
    payments
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Fetch All Payments ................................
async fetchPayments(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/payments/parent/" + id}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All Payments (Project Wise) ................................
async fetchProjectPayments(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/payments/" + id}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data) {
    return data;
  }
  return null;
},


//................... Fetch All Payments (Project Wise to show On Lead Page) ................................
async fetchProjectPaymentsLeads(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/payments/leads/" + id}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data) {
    return data;
  }
  return null;
},


//................... Fetch All Payments (Project Wise to show On Lead Page (project Total Amount)) ................................
async fetchProjectTotalAmountLeads(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/projects/pay/leads/" + id}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data) {
    return data;
  }
  return null;
},


//.............. Fetch Payment By Id .............................
async fetchPaymentById(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/payments/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

  
// .......... Delete Payments .............................
async deletePayment(id) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/payments/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},

// *******************************Archives******************************************
  
//   //................... Create Archives ................................
async createArchive(archives) {
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/archives/"}`,
    archives
  );

  if (data) {
    return data;
  }
  return null;
},

async saveArchive(archives) {
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/archives/" + archives.id}`,
    archives
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Fetch All Archives (Leads) ................................
async fetchLeadsArchives() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/archives/leads"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Fetch All Archives (Projects) ................................
async fetchProjetcsArchives() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/archives/projects"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},


// //.............. Fetch Archives By Id .............................
// async fetchAccountById(id) {
//   const instance = getAxios();
//   const { data } = await instance.get(
//     `${constants.API_BASE_URL + "/api/accounts/" + id}`
//   );
//   if (data.status === 401) {
//     authApi.logout();
//   }
//   if (data) {
//     return data;
//   }
//   return null;
// },

  
// .......... Delete Archives .............................
async deleteArchive(id) {
  console.log("Aid",id)
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/archives/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},

//................... Create Backup ................................
async createBackup(lead) {
  const token = localStorage.getItem("token");

  let response = await fetch(constants.API_BASE_URL + "/api/backup", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(lead),
  });
  return await response.json();
},

//................... Fetch Backup ................................
async fetchBackups() {
  const token = localStorage.getItem("token");
  let response = await fetch(constants.API_BASE_URL + "/api/backup", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (response.status === 401) {
    authApi.logout();
  }
  const result = await response.json();
  if (result.length > 0) {
    return result;
  }
  return null;
},

//...................  Download Backup ................................
async downloadBackupFile(filename) {
  const token = localStorage.getItem("token");
  let response = await fetch(
    constants.API_BASE_URL + "/api/backup/download/" + filename,
    {
      method: "GET",
      //mode: "cors",

      headers: {
        Authorization: token,
      },
    }
  );
  const fileBody = await response.blob();
  return fileBody;
},

//...................  Delete Backup ................................
async deleteBackupFile(filename) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/backup/delete/" + filename}`
  );
  if (data.length > 0) {
    return data;
  }
  return null;
},



// *******************************Repair Ticket******************************************
  //   //************************ Repair Ticket ***********************************//

//   //................... Create Repair Ticket ................................
async createRepairTicket(projects) {
  console.log("Repair Ticket =>",projects);
  const instance = getAxios();
  const { data } = await instance.post(
    `${constants.API_BASE_URL + "/api/repairprojects/"}`,
    projects
  );

  if (data) {
    return data;
  }
  return null;
},



//   //................... Create Repair Ticket External ................................
async createRepairTicketExternal(enquiryDetails) {
  let response = await fetch(
    constants.API_BASE_URL + "/api/repairprojects/serviceform",
    {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(enquiryDetails),
    }
  );
  
  const result = await response.json();
  console.log("current record from api  in fetch =>", result);
  if (result) {
    console.log('Result in fetch =>', result)
    return result;
  }
  return null;
},

async saveRepairTicket(projects) {
  const instance = getAxios();
  const { data } = await instance.put(
    `${constants.API_BASE_URL + "/api/repairprojects/" + projects.id}`,
    projects
  );
  if (data) {
    return data;
  }
  return null;
},


//................... Fetch All Projects Repair Tiket Total ................................
async fetchRepairTicketTotal(filterDate) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL}/api/repairprojects/total/sm/${filterDate.month}/${filterDate.year}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},

//................... Find Contact By Phone ................................
async fetchContactByPhone(phone) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL}/api/contacts/p/${phone}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},


//................... Fetch All Lead By Repair Lead Name ................................
async fetchRepairLeadByLeadName(leadname) {
  try {
    const instance = getAxios();
    const response = await instance.get(
      `${constants.API_BASE_URL}/api/repairprojects/lead/all/?leadname=${encodeURIComponent(leadname)}`
    );

    if (response.status === 401) {
      authApi.logout();
      return null; // Return null if unauthorized
    }

    const data = response.data;

    if (data && data.length > 0) {
      return data;
    } else {
      return null; // Return null if no data
    }
  } catch (error) {
    console.error("Error fetching repair lead by lead name:", error);
    return null; // Return null in case of error
  }
},


//................... Fetch All projects ................................
async fetchRepairTicket() {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/repairprojects/"}`
  );

  if (data.status === 401) {
    authApi.logout();
  }

  if (data.length > 0) {
    return data;
  }
  return null;
},



//.............. Fetch Repair Ticket By Id .............................
async fetchRepairTicketById(id) {
  const instance = getAxios();
  const { data } = await instance.get(
    `${constants.API_BASE_URL + "/api/repairprojects/" + id}`
  );
  if (data.status === 401) {
    authApi.logout();
  }
  if (data) {
    return data;
  }
  return null;
},

  
// .......... Delete Repair Ticket .............................
async deleteRepairTicket(id) {
  const instance = getAxios();
  const { data } = await instance.delete(
    `${constants.API_BASE_URL + "/api/repairprojects/" + id}`
  );
  if (data) {
    return data;
  }
  return null;
},
  //End Here
};

export default WhiteObjectApi;
