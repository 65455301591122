import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "../File/FilesCreate";
import RelatedListFiles from "../File/RelatedListFiles";
// import RealetedLeadTest from "./RealetedLeadTest";
import RelatedListProjects from "../Lead/RelatedListProjects";
import UserTracking from "../Lead/UserTracking";
// import TaskEdit from "./TaskEdit";
import Path from "../common/Path";
import moment from "moment";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
// import * as constants from '../../constants/CONSTANT';
import Permissionhelper from "../common/Permissionhelper";
import * as constants from "../../constants/CONSTANT";
import PubSub from "pubsub-js";
import EmailComposer from "../common/EmailComposer";
import Chat from "../common/Chat";
import jwt_decode from "jwt-decode";
import Helmet from "react-helmet";
import Main from "../layout/Main";
import { Shimmer } from "react-shimmer";
import Image from "react-bootstrap/Image";
import RelatedLocationHistory from "../LocationHistory/RelatedLocationHistory";
import RelatedListTask from "../Task/RelatedListTask";
import TaskEdit from "../Task/TaskEdit";
import CurrencyFormatter from "currency-formatter-react";
import { disableCursor } from "@fullcalendar/core/internal";
const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [spinner, setSpinner] = useState(true);
  const [lead, setLead] = useState(location.state ? location.state : {});
  ////console.log('lead', lead)
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [showArchive, setArchiveShow] = useState(false);
  const [imageData, setImageData] = useState();
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [reletedListTask, setReletedListProjects] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [userName, setUserName] = useState("");
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [convertShow, setConvertShow] = useState(true);

  const [showDetails, setShowDetails] = useState(true);
  const [showNotesMessages, setShowNotesMessages] = useState(false);
  const [relatedListLocation, setRelatedListLocationHistory] = useState(false);
  const [relatedListTaskList, setRelatedListTask] = useState(false);
  const [refreshLocationList, setRefreshLocationList] = useState();
  const [editDisable, setDisableEdit] = useState(true);
  const [payments, setPayments] = useState({});
  // const [updateCounter, setUpdateCounter] = useState(0);

  const [messages, setMessages] = useState([
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this.",
    },
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this.",
    },
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this.",
    },
  ]);
  const [leadStatusArray, setleadStatusArray] = useState(
    JSON.parse(localStorage.getItem("lead_status"))
  );
  const [userInfo, setUserInfo] = useState({});
  const [proejectTotal, setTotalAmountProject] = useState({});

  useEffect(() => {
    let userInfoJWT = jwt_decode(localStorage.getItem("token"))
    setUserInfo(userInfoJWT);
    fetchLead();
    console.log("Lead View", lead);
    if(userInfoJWT.id === lead.ownerid){
      console.log("Hello Permi")
      setDisableEdit(false);

    }
    //init();
  }, []);

  const fetchLead = () => {
    if (!lead.id && location.hasOwnProperty("pathname") && location.pathname.split("/").length >= 3) {
      lead.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      console.log("lead ki id",lead.id)
      let result = await WhiteObjectApi.fetchLeadById(lead.id);
      let productImageResult = await WhiteObjectApi.fetchPrimaryImages(
        lead.contactid
      );
      const fetchPayment = await WhiteObjectApi.fetchProjectPaymentsLeads(
        lead.id
      );
      const fetchPaymentTotal =
        await WhiteObjectApi.fetchProjectTotalAmountLeads(lead.id);
      console.log("Product image result:", fetchPaymentTotal);
      setTotalAmountProject(fetchPaymentTotal ? fetchPaymentTotal : null);
      if (productImageResult.id) {
        let productImageData = await WhiteObjectApi.downloadFiles(
          productImageResult.id,
          "ContactImages"
        );
        console.log("Product image data:", productImageData);

        // Convert Blob to base64 string
        let reader = new FileReader();
        reader.readAsDataURL(productImageData);
        reader.onloadend = () => {
          let base64data = reader.result;
          console.log("Base64 Product Image:", base64data);
          setImageData(base64data);
        };
      }

      if (
        result?.convertedcontactid &&
        result?.contactname &&
        result.contactname.trim().length > 0
      ) {
        ////console.log('convert call');
        setConvertShow(false);
      }
      if (result) {
        ////console.log("=======", result);

        setLead(result);
        setData(result);
        ////console.log("name : ", lead.firstname);
      } else {
        setLead({});
      }
      console.log("fetchPaymentfetchPayment", fetchPayment);
      if (fetchPayment) {
        setPayments(fetchPayment);
      } else {
        setPayments({});
      }
    }
    initStudent();
  };

  const deleteLead = async () => {
    const result = await WhiteObjectApi.deleteLead(lead.id);

    if (result.success) {
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Deleted",
        message: "Record Deleted successfully",
      });
    }

    navigate(`/leads`);
  };

  const archiveLeads = async () => {
    lead.isarchive = true;
    const achieveLead = await WhiteObjectApi.saveLead(lead);
    if (achieveLead) {
      lead.lead_id = lead.id;
      const result = await WhiteObjectApi.createArchive(lead);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Archive ",
          message: "Record Archive successfully",
        });
      }

      navigate(`/leads`);
    }
  };
  const editLead = () => {
    navigate(`/leads/${lead.id}/e`, { state: lead });
  };

  const createProjects = () => {
    navigate("/projects/e", {
      // state: { leadView: lead.id, fromTable: "leadtable" },
      state: {
        leadView: lead.id,
        contactView: lead.contactid,
        fromTable: "leadtable",
      }, //Added by shivani 7 aug
    });
  };

  const handleSelect = (key) => {
    if (key === "followup") {
      setRelatedListTask(true);
      setRelatedListFiles(false);
      setRelatedListLocationHistory(false);
      setReletedListProjects(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListProjects(false);
      setRelatedListLocationHistory(false);
      setRelatedListTask(false);
    } else if (key === "project") {
      setRelatedListFiles(false);
      setReletedListProjects(true);
      setRelatedListLocationHistory(false);
      setRelatedListTask(false);
    } else if (key === "location") {
      setRelatedListLocationHistory(true);
      setRelatedListFiles(false);
      setReletedListProjects(false);
      setRelatedListTask(false);
    }
    
  };

  const handleSelectTab = (key) => {
    if (key === "details") {
      // setReletedListProjects(true);
      setShowDetails(true);
      setShowNotesMessages(false);
    } else if (key === "notesMessages") {
      setShowDetails(false);
      setShowNotesMessages(true);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
    fetchLead();
  };

  const formatAmount = (value) => {
    // Check if the value is a valid number
    if (isNaN(value)) {
      return "0";
    }

    // Convert to number and format with thousand separators
    const num = parseFloat(value); // Ensure it's a number
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleConvert = async () => {
    let convertStatus = "";

    let status = leadStatusArray.filter((item) => item.is_converted === true);
    //console.log("status:", status);
    convertStatus = status[0].label;
    const result = await WhiteObjectApi.convertLeadToContact(
      lead.id,
      convertStatus
    );
    // console.log('result', result)
    if (result) {
      let contactId = result.convertedcontactid;
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Lead Convert ",
        message: "Record Convert successfully",
      });
      navigate(`/contacts/${contactId}`);
      ////console.log("result.success : ", result.success);
    }
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setModalShowEmail(false);
    setRefreshTaskList(Date.now());
    fetchLead();
  };

  // Find the current status' sortorder
  //  const currentStatus = leadStatusArray.find(status => status.sortorder === lead.leadstatus);
  console.log("leadStatusArray", leadStatusArray);

  // Checking Lead Status array vallue and shwoing as till length as higlighted.
  const highlightedStatusArray = leadStatusArray.map((status) => ({
    ...status,
    highlight: status.sortorder <= lead.leadstatus,
  }));

  const [currentRec, setCurrentRecord] = useState({});
  const [locationRecordv1, setLocationRecordv1] = useState({});
  const [latitudev1, setLatitudev1] = useState(null);
  const [longitudev1, setLongitudev1] = useState(null);
  console.log("lead value", lead);
  const [addressv1, setAddressv1] = useState(
    `${lead.street} ${lead.state} ${lead.zipcode}`
  ); // Default address
  const [buttonDisabledv1, setButtonDisabledv1] = useState(false);
  const [remarksv1, setRemarksv1] = useState("");

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const googleMapScript = document.createElement("script");
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDtztwOW6bufAlbBWfO1k5FdZTkpUP_gmI&callback=initMap`;
        googleMapScript.async = true;
        googleMapScript.defer = true;
        window.document.body.appendChild(googleMapScript);
      } else {
        window.initMap();
      }
    };

    window.initMap = () => {
      myMapFunction();
    };

    loadGoogleMapsScript();
  }, [latitudev1, longitudev1, addressv1]);

  const myMapFunction = async () => {
    if (!latitudev1 || !longitudev1) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: addressv1 }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          setLatitudev1(location.lat());
          setLongitudev1(location.lng());

          const mapProp = {
            center: location,
            zoom: 14,
          };

          const map = new window.google.maps.Map(
            document.getElementById("googleMapaddress"),
            mapProp
          );

          const marker = new window.google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          setAddressv1(results[0].formatted_address);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    } else {
      const location = new window.google.maps.LatLng(latitudev1, longitudev1);
      const mapProp = {
        center: location,
        zoom: 14,
      };

      const map = new window.google.maps.Map(
        document.getElementById("googleMapaddress"),
        mapProp
      );

      const marker = new window.google.maps.Marker({
        map,
        position: location,
        title: addressv1,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: addressv1,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    }
  };

  return (
    <div>
      <>
        <Helmet>
          <title>{LIST_TAB_TITLE.LeadView}</title>
        </Helmet>
      </>
      <Container>
        {lead && (
          <>
            {modalShow && (
              <Confirm
                show={modalShow}
                onHide={() => setModalShow(false)}
                deleteLead={deleteLead}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="lead"
              />
            )}

            {showArchive && (
              <Confirm
                show={showArchive}
                onHide={() => setArchiveShow(false)}
                archiveLeads={archiveLeads}
                title="Confirm Archive?"
                message="You are going to Archive the record. Are you sure?"
                table="archive"
              />
            )}
            <Row className="view-form">
              <Col lg={11} className="pb-3 pt-2">
                <Link
                  className="nav-link"
                  to="/leads"
                  style={{ color: "#23468c" }}
                >
                  Home <i className="fa-solid fa-chevron-right"></i>{" "}
                  <span style={{ color: "#23468c", display: "inline" }}>
                    Leads
                  </span>
                </Link>
              </Col>

              <Col></Col>
            </Row>

            <Row className="view-form gx-5 mx-auto">
              <Col lg={8}>
                <Row className="view-form-header-lead align-items-center">
                  <Col lg={8}>
                    Lead
                    <h5>
                      {lead.salutation ? lead.salutation : ""}
                      {lead.firstname + " " + lead.lastname}
                    </h5>
                  </Col>
                  <Col lg={4} className="d-flex justify-content-end">
                  {userInfo.userrole !== "SUPER_ADMIN" && (
                      <Button
                        className="btn-sm mx-2"
                        onClick={() => editLead(true)}
                        disabled={editDisable}
                      >
                        <i className="fa-regular fa-pen-to-square"></i>
                      </Button>
                    )}

                    {Permissionhelper.checkPermission(constants.SUPER_ADMIN) &&
                    <Button
                      className="btn-sm mx-2"
                      onClick={() => editLead(true)}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </Button>
                       }
                    {Permissionhelper.checkPermission(constants.SUPER_ADMIN) &&
                      lead.leadstatus !== "Archive" && (
                        <Button
                          className="btn-sm"
                          variant="danger"
                          onClick={() => setModalShow(true)}
                        >
                          Delete
                        </Button>
                      )}

                    {lead.leadstatus === "Archive" && (
                      <Button
                        className="btn-sm mx-2"
                        variant="danger"
                        onClick={() => setArchiveShow(true)}
                      >
                        Archive Lead
                      </Button>
                    )}

                    {/* <Button
                      className="float-right btn-sm"
                      onClick={() => setModalShowEmail(true)}
                      variant="success"
                      title="Send Email"
                    >
                      <i class="fa-regular fa-envelope mx-2"></i>
                    </Button> */}
                    {/* {convertShow === true ? (
                    <Button
                      className="btn-sm mx-2"
                      variant="warning"
                      onClick={handleConvert}
                    >
                      Convert
                    </Button>
                  ) : (
                    ""
                  )} */}
                  </Col>
                </Row>
                <Card style={{ width: "154%" }} className="mt-2">
                  <Col lg={12} className="mt-4" style={{ color: "#fff" }}>
                    <Tabs
                      defaultActiveKey="details"
                      id="uncontrolled-tab-example"
                      onSelect={(key) => handleSelectTab(key)}
                      style={{ marginBottom: "12px" }}
                    >
                      <Tab eventKey="details" title="Details"></Tab>
                      <Tab
                        eventKey="notesMessages"
                        title="Notes & Messages"
                      ></Tab>
                    </Tabs>
                  </Col>
                </Card>

                {showDetails && (
                  <Row className="ibs-edit-form-lead mt-2">
                    {/* <Col lg={12}>
                  <Path
                    values={leadStatusArray}
                    selectedValue={lead.leadstatus}
                  />
                </Col> */}
                    {/* <Row> */}
                    <Col lg={12}>
                      <div className="scrollable-container">
                        <Path
                          values={highlightedStatusArray}
                          selectedValue={lead.leadstatus}
                        />
                      </div>
                    </Col>
                    {/* </Row>                     */}
                    <Col lg={6}>
                      <label>Lead Name</label>
                      <span>
                        {lead.salutation ? lead.salutation : ""}
                        {lead.firstname + " " + lead.lastname}
                      </span>
                    </Col>
                    <Col lg={6}>
                      <label>Contact Name</label>
                      <span>{lead.contactname}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Phone</label>
                      <span>{lead.phone}</span>
                    </Col>
                    {imageData && (
                      <Image
                        style={{
                          height: "70px",
                          width: "80px",
                        }}
                        src={imageData}
                        fluid
                        alt="No Image Found"
                      />
                    )}
                    <Col lg={6}>
                      <label>Email</label>
                      <span>{lead.email}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Company</label>
                      <span>{lead.company}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Title</label>
                      <span>{lead.title}</span>
                    </Col>
                    {/* <Col lg={6}>
                      <label>Fax</label>
                      <span>{lead.fax}</span>
                    </Col> */}
                    <Col lg={6}>
                      <label>Lead Source</label>
                      <span>{lead.leadsource}</span>
                    </Col>

                    <Col lg={6}>
                      <label>User Name</label>
                      <span>{userName}</span>
                    </Col>

                    {/* <Col lg={6}>
                      <label>Industry</label>
                      <span>{lead.industry}</span>
                    </Col> */}

                    {/* <Col lg={6}>
                      <label>Expected Amount</label>
                      <span>₹ {lead.amount}</span>
                    </Col> */}
                    {/* <Col lg={6}>
                  <label>Payment Model</label>
                  <span>{lead.paymentmodel}</span>
                </Col>
                <Col lg={6}>
                  <label>Payment Terms</label>
                  <span>{lead.paymentterms}</span>
                </Col> */}
                    <Col lg={6}>
                      <label>Status</label>
                      <span>{lead.leadstatus}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Assigned User</label>
                      <span>
                        {/* Image Url for Production  */}
                        {/* <img src={`https://spark.indicrm.io/images/${userInfo.tenantcode}/users/${lead.ownerid}.thumbnail/${Math.random()}`} className="rounded-circle " style={{height: "20px", width: "20px", objectFit: "cover"}}/>   */}
                        {/* Image Url for Local  */}
                        <img
                          src="https://www.w3schools.com/bootstrap4/img_avatar3.png"
                          className="rounded-circle"
                          style={{
                            height: "30px",
                            width: "30px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                        <img
                          src={localStorage.getItem("myimage")}
                          className="rounded-circle"
                          style={{ height: "30px" }}
                          alt=""
                        />

                        <Badge
                          bg="warning"
                          className="mx-2"
                          style={{ display: "inline", color: "#000" }}
                        >
                          {lead.ownername}
                        </Badge>
                      </span>
                    </Col>
                    <Col lg={12}>
                      <label>Description</label>
                      <span>{lead.description}</span>
                    </Col>
                    <Col lg={12}>
                      <label>Created Date</label>
                      <span>
                        {moment(lead.createddate).format("DD MMM, yyyy")}
                      </span>
                    </Col>
                    <Col lg={12} className="section-header">
                      POINT OF CONTACT INFORMATION
                    </Col>
                    <Col lg={6}>
                      <label>Point of Contact Name</label>
                      <span>{lead.poc_contact_name}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Point of Contact Phone</label>
                      <span>{lead.poc_contact_phone}</span>
                    </Col>

                    <Col lg={12} className="section-header">
                      ADDRESS INFORMATION
                    </Col>
                    <Col lg={6}>
                      <label>Location Link</label>
                      <span>{lead.location_link}</span>
                      <a
                        href={
                          lead.location_link
                            ? lead.location_link
                            : disableCursor
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Map
                      </a>
                    </Col>
                    <Col lg={6}>
                      <label>Complete Address</label>
                      <span>{lead.street}</span>
                    </Col>
                    <Col lg={6}>
                      <label>City</label>
                      <span>
                        {lead.city !== null ? lead.city : ""} {lead.zipcode}
                      </span>
                    </Col>
                    <Col lg={6}>
                      <label>State</label>
                      <span>{lead.state}</span>
                    </Col>
                    <Col lg={6}>
                      <label>Country</label>
                      <span>{lead.country}</span>
                    </Col>
                    <Col lg={12}>
                      <div
                        id="googleMapaddress"
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "300px",
                          border: "1px solid black",
                        }}
                      ></div>
                    </Col>
                    <Col lg={12} className="section-header">
                      PROJECT TOTAL AMOUNT INFORMATION
                    </Col>
                    <Col lg={6}>
                      <label>Total Amount</label>
                      <Badge
                        bg="secondary"
                        style={{
                          width: "30%",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {"₹ " +
                          formatAmount(
                            proejectTotal?.totalprojectamount || 0
                          )}{" "}
                      </Badge>
                    </Col>

                    <Col lg={6}>
                      <label>Received Amount</label>
                      <Badge
                        bg="success"
                        style={{
                          width: "30%",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {/* <CurrencyFormatter
                          value={payments.paidamount || 0}
                          currency="INR"
                          thousandSeparator={false}
                        />{" "} */}
                        {"₹ " + formatAmount(payments?.paidamount || 0)}{" "}
                      </Badge>
                    </Col>
                    <Col lg={6}>
                      <label>Pending Amount</label>

                      <Badge
                        bg="danger"
                        style={{
                          width: "30%",
                          fontWeight: "normal",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {/* <CurrencyFormatter
                          // value={Math.max(
                          //   0,
                          //   proejectTotal.totalprojectamount -
                          //     (payments.paidamount || 0)
                          // )}
                          value={
                            Math.max(
                              0,
                              proejectTotal.totalprojectamount -
                                (payments.paidamount || 0)
                            ) || 0
                          }
                          
                          
                          // value={0}
                          currency="INR"
                          thousandSeparator={false}
                        />{" "} */}

                        {"₹ " +
                          formatAmount(
                            Math.max(
                              0,
                              proejectTotal.totalprojectamount -
                                (payments.paidamount || 0)
                            )
                          )}
                      </Badge>
                    </Col>

                    <Col className="mt-5"></Col>
                  </Row>
                )}
                {showNotesMessages && (
                  <Card style={{ width: "154%" }} className="mt-2">
                    <Row className="mt-4">
                      <Col lg={12}>
                        <Chat parentid={lead.id} />
                      </Col>
                    </Row>
                  </Card>
                )}
              </Col>
            </Row>

            <Card bg="light" text="light" className="mb-2 mt-4">
              <Card.Header className="d-flex justify-content-between">
                <Tabs
                  defaultActiveKey="files"
                  id="uncontrolled-tab-example"
                  onSelect={(key) => handleSelect(key)}
                >
                  {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                  <Tab eventKey="files" title="Files"></Tab>
                  <Tab eventKey="followup" title="Follow Up"></Tab>
                  <Tab eventKey="project" title="Projects"></Tab>
                  <Tab eventKey="location" title="Location History"></Tab>
                </Tabs>

                {relatedListFiles && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowFile(true)}
                  >
                    New File
                  </Button>
                )}

                  {relatedListTaskList && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowTask(true)}
                  >
                    New Follow Up
                  </Button>
                  )}

                {modalShowFile && (
                  <FilesCreate
                    show={modalShowFile}
                    onHide={() => setModalShowFile(false)}
                    parent={lead}
                    table="medicaltest"
                    submitFiles={submitFiles}
                  />
                )}

                {reletedListTask && (
                  <div>
                    <Button
                      className="float-right btn-sm mx-2"
                      onClick={() => createProjects()}
                    >
                      New Project
                    </Button>

                    {/* <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                  >
                    <i class="fa-regular fa-envelope mx-2"></i>
                    Send Email
                  </Button> */}
                  </div>
                )}
                {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={lead?.id}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
              </Card.Header>
              <Card.Body>
                {relatedListFiles && (
                  <RelatedListFiles
                    refreshFileList={refreshFileList}
                    // onFileUpdate={updateCounter}
                    parent={lead}
                    submitFiles={submitFiles} //Added by shivani
                  />
                )}
                {reletedListTask ? (
                  <RelatedListProjects
                    refreshTaskList={refreshTaskList}
                    parent={lead}
                  />
                ) : (
                  ""
                )}

                {relatedListLocation && (
                  <RelatedLocationHistory
                    refreshTaskList={refreshLocationList}
                    parent={lead}
                  />
                  // ) : (
                  //   ""
                  // )}
                )}
                {relatedListTaskList && (
                  <RelatedListTask
                    refreshFileList={refreshFileList}
                    // onFileUpdate={updateCounter}
                    parent={lead}
                    submitFiles={submitFiles}
                    table='Lead'
                  />
                )}
              </Card.Body>
            </Card>

            {/* <EmailComposer
              size="lg"
              show={modalShowEmail}
              onHide={() => setModalShowEmail(false)}
              parentid={lead?.id}
              toEmail={lead?.email}
              table="lead"
              submitTasks={submitTasks}
            /> */}
          </>
        )}
      </Container>
    </div>
  );
};
export default LeadView;
