import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "../Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import FilesCreate from "../File/FilesCreate";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import RelatedListFiles from "../File/RelatedListFiles";
// import RealetedLeadTest from "./RealetedLeadTest";
import RelatedListTask from "../Task/RelatedListTask";
import TaskEdit from "../Task/TaskEdit";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import RelatedListBusiness from "./RelatedListBusiness";
// import helper from "./common/helper";
import Main from "../layout/Main";
import * as constants from "../../constants/CONSTANT";
import { Shimmer } from "react-shimmer";
import Image from "react-bootstrap/Image";
import moment from "moment";
import { disableCursor } from "@fullcalendar/core/internal";
import Permissionhelper from "../common/Permissionhelper";

const ContactView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const contact = location.state;
  const [contact, setContact] = useState(location.state ? location.state : {});
  const [relatedListPatientTests, setRelatedListPatientTests] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);
  const [modalShowMedicaltestitem, setModalShowMedicaltestitem] =
    useState(false);
  const [refreshBusinessList, setRefreshBusinessList] = useState();
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [reletedListBusiness, setRelatedListBusiness] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [currentRec, setCurrentRecord] = useState({});
  const [locationRecordv1, setLocationRecordv1] = useState({});
  const [latitudev1, setLatitudev1] = useState(null);
  const [longitudev1, setLongitudev1] = useState(null);
  const [addressv1, setAddressv1] = useState(
    `${contact.street} ${contact.state} ${contact.zipcode}`
  ); // Default address
  const [buttonDisabledv1, setButtonDisabledv1] = useState(false);
  const [remarksv1, setRemarksv1] = useState("");

  const [alert, setAlert] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [imageData, setImageData] = useState();
  const [mapLocation, setMapLocation] = useState(false);

  useEffect(() => {
    fetchContact();
    setMapLocation(true);
  }, []);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const googleMapScript = document.createElement("script");
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDtztwOW6bufAlbBWfO1k5FdZTkpUP_gmI&callback=initMap`;
        googleMapScript.async = true;
        googleMapScript.defer = true;
        window.document.body.appendChild(googleMapScript);
      } else {
        window.initMap();
      }
    };

    window.initMap = () => {
      myMapFunction();
    };

    loadGoogleMapsScript();
  }, [latitudev1, longitudev1, addressv1]);

  const myMapFunction = async () => {
    if (!latitudev1 || !longitudev1) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: addressv1 }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          setLatitudev1(location.lat());
          setLongitudev1(location.lng());

          const mapProp = {
            center: location,
            zoom: 14,
          };

          const map = new window.google.maps.Map(
            document.getElementById("googleMapaddress"),
            mapProp
          );

          const marker = new window.google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          setAddressv1(results[0].formatted_address);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    } else {
      const location = new window.google.maps.LatLng(latitudev1, longitudev1);
      const mapProp = {
        center: location,
        zoom: 14,
      };

      const map = new window.google.maps.Map(
        document.getElementById("googleMapaddress"),
        mapProp
      );

      const marker = new window.google.maps.Marker({
        map,
        position: location,
        title: addressv1,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: addressv1,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    }
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
      setRelatedListBusiness(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
      setRelatedListBusiness(false);
    } else if (key === "business") {
      setRelatedListBusiness(true);
      setReletedListTask(false);
      setRelatedListFiles(false);
    }
  };

  //Added by shivani
  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
    fetchContact();
  };
  // End

  const fetchContact = () => {
    // Coming from Email
    if (!contact.id && location.hasOwnProperty("pathname")) {
      contact.id = location.pathname.split("/")[2];
      //console.log("pathnam===" + contact.id);
    }
    async function initContact() {
      let result = await WhiteObjectApi.fetchContactById(contact.id);
      let productImageResult = await WhiteObjectApi.fetchPrimaryImages(
        contact.id
      );
      console.log("Product image result:", productImageResult);

      let productImageData = await WhiteObjectApi.downloadFiles(
        productImageResult.id,
        "ContactImages"
      );
      console.log("Product image data:", productImageData);

      // Convert Blob to base64 string
      let reader = new FileReader();
      reader.readAsDataURL(productImageData);
      reader.onloadend = () => {
        let base64data = reader.result;
        console.log("Base64 Product Image:", base64data);
        setImageData(base64data);
      };
      if (result) {
        setContact(result);
      } else {
        setContact({});
      }
    }
    initContact();
  };

  const deleteContact = async () => {
    const result = await WhiteObjectApi.deleteContacts(contact.id);
    if (result.success) navigate(`/contacts`);
  };

  const editContact = () => {
    navigate(`/contacts/${contact.id}/e`, { state: contact });
  };

  return (
    <Main>
      <>
        <Helmet>{LIST_TAB_TITLE.ContactView}</Helmet>
      </>

      <div>
        {contact && (
          <Container>
            {modalShow && (
              <Confirm
                show={modalShow}
                onHide={() => setModalShow(false)}
                deleteContact={deleteContact}
                title="Confirm delete?"
                message="You are going to delete the record. Are you sure?"
                table="contact"
              />
            )}
            <Row className="view-form">
              <Col lg={11} className="pb-3 pt-2">
                <Link className="nav-link" to="/contacts">
                  Home <i className="fa-solid fa-chevron-right"></i>{" "}
                  <div style={{ color: "#23468c", display: "inline" }}>
                    Contacts
                  </div>
                </Link>
              </Col>
              <Col></Col>
            </Row>
            <Row className="view-form gx-5 mx-auto">
              <Col lg={8}>
                <Row className="view-form-header align-items-center">
                  <Col lg={8}>
                    Contact
                    <h5>
                      {contact.salutation} {contact.firstname}{" "}
                      {contact.lastname}
                    </h5>
                  </Col>
                  <Col lg={4} className="d-flex justify-content-end">
                    <Button
                      className="btn-sm mx-2"
                      onClick={() => editContact(true)}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </Button>

                    {Permissionhelper.checkPermission(
                      constants.SUPER_ADMIN
                    ) && (
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => setModalShow(true)}
                      >
                        Delete
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className="py-3 ibs-edit-form">
                  <Col lg={6}>
                    <label>Name</label>
                    <span>
                      {contact.salutation} {contact.firstname}{" "}
                      {contact.lastname}
                    </span>
                  </Col>
                  <Col lg={6}>
                    <label>Email</label>
                    <span>{contact.email}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Phone</label>
                    <span>{contact.phone}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Type</label>
                    <span>{contact.title}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Date of Birth</label>
                    <span>
                      {contact.date_of_birth
                        ? moment(contact.date_of_birth).format("DD MMM, yyyy")
                        : ""}
                    </span>
                  </Col>
                  {contact.title === "Customer Enquiry" ? (
                    <>
                      <Col lg={6}>
                        <label>No. Of Windows</label>
                        <span>{contact.qty_windows}</span>
                      </Col>
                      <Col lg={6}>
                        <label>Type of Order</label>
                        <span>{contact.type_of_order}</span>
                      </Col>
                      <Col lg={6}>
                        <label>Time Slot</label>
                        <span>{contact.time_slot}</span>
                      </Col>
                      <Col lg={6}>
                        <label>Specific Time</label>
                        <span>{contact.specific_time}</span>
                      </Col>
                      <Col lg={6}>
                        <label>Site Visit Date</label>
                        <span>
                          {moment(contact.site_visit_date).format(
                            "DD MMM, yyyy"
                          )}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  {/* 
                
                <Col lg={6}>
                  <label>State</label>
                  <span>{contact.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{contact.pincode}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{contact.country}</span>
                </Col>
                <Col lg={6}>
                  <label>Street</label>
                  <span>{contact.street}</span>
                </Col> */}
                  <Col lg={12} className="section-header">
                    ADDRESS INFORMATION
                  </Col>
                  <Col lg={6}>
                    <label>Location Link</label>
                    <span>{contact.location_link}</span>
                    <a
                      href={
                        contact.location_link
                          ? contact.location_link
                          : disableCursor
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Map
                    </a>
                  </Col>
                  <Col lg={6}>
                    <label>Complete Address</label>
                    <span>{contact.street}</span>
                  </Col>
                  <Col lg={6}>
                    <label>City</label>
                    <span>
                      {contact.city !== null ? contact.city : ""}{" "}
                      {contact.pincode}
                    </span>
                  </Col>
                  <Col lg={6}>
                    <label>State</label>
                    <span>{contact.state}</span>
                  </Col>
                  <Col lg={6}>
                    <label>Country</label>
                    <span>{contact.country}</span>
                  </Col>
                  <Col lg={12}>
                    {mapLocation && (
                      <div
                        id="googleMapaddress"
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "300px",
                          border: "1px solid black",
                        }}
                      ></div>
                    )}
                  </Col>
                </Row>
                <Row></Row>
              </Col>
              <Col lg={4}>
                <div
                  className="card mt-2 align-items-center"
                  style={{ width: "18rem" }}
                >
                  <div className="card-body">
                    <h5
                      className="card-title"
                      style={{ textAlign: "-webkit-center", fontWeight: "900" }}
                    >
                      {" "}
                      Contact User Image
                    </h5>
                    <hr></hr>
                    <div className="align-items-center">
                      {imageData && (
                        <Image
                          style={{
                            height: "150px",
                            width: "150px",
                            border: "12px",
                            marginLeft: "4px",
                          }}
                          src={imageData}
                          fluid
                          alt="No Image Found"
                        />
                      )}
                    </div>

                    <p
                      className="card-text"
                      style={{ textAlign: "center", marginTop: "24px" }}
                    >
                      {contact.salutation} {contact.firstname}{" "}
                      {contact.lastname}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Card bg="light" text="light" className="mb-2 mt-4">
              <Card.Header className="d-flex justify-content-between">
                <Tabs
                  defaultActiveKey="files"
                  id="uncontrolled-tab-example"
                  onSelect={(key) => handleSelect(key)}
                >
                  {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                  <Tab eventKey="files" title="Files"></Tab>
                  <Tab eventKey="tasks" title="Tasks"></Tab>
                </Tabs>

                {relatedListFiles && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowFile(true)}
                  >
                    New File
                  </Button>
                )}
                {modalShowFile && (
                  <FilesCreate
                    show={modalShowFile}
                    onHide={() => setModalShowFile(false)}
                    parent={contact}
                    table="medicaltest"
                    submitFiles={submitFiles}
                  />
                )}

                {reletedListTask && (
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowTask(true)}
                  >
                    New Task
                  </Button>
                )}
                {modalShowTask && (
                  <TaskEdit
                    show={modalShowTask}
                    onHide={() => setModalShowTask(false)}
                    parentid={contact.id}
                    table="account"
                    submitTasks={submitTasks}
                  />
                )}
              </Card.Header>
              <Card.Body>
                {/* {relatedListPatientTests && <RelatedListPatientTests parent={contact}/>} */}

                {relatedListFiles && (
                  <RelatedListFiles
                    refreshFileList={refreshFileList}
                    parent={contact}
                    submitFiles={submitFiles} //Added by shivani
                    table="Contact"
                    
                  />
                )}
                {reletedListTask ? (
                  <RelatedListTask
                    refreshTaskList={refreshTaskList}
                    parent={contact}
                  />
                ) : (
                  ""
                )}
                {/* {reletedListBusiness ? <RelatedListBusiness refreshTaskList={refreshBusinessList} parent={contact} /> : ""} */}
              </Card.Body>
            </Card>
          </Container>
        )}
      </div>
    </Main>
  );
};
export default ContactView;
