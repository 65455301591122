import React, { useEffect, useState } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import WhiteObjectApi from '../../api/WhiteObjectApi';
import { ChartColors } from '../../constants/CONSTANT';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GroupBarChart = () => {
  const [ownerNames, setOwnerNames] = useState([]);
  const [dataSetsArr, setDataSetsArr] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let groupedData = [];
      const leadCountByAgent = await WhiteObjectApi.fetchLeadReports('lead_count_by_agent');
      console.log('leadCountByAgent', leadCountByAgent);
      if(leadCountByAgent.length > 0){
        
      leadCountByAgent.map(obj => {
        let arr = groupedData[obj.lead_manager];
        if (!arr)
          arr = [];
        arr.push({ status: obj.leadstatus, count: obj.count });

        groupedData[obj.lead_manager] = arr;
      });
      }
      

      console.log('groupedData', groupedData);

      let finalSeries = [];
      Object.keys(groupedData).map(key => {
        let data = [...groupedData[key]];
        let series = [];
        data.forEach(function (obj) {
          let arr = series[obj.status];
          if (!arr)
            arr = [];
          arr.push(obj.count);
          series[obj.status] = arr;
        })

        finalSeries[key] = series;
      })  
      let uniqueStatus = [];
      Object.keys(finalSeries).map(key => {
        Object.keys(finalSeries[key]).map(stkey => {
          uniqueStatus.push(stkey);
        });
      });
      uniqueStatus = uniqueStatus.filter((item, index) => uniqueStatus.indexOf(item) === index);
      console.log('uniqueStatus:', uniqueStatus);

      let keys = [];
      Object.keys(finalSeries).map(key => {

        uniqueStatus.forEach((stkey) => {
          let arr = keys[stkey];
          if (!arr)
            arr = [];
          let data = finalSeries[key][stkey];
          if (!data)
            data = 0;
          arr.push(data)
          keys[stkey] = arr;
        });
      });
      console.log('finalSeries::2', keys);

      setOwnerNames(Object.keys(groupedData));

      let colors = ChartColors.slice(0, uniqueStatus.length).map((color) => ({
        borderColor: color,
        backgroundColor: color,
      }));

      let datasets = [];

      Object.keys(keys).forEach(function (key, index) {
        let dataset = {
          label: key,
          data: [...keys[key]],
          ...colors[index],
        };
        datasets.push(dataset);
      })

      console.log('datasets:', datasets);
      setDataSetsArr(datasets);
    }

    fetchData();
  }, []);

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2.5,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Horizontal Bar Chart',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Lead', // Set the title text for the x-axis
          color: 'black', // Set the color of the title (optional)
          font: {
            size: 16, // Set the font size of the title (optional)
            weight: 'bold', // Set the font weight of the title (optional)
          },
        },
      },
    
      y: {
        title: {
          display: true,
          text: 'Agent Name', // Set the title text for the x-axis
          color: 'black', // Set the color of the title (optional)
          font: {
            size: 16, // Set the font size of the title (optional)
            weight: 'bold', // Set the font weight of the title (optional)
          },
        },
      },
    },
  
  };

  const labels = ownerNames;

  const data = {
    labels,
    datasets: dataSetsArr,
  };

      

  return (
    <div className="chart-container">
    <h2  className="chart-title">Agent Wise</h2>
  <div className="chart-wrapper">
    <Bar options={options} data={data} width={150} height={120} />
    </div>
    </div>
  );
}

export default GroupBarChart;
