import axios from "axios";
import jwt_decode from "jwt-decode";

export const getToken = () => {
    let token = localStorage.getItem("token");
    return token;
  };

  export const getAxios = () => {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: ` ${token}`,
    };
    return axios.create({ headers });
  };

  export const getAxiosFile = () => {
    const token = getToken();
    const headers = {
      Authorization: ` ${token}`,
    };
    return axios.create({ headers });
  };

  export const getAxiosMultipart = () => {
    const token = getToken();
    const headers = {Authorization: `${token}`};
    return axios.create({ headers });
  };

 


  export const getUserDetails =()=>{
    return jwt_decode(localStorage.getItem('token'));

  }