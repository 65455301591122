/* Start updated by Pooja Vaishnav and it will create new pricebook and update it also for the related product*/
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PubSub from 'pubsub-js';
import "react-bootstrap-typeahead/css/Typeahead.css";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from "moment";

const PricebookEdit = (props) => {
  const navigate = useNavigate();
  const [pricebook, setPricebook] = useState({});
  const [active, setActive] = useState(false);
  const [validated, setValidated] = useState(false);

  
  const checkRequredFields = () => {
    if (
      pricebook.amount &&
      pricebook.amount.trim() !== "" 
    ) {
      return false;
    }
    return true;
  };
  const handleChange = (e) => {
    setPricebook({ ...pricebook, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }
    pricebook.active = active;
    pricebook.productid = props.parentId;   
    console.log("PriceBook on Create",pricebook)
    
      const result = await WhiteObjectApi.createPricebook(pricebook);
      if (result.errors) {
        PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: result.errors });
      }else{
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record created successfully' });
      }
    //   submitPricebooks();
    
  };

  const submitPricebooks = () => {
    props.submitPricebooks();
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="view-form-header">
        <Modal.Title id="contained-modal-title-vcenter">
          Pricebook
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="view-form ">
          <Row>
            <Col lg={12}>
              <Form
                className="mt-3"
                onSubmit={handleSubmit}
                controlId="taskCreate"
              >
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicLastName">
                      <Form.Label className="form-view-label">
                        Amount
                      </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="amount"
                        placeholder="Enter amount"
                        value={pricebook.amount}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        GST
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="gst"
                        placeholder="Enter Gst"
                        value={pricebook.gst}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col lg={4}>
                    <Form.Group className="mx-3">
                      <Form.Label className="form-view-label">
                        Active
                      </Form.Label>

                      <Form.Check
                        defaultChecked={pricebook.active}
                        value={active}
                        onClick={(e) => {
                          e.target.checked ? setActive(true) : setActive(false);
                        }}
                      ></Form.Check>
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row>
                 
                </Row> */}
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleSubmit}>
          Save
        </Button>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PricebookEdit;
/* End updated by Pooja Vaishnav */