import React, { useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { Col, Button } from "react-bootstrap";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LineChart = ({filterDateData}) => {
  console.log("LineChart",filterDateData);
  const [optionLead, setOptionLead] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [projectsData, setProjectsData] = useState([]);

  useEffect(() => {
    fetchInitialData(filterDateData);
  }, [filterDateData]);
  async function fetchInitialData() {
    try {
      // Fetch leads data and set options
      const fetchLeads = await WhiteObjectApi.fetchLead();
      if (fetchLeads && Array.isArray(fetchLeads)) {
        const leadOptions = fetchLeads.map((item) => ({
          value: item.id,
          label: item.firstname + " " + item.lastname,
        }));
        setOptionLead(leadOptions);
      }
      if(filterDateData && filterDateData.month && filterDateData.year){
        console.log("Project Line Chart Date",filterDateData)
        const fetchProjects = await WhiteObjectApi.fetchProjectCurrentMonth(filterDateData);
      if (fetchProjects && Array.isArray(fetchProjects)) {
        setProjectsData(fetchProjects);
      } else {
        setProjectsData([]);
      }
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setProjectsData([]);
    }
  }
  console.log("FETCH_ON_LOAD_PROJECTS",projectsData);
  useEffect(() => {
    async function fetchProjectsByLeadId(leadId) {
      try {
        if (leadId) {
          const fetchProject = await WhiteObjectApi.fetchProjectByLeadId(leadId);
          if (fetchProject && Array.isArray(fetchProject)) {
            setProjectsData(fetchProject);
          } else {
            setProjectsData([]);
          }
        } else {
          // Handle default state (when no lead is selected)
          const fetchProjects = await WhiteObjectApi.fetchProjectCurrentMonth();
          if (fetchProjects && Array.isArray(fetchProjects)) {
            setProjectsData(fetchProjects);
          } else {
            setProjectsData([]);
          }
        }
      } catch (error) {
        console.error('Error fetching projects by lead ID:', error);
        setProjectsData([]);
      }
    }

    fetchProjectsByLeadId(selectedLead?.value);
  }, [selectedLead]);

  //This is Using for Options For chart showing (Added by Farhan Khan on 13-June-2024)
  const options = {
    animationEnabled: true,
    axisY: {
      title: "Project Total Amount",
      scaleBreaks: {
        autoCalculate: true,
        type: "wavy",
        lineColor: "black"
      }
    },
    data: projectsData ? [{
      type: "column",
      indexLabel: "{y}",
      indexLabelFontColor: "black",
      toolTipContent: `<strong>{label}</strong><br/>
                       Total Amount: {project_total_amount}<br/>
                       Pending Amount: {project_pending_amount}<br/>
                       Received Amount: {project_amount_received}`,
      dataPoints: projectsData.map(project => ({
        label: project.project_name,
        y: parseFloat(project.project_total_amount) || 0,
        project_total_amount: project.project_total_amount || 0,
        project_pending_amount: project.pendingamount || 0,
        project_amount_received: project.paidamount || 0
      }))
    }] : [],
  };

  const handleLead = (selectedOption) => {
    setSelectedLead(selectedOption);
  };

  const handleClear = () => {
    setSelectedLead(null);
    setProjectsData([]);
  };

  return (
    <div>
      <Col lg={4} className='mt-4'>
        <Form.Group>
          <div className="d-flex flex-column">
            <div className="d-flex">
              <Select
                required
                placeholder="Select Lead Name..."
                onChange={handleLead}
                options={optionLead}
                value={selectedLead}
              />
              <Button variant="secondary" onClick={handleClear} className='ms-2'>
                Clear
              </Button>
            </div>
            {/* {selectedLead && projectsData.length === 0 && (
              <div className='mt-2 text-center text-danger'>
                No Record Found
              </div>
            )} */}
          </div>
        </Form.Group>
      </Col>
      <div className='mt-4'>
        {/* {projectsData.length > 0 ? ( */}
          <CanvasJSChart options={options} />
        {/* ) : ( */}
          {/* selectedLead && ( */}
            {/* <div className='text-center text-danger'>
              No Record Found
            </div> */}
          {/* ) */}
        {/* )} */}
      </div>
    </div>
  );
}

export default LineChart;