import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import PubSub from "pubsub-js";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Main from "../../components/layout/Main";
import Helmet from "react-helmet";
// import { faRecordVinyl } from "@fortawesome/free-solid-svg-icons";

const UserProfileEdit = ({ userId }) => {
  const fileInputRef = useRef();
  const [profile, setProfile] = useState({});
  const [body, setBody] = useState();
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({ password: "", confirmpassword: "" });
  //eslint-disable-next-line
  const [validated, setValidated] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState(null);
  
  useEffect(() => {
    async function init() {
      let result = await WhiteObjectApi.getLoginUserData();
      setProfile(result);
      // let files = await WhiteObjectApi.fetchFiles(result.id);
      // setFile(files.id)
      // //setSelectedFiles(files);
      // if (files && files.length > 0) {
      //   let file = files[0];
      //   let downloadresult = await WhiteObjectApi.downloadFiles(file.id);
      //   file.body = window.URL.createObjectURL(downloadresult);
      //   setBody(file.body);
      // }
    }

    init();
  }, []);

  const handlePasswordOnchange = (e) => {    
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let result = {};
    if (selectedFiles === null) {
      result = await WhiteObjectApi.saveUser(profile);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
      } else {
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Record Save Error",
          message: result.errors,
        });
      }
    } else {
      //eslint-disable-next-line
      let result = await WhiteObjectApi.saveStaffMemberEditProfile(
        profile.id,
        selectedFiles,
        JSON.stringify(JSON.stringify(profile))
      );
      localStorage.setItem("myimage", body);
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Record Saved",
        message: " Record update successfully",
      });
    }
  };

  const handlePhotoUpload = (event) => {
    setBody(URL.createObjectURL(event.target.files[0]));
    setSelectedFiles(event.target.files[0]);
  };

  const handleClose = () => setShow(false);

  const handleChangeSubmit = async (e) => {
    
    setShow(true);
    e.preventDefault();
    if (user.password === user.confirmpassword && user.password !== "") {
      //eslint-disable-next-line
      const result = await WhiteObjectApi.updateUser(user);
      setShow(false);
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Record Saved",
        message: "Password updated successfully",
      });
    }
  };


  return (
    <Main>
      <>
        <Helmet>
          {/* <title>User Profile</title> */}
        </Helmet>
      </>
    <Container>
      <Row>
        <Col lg={1}></Col>
        <Col lg={3}>
          <Card>
            <Card.Body className="text-center">
              <Card.Title style={{ textAlign: "center" }}>
                {profile.firstname} {profile.lastname}
              </Card.Title>
              <Image
                variant="top"
                src={body}
                className="rounded-circle"
                thumbnail
                style={{ width: "200px" }}
              ></Image>
              <br />
              <Button
                className="btn my-3 "
                variant="secondary"
                style={{ width: "100%", display: "block" }}
                onClick={() => fileInputRef.current.click()}
              >
                Image Upload
              </Button>
              <Button
                className="btn"
                style={{ width: "100%", display: "block" }}
                variant="danger"
                onClick={handleChangeSubmit}
              >
                Change Password
              </Button>
              <input
                onChange={handlePhotoUpload}
                name="profilephotourl"
                ref={fileInputRef}
                type="file"
                hidden
              />
            </Card.Body>
          </Card>
        </Col>

        <Col style={{ backgroundColor: "#fff" }} lg={7}>
          <Row className="view-form-header align-items-center">
            <Col lg={6}>Edit Profile</Col>
            <Col lg={6} className="d-flex justify-content-end">
              <Button className="btn-sm mx-2" onClick={handleSubmit}>
                Save
              </Button>
            </Col>
          </Row>

          <Row className="pb-5 py-3">
            <Col lg={6}>
              <Form.Group className="mx-3 mb-3" controlId="formBasicPhone">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicPhone"
                >
                  First Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={profile.firstname}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3 mb-3" controlId="formBasicLastName">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicPhone"
                >
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="lastname" //Added by shivani
                  placeholder="Enter Last Name"
                  value={profile.lastname}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mx-3 mb-3" controlId="formBasicLastEmail">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicPhone"
                >
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  value={profile.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mx-3 mb-3" controlId="formBasicLastName">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicPhone"
                >
                  Phone
                </Form.Label>
                <Form.Control
                  type="phone"
                  name="phone"
                  placeholder="Enter Phone"
                  value={profile.phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col lg={1}></Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col></Col>
            <Col lg={12}>
              <Form className="mt-3">
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicFirstName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        New Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter Your password"
                        onChange={handlePasswordOnchange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mx-3" controlId="formBasicPhone">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicPhone"
                      >
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmpassword"
                        placeholder="Enter confirm password"
                        onChange={handlePasswordOnchange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChangeSubmit}>
            Save
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </Main>
  );
};

export default UserProfileEdit;