import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import { useLocation } from "react-router-dom";
import Main from '../layout/Main';

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
// import authApi from "../api/authApi";

const UserList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [leadname, setLeadName] = useState();
  // const[contact,setContact] = useState(location.state ? location.state : {});
  const [lead, setLead] = useState();
  ////console.log('location.state ='+location.state)

  useEffect(() => {
    
    async function init() {
      const users = await WhiteObjectApi.fetchUsers();
      console.log('users',users)
      let resultWithoutSuperAdmin = [];
      if (localStorage.getItem('userrole') === 'ADMIN' || localStorage.getItem('userrole') === 'USER') {
        resultWithoutSuperAdmin = users.filter((value, index, array) => value.userrole !== 'SUPER_ADMIN');
        setBody(resultWithoutSuperAdmin);
      } else {
        setBody(users);
      }
      if (users) {
        //console.log("user data =>", users);
        setLead(users);
      } else {
        setBody([]);
        setLead([]);
      }
    }
    init();
  }, []);

  

  const onFilterType = (event) => {
    if (event.target.value === "") {
      setBody(lead);
    } else {
      setBody(
        lead.filter((data) => {
          if (
            (data.leadstatus || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };


  const editUser = (row) => {
    //console.log("row", row);
    navigate(`/users/${row.row.id}/e`, { state: row.row });
  }
  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Name",
      prop: "username",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/users/" + row.id} state={row}>
          {row.username}
        </Link>
      ),
    },
    { 
      title: "Role", 
      prop: "userrole", 
      isFilterable: true, 
      render: (user) => getUserRoleDisplayName(user.userrole) 
    },
    { title: "Phone", prop: "phone", isFilterable: true },
    { title: "Email", prop: "email", isFilterable: true },
    {
      title: "Active", prop: "isactive", isFilterable: true,
      cell: (row) => (

        row.isactive === true ? <i class="fa-regular fa-square-check" style={{ fontSize: "1.3rem", marginLeft: "19px" }}></i> : <i class="fa-regular fa-square" style={{ fontSize: "1.3rem", marginLeft: "19px" }}></i>

      )
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <Button className="btn-sm mx-2 " onClick={() => editUser({ row })}>
          <i className="fa-regular fa-pen-to-square "></i>
        </Button>
      ),
    },

  ];

  const getUserRoleDisplayName = (role) => {
    switch (role) {
      case "SUPER_ADMIN":
        return "SUPER ADMIN";
      case "ADMIN":
        return "Office User";
      case "USER":
        return "Factory User";
      default:
        return role;
    }
  };

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createUser = () => {
    navigate(`/users/e`);
  };

  return (
    <Main>
      <Row className="g-0">
     
     <Col lg={12} className="px-4">
       <DatatableWrapper
         body={body}
         headers={header}
         paginationOptionsProps={{
           initialState: {
             rowsPerPage: 10,
             options: [5, 10, 15, 20],
           },
         }}
       >
         <Row className="mb-4">
           <Col
             xs={12}
             lg={4}
             className="d-flex flex-col justify-content-end align-items-end"
           >
             <Filter />
           </Col>
           <Col
             xs={12}
             sm={6}
             lg={4}
             className="d-flex flex-col justify-content-start align-items-start"
           >
             <PaginationOptions labels={labels} />
           </Col>
           <Col
             xs={12}
             sm={6}
             lg={4}
             className="d-flex flex-col justify-content-end align-items-end"
           >
             <Button
               className="btn-sm"
               variant="outline-primary mx-2"
               onClick={() => createUser(true)}
             >
               New User
             </Button>
             {/* <Button className="btn-sm" variant="outline-primary" onClick={()=>location.state ? navigate(`/contacts/${contact.id}`) : navigate('/contacts/')}>Back to Contact</Button> */}
           </Col>
         </Row>
         {body.length > 0 ? (
           <Table striped className="data-table" responsive = "sm">
             <TableHeader />
             <TableBody />
           </Table>
         ) : (
           <ShimmerTable row={10} col={8} />
         )}
         <Pagination />
       </DatatableWrapper>
     </Col>
     <Col lg={2}></Col>
   </Row>
    </Main>
    
  );
};
export default UserList;
