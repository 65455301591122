import React, { useEffect, useState } from "react";
import { Col, Alert } from "react-bootstrap";
import { Shimmer } from "react-shimmer";
import WhiteObjectApi from "../../api/WhiteObjectApi";

const UserTracking = (props) => {

  const [alert, setAlert] = useState(null);
  const [spinner, setSpinner] = useState(true);

  const [currentRec, setCurrentRecord] = useState({});
  const [locationRecordv1, setLocationRecordv1] = useState({});
  const [latitudev1, setLatitudev1] = useState(null);
  const [longitudev1, setLongitudev1] = useState(null);
  const [addressv1, setAddressv1] = useState(`${props.parent.street} ${props.parent.state} ${props.parent.zipcode}`);
  const [buttonDisabledv1, setButtonDisabledv1] = useState(false);
  const [remarksv1, setRemarksv1] = useState('');

  useEffect(() => {
    // async function init() {
    //   let userdata = await WhiteObjectApi.getCurrentUserTrackingRec();
    //   setCurrentRecord(userdata);
    //   setLocationRecordv1(userdata);
    //   if (userdata.logindatetime != null && userdata.logoutdatetime == null) {
    //     setButtonDisabledv1(true);
    //     setRemarksv1(userdata.remarks);
    //   } else if (userdata.logindatetime.length && userdata.logoutdatetime.length) {
    //     setRemarksv1(userdata.remarks);
    //     setButtonDisabledv1(false);
    //   }
    // }
    // init();

    if (!window.myMap) {
      window.myMap = myMapFunction;
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
    } else {
      myMapFunction();
    }

    setCurrentRecord({ errors: 'No Data' });
  }, []);

  const myMapFunction = async () => {
    if (!latitudev1 || !longitudev1) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: addressv1 }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          setLatitudev1(location.lat());
          setLongitudev1(location.lng());

          const mapProp = {
            center: location,
            zoom: 14,
          };

          const map = new window.google.maps.Map(document.getElementById("googleMapaddress"), mapProp);

          const marker = new window.google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          setAddressv1(results[0].formatted_address);
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    } else {
      const location = new window.google.maps.LatLng(latitudev1, longitudev1);
      const mapProp = {
        center: location,
        zoom: 14,
      };

      const map = new window.google.maps.Map(document.getElementById("googleMapaddress"), mapProp);

      const marker = new window.google.maps.Marker({
        map,
        position: location,
        title: addressv1,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: addressv1,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    }
  };
  

  return (
    <div className="p-4">
      <Col lg={4}>
        {alert && (
          <Alert variant={alert.type} onClose={() => setAlert(null)}>
            {alert.message}
          </Alert>
        )}
        {spinner && <Shimmer height={300} />}
        {!spinner && (
          <div id="googleMapaddress" className="my-4" style={{ width: '200%', height: '300px', border: 'none' }}></div>
        )}
      </Col>
    </div>
  );
};

export default UserTracking;