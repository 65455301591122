import React, { useState, useEffect } from "react";

const Path = ({ values = [], selectedValue = '' }) => {
    const [statusArray, setStatusArray] = useState([]);

    useEffect(() => {
        setStatusArray(values);
    }, [values]);

    const getClassName = (currentVal, selectedVal) => {
        const currentIndex = statusArray.findIndex(val => val.label === currentVal.label);
        const selectedIndex = statusArray.findIndex(val => val.label === selectedVal);

        if (currentIndex <= selectedIndex) {
            return "bar-step completed";
        } else {
            return "bar-step";
        }
    };

    return (
        <div className="bar">
            {statusArray.map((val, index) => (
                <div key={index} className={getClassName(val, selectedValue)}>
                    {val.label}
                </div>
            ))}
        </div>
    );
}

export default Path;
