import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import { isMobile, BrowserView } from "react-device-detect";
import jwt_decode from "jwt-decode";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "../InfoPill";
import Main from "../layout/Main";
import { Helmet } from "react-helmet";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from "../../constants/CONSTANT";
import {
  Project_Stage_Status,
  LEAD_STATUS_VALUES,
} from "../../constants/CONSTANT";
import PubSub from "pubsub-js";
import Permissionhelper from "../common/Permissionhelper";

const LeadList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [deleteModalShow, setDeleteModal] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [isProject, setIsProject] = useState(false);
  const [task, setTask] = useState({});
  const [validated, setValidated] = useState(false);
  // useEffect(() => {
  //   onFilterType();
  // }, []);

  const onFilterType = async (event) => {
    const selectedStatus = event.target.value;
    if (selectedStatus === "Lead") {
      setIsLead(true);
      let leadResult = await WhiteObjectApi.fetchLeadsArchives();
      if (leadResult) {
        setIsProject(false);
        setBody(leadResult);
        setFilteredProjects(leadResult);
      } else {
        setBody([]);
        setFilteredProjects([]);
      }
    } else if (selectedStatus === "Project") {
      setIsLead(false);
      setIsProject(true);
      const projects = await WhiteObjectApi.fetchProjetcsArchives();
      if (projects) {
        setBody(projects);
        setFilteredProjects(projects);
      } else {
        setBody([]);
        setFilteredProjects([]);
      }
    } else if (selectedStatus === "Archive Deleted") {
      setIsLead(false);
      setIsProject(false);
      const archiveDeleted = await WhiteObjectApi.fetchDeletedHistoryArchive();
      if (archiveDeleted) {
        setBody(archiveDeleted);
        setFilteredProjects(archiveDeleted);
      } else {
        setBody([]);
        setFilteredProjects([]);
      }
    }
  };

  const getStatusClass = (status) => {
    const statusObj = Project_Stage_Status.find(
      (value) => value.label === status
    );
    return statusObj ? statusObj.backgroundColor : "#FFFFFF";
  };

  const getStatusClassLead = (status) => {
    const statusObj = LEAD_STATUS_VALUES.find(
      (value) => value.label === status
    );
    return statusObj ? statusObj.backgroundColor : "#FFFFFF";
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRecords([]);
    } else {
      setSelectedRecords(filteredProjects);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectRecord = (record) => {
    console.log("RecordRow", record);
    if (selectedRecords.includes(record)) {
      setSelectedRecords(selectedRecords.filter((r) => r !== record));
    } else {
      setSelectedRecords([...selectedRecords, record]);
    }
  };
  const archiveLeads = async () => {
    const leadRecords = selectedRecords.filter((record) => record.lead_id);
    const projectRecords = selectedRecords.filter(
      (record) => record.project_id
    );

    const leadRestorePromises = leadRecords.map(async (record) => {
      const updatedRecord = { id: record.lead_id, isarchive: false };
      const deleteRecord = { id: record.id };
      try {
        const saveResult = await WhiteObjectApi.saveLead(updatedRecord);
        if (saveResult && saveResult.success) {
          console.log("DeletedRecord", deleteRecord);
          await WhiteObjectApi.deleteArchive(deleteRecord.id);
        }
        return saveResult;
      } catch (error) {
        console.error("Error deleting lead record:", error);
        return { success: false };
      }
    });

    const projectRestorePromises = projectRecords.map(async (record) => {
      const updatedRecord = { id: record.project_id, isarchive: false };
      const deleteRecord = { id: record.id };
      try {
        const saveResult = await WhiteObjectApi.saveProject(updatedRecord);
        if (saveResult && saveResult.success) {
          await WhiteObjectApi.deleteArchive(deleteRecord.id);
        }
        return saveResult;
      } catch (error) {
        console.error("Error deleting project record:", error);
        return { success: false };
      }
    });

    try {
      const leadResults = await Promise.all(leadRestorePromises);
      const projectResults = await Promise.all(projectRestorePromises);
      // const allSuccessful = [...leadResults, ...projectResults].every((result) => result && result.success);

      if (leadResults) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Archive",
          message: "Records Restore successfully",
        });
        navigate(`/archives`);
      } else if (projectResults) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Archive",
          message: "Records Restore successfully",
        });
        navigate(`/archives`);
      } else {
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Archive",
          message: "Some records could not be Restore",
        });
      }
    } catch (error) {
      console.error("Error restoring records:", error);
    }
  };

  console.log("Selected Records", selectedRecords);
  const archiveHeader = [
    {
      title: (
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
      ),
      prop: "checkbox",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedRecords.includes(row)}
          onChange={() => handleSelectRecord(row)}
        />
      ),
    },

    {
      title: "Deleted Date",
      prop: "reason_of_delete_date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <span>{moment(row.reason_of_delete_date).format("DD MMM, yyyy")}</span>
      ),
    },
    {
      title: "Reason Of Delete",
      prop: "description",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Deleted Summary",
      prop: "delete_summary",
      isFilterable: true,
      isSortable: true,
    },
  ];
  const projectHeader = [
    {
      title: (
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
      ),
      prop: "checkbox",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedRecords.includes(row)}
          onChange={() => handleSelectRecord(row)}
        />
      ),
    },
    {
      title: "Project Name",
      prop: "project_name",
      isFilterable: true,
      isSortable: true,
      // cell: (row) => (
      //   <Link to={"/projects/" + row.id} state={row}>
      //     {row.project_name}
      //   </Link>
      // ),
    },
    {
      title: "Contact Name",
      prop: "contactname",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Lead Name",
      prop: "leadname",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Project Status",
      prop: "project_status",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <span
          className="badge"
          style={{
            backgroundColor: getStatusClass(row.project_status),
            display: "block",
            paddingBottom: "5px",
          }}
        >
          {row.project_status}
        </span>
      ),
    },
    {
      title: "Expected Delivery Date",
      prop: "expected_date_delivery",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <span>{moment(row.expected_date_delivery).format("DD MMM, yyyy")}</span>
      ),
    },
    {
      title: "Quote Approval Date",
      prop: "quote_approval_date",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <span>{moment(row.quote_approval_date).format("DD MMM, yyyy")}</span>
      ),
    },
    {
      title: "Created Date",
      prop: "createddate",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <span>{moment(row.createddate).format("DD MMM, yyyy")}</span>
      ),
    },
    {
      title: "Assigned",
      prop: "ownername",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <Link
          to={"/users/" + row.ownerid}
          state={row}
          className="align-items-center justify-content-start"
        >
          <img
            src="https://www.w3schools.com/bootstrap4/img_avatar3.png"
            className="rounded-circle"
            style={{ height: "30px", width: "30px", objectFit: "cover" }}
            alt=""
          />
          <span className="mx-2">{row.ownername}</span>
        </Link>
      ),
    },
  ];

  const leadHeader = [
    {
      title: (
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
      ),
      prop: "checkbox",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedRecords.includes(row)}
          onChange={() => handleSelectRecord(row)}
        />
      ),
    },
    {
      title: "Lead Name",
      prop: "leadname",
      isFilterable: true,
      isSortable: true,
      // cell: (row) => (
      //   <Link to={"/leads/" + row.id} state={row}>
      //     {row.leadname}
      //   </Link>
      // ),
    },
    {
      title: "Contact Name",
      prop: "contactname",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Phone",
      prop: "phone",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Email",
      prop: "email",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Lead Status",
      prop: "leadstatus",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <span
          className="badge"
          style={{
            backgroundColor: getStatusClassLead(row.leadstatus),
            display: "block",
            paddingBottom: "5px",
          }}
        >
          {row.leadstatus}
        </span>
      ),
    },
    {
      title: "Company",
      prop: "company",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Lead Source",
      prop: "leadsource",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Created Date",
      prop: "createddate",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <span>{moment(row.createddate).format("DD MMM, yyyy")}</span>
      ),
    },
    {
      title: "Assigned",
      prop: "ownername",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <Link
          to={"/users/" + row.ownerid}
          state={row}
          className="align-items-center justify-content-start"
        >
          <img
            src="https://www.w3schools.com/bootstrap4/img_avatar3.png"
            className="rounded-circle"
            style={{ height: "30px", width: "30px", objectFit: "cover" }}
            alt=""
          />
          <span className="mx-2">{row.ownername}</span>
        </Link>
      ),
    },
  ];

  const handleCloseModal = () => {
    setDeleteModal(false);
  };

  const labels = {
    beforeSelect: " ",
  };

  const handleChange = (e) => {
    setTask({ ...task, [e.target.name]: e.target.value });
  };
  const checkRequiredFields = () => {
    if (task.description && task.description.trim() !== "") {
      return false;
    }

    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkRequiredFields()) {
      setValidated(true);
      return;
    }
    if (task.reason_of_delete_date === undefined) {
      //console.log('if call')
      task.reason_of_delete_date = moment(
        task.reason_of_delete_date
      ).toISOString();
    }

    let result = {};
    let taskDeleteSummaryLead = "";
    let taskDeleteSummaryProject = "";

    if (selectedRecords.length > 0) {
      const record = selectedRecords[0];
      if (record.lead_id) {
        taskDeleteSummaryLead = `Lead Name: ${record.leadname}, Contact Name: ${record.contactname}`;
        task.delete_summary = taskDeleteSummaryLead;
        result = await WhiteObjectApi.createTask(task);
      } else if (record.project_lead_id) {
        taskDeleteSummaryProject = `Project Name: ${record.project_name}, Lead Name: ${record.leadname}, Contact Name: ${record.contactname}`;
        task.delete_summary = taskDeleteSummaryProject;
        result = await WhiteObjectApi.createTask(task);
      }
    }

    if (result) {
      const leadRecords = selectedRecords.filter((record) => record.lead_id);
      const projectRecords = selectedRecords.filter(
        (record) => record.project_id
      );

      const leadRestorePromises = leadRecords.map(async (record) => {
        const updatedRecord = { id: record.lead_id };
        const deleteRecord = { id: record.id };
        try {
          const saveResult = await WhiteObjectApi.deleteLead(updatedRecord.id);
          if (saveResult && saveResult.success) {
            await WhiteObjectApi.deleteArchive(deleteRecord.id);
          }
          setSelectedRecords([]); //Added by shivani 9 aug
          onFilterType({ target: { value: "Lead" } }); //Added by shivani 9 aug
          setDeleteModal(false); //Added by shivani 9 aug
          return saveResult;
        } catch (error) {
          console.error("Error deleting lead record:", error);
          return { success: false };
        }
      });

      const projectRestorePromises = projectRecords.map(async (record) => {
        const updatedRecord = { id: record.project_id };
        const deleteRecord = { id: record.id };
        try {
          const saveResult = await WhiteObjectApi.deleteProject(
            updatedRecord.id
          );
          if (saveResult && saveResult.success) {
            await WhiteObjectApi.deleteArchive(deleteRecord.id);
          }
          setSelectedRecords([]); //Added by shivani 9 aug
          onFilterType({ target: { value: "Project" } });  //Added by shivani 9 aug
          setDeleteModal(false); //Added by shivani 9 aug
          return saveResult;
        } catch (error) {
          console.error("Error deleting project record:", error);
          return { success: false };
        }
      });

      try {
        const leadResults = await Promise.all(leadRestorePromises);
        const projectResults = await Promise.all(projectRestorePromises);
        // const allSuccessful = [...leadResults, ...projectResults].every((result) => result && result.success);

        if (leadResults) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Archive",
            message: "Records Deleted successfully",
          });
          navigate(`/archives`);
        } else if (projectResults) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Archive",
            message: "Records Deleted successfully",
          });
          navigate(`/archives`);
        } else {
          PubSub.publish("RECORD_ERROR_TOAST", {
            title: "Archive",
            message: "Some records could not be Deleted",
          });
        }
      } catch (error) {
        console.error("Error restoring records:", error);
      }
    }
  };

  const handleDeleteArcive = async () => {
    const leadRecords = selectedRecords.filter((record) => record.id);

    const deletedRecordPromises = leadRecords.map(async (record) => {
      const deleteRecord = { id: record.id };
      try {
        await WhiteObjectApi.deleteTask(deleteRecord.id);
      } catch (error) {
        console.error("Error deleting lead record:", error);
        return { success: false };
      }
      setSelectedRecords([]); //Added by shivani 9 aug
      onFilterType({ target: { value: "Archive Deleted" } });  //Added by shivani 9 aug
      return { success: true };
    });

    try {
      const deletedResults = await Promise.all(deletedRecordPromises);

      if (deletedResults.every((result) => result.success)) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Archive",
          message: "Records deleted successfully",
        });
      }
    } catch (error) {
      console.error("Error handling delete archive:", error);
    }
  };

  const headers = isLead
    ? leadHeader
    : isProject
    ? projectHeader
    : archiveHeader;

  return (
    <Main>
      <Helmet>{LIST_TAB_TITLE.ProjectList}</Helmet>
      <Row className="g-0">
        <Col lg={12} className="px-4">
          <DatatableWrapper
            body={filteredProjects}
            headers={headers}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={5}
                className="d-flex flex-col justify-content-start align-items-center"
              >
                <PaginationOptions labels={labels} />
                <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Enter status"
                    name="archiveType"
                    onChange={onFilterType}
                  >
                    <option value="">--Select Type--</option>
                    <option value="Lead">Lead</option>
                    <option value="Project">Project</option>
                    <option value="Archive Deleted">Archive Deleted</option>
                  </Form.Select>
                </Form.Group>

                <BrowserView>
                  <div style={{ position: "absolute", marginBlock: "-22px" }}>
                    <InfoPill left="Total" right={filteredProjects?.length} />
                  </div>
                </BrowserView>
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary mx-2"
                  onClick={() => archiveLeads()}
                >
                  Restore
                </Button>
                {Permissionhelper.checkPermission(constants.SUPER_ADMIN) && (
                  <>
                    {!isLead && !isProject && selectedRecords.length > 0 &&(
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => handleDeleteArcive()}
                      >
                        Delete
                      </Button>
                    )}

                    {isLead && selectedRecords.length > 0 &&(
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => setDeleteModal(true)}
                      >
                        Delete
                      </Button>
                    )}

                    {isProject && selectedRecords.length > 0 &&(
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => setDeleteModal(true)}
                      >
                        Delete
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
            {body ? (
              <Table striped className="data-table" responsive="sm">
                <TableHeader />
                <TableBody />
              </Table>
            ) : (
              <ShimmerTable row={10} col={8} />
            )}
            <Pagination />
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>

        <Modal show={deleteModalShow} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated}>
              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicTargetdate">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicTargetdate"
                    >
                      Delete Date
                    </Form.Label>
                    <Form.Control
                      required
                      disabled
                      type="date"
                      name="reason_of_delete_date"
                      placeholder="Enter Delete Date"
                      value={moment(task.reason_of_delete_date).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12} className="mt-4">
                  <Form.Group className="mx-3" controlId="formBasicDescription">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicDescription"
                    >
                      Why are you deleting this record ?
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      name="description"
                      placeholder="Enter Reason of Delete"
                      value={task.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="danger" onClick={handleSubmit}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </Main>
  );
};

export default LeadList;
