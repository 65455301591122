/** @format */

// import Sidebar from "../../components/layout/common/Sidebar"
import Header from "../../components/layout/common/Header";
// import "../../components/layout/common/Header.css";
const Main = ({ children }) => {
  return (
    // <div className="wrapper">
      <div id="content">
        
        {children && children}
      {/* </div> */}
    </div>
  );
};

export default Main;
