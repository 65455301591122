import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../Auth/AuthProvider";
import jwt_decode from "jwt-decode";
import React from "react";

const Auth = ({ allowedRoles }) => {
  //eslint-disable-next-line
  const { auth, setAuth, accessToken } = useContext(AuthContext);
  const location = useLocation();
  let permissionArray = [];
  if (accessToken) {
    const decodedToken = jwt_decode(accessToken);
    const permissions = decodedToken.permissions;

    permissions && //eslint-disable-next-line
      permissions.map((vl) => {
        permissionArray.push(vl.name);
      });
  }
  console.log("alow Role",allowedRoles)

  return allowedRoles.find((role) => permissionArray.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to={"/no-access"} state={{ from: location }} replace />
  );
};

export default Auth;
