import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import { useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import { isMobile, BrowserView } from "react-device-detect";
import jwt_decode from "jwt-decode";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "../InfoPill";
import Main from "../layout/Main";
import { Helmet } from "react-helmet";
import { LEAD_STATUS_VALUES, LIST_TAB_TITLE } from "../../constants/CONSTANT";

const LeadList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [lead, setLead] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [leadStatusArray, setLeadStatusArray] = useState(
    JSON.parse(localStorage.getItem("lead_status")) || []
  );
  const [selectedLeadStatus, setSelectedLeadStatus] = useState("");

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem("token")));

    async function fetchLeads() {
      const leads = await WhiteObjectApi.fetchFollowUpLeads();
      if (leads) {
        setLead(leads);
        if (location.state && location.state.leadStatus) {
          setSelectedLeadStatus(location.state.leadStatus);
          filterLeads(leads, location.state.leadStatus);
        } else {
          setBody(leads);
        }
      } else {
        setLead([]);
        setBody([]);
      }
    }
    fetchLeads();
  }, [location.state]); // Depend only on location.state

  const filterLeads = (leads, status) => {
    const filteredLeads = leads.filter(
      (data) => data.leadstatus.toLowerCase() === status.toLowerCase()
    );
    setBody(filteredLeads);
  };

  const onFilterType = (event) => {
    const selectedValue = event.target.value;
    setSelectedLeadStatus(selectedValue);
    if (selectedValue === "") {
      setBody(lead);
    } else {
      filterLeads(lead, selectedValue);
    }
  };

  const header = [
    {
      title: "Name",
      prop: "leadname",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <Link to={"/leads/" + row.id} state={row}>
          {row.leadname}
        </Link>
      ),
    },
    {
      title: "Contact Name",
      prop: "contactname",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        //Added by shivani
        <Link to={"/contacts/" + row.contactid}>
          {row.contactname}
        </Link>
      ),
    },
    { title: "Phone", prop: "phone", isFilterable: true },
    {
      title: "POC Name",
      prop: "poc_contact_name",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Lead Status",
      prop: "leadstatus",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <span
          className="badge"
          style={{ backgroundColor: getStatusClass(row.leadstatus) }}
        >
          {row.leadstatus}
        </span>
      ),
    },
    { title: "Company", prop: "company", isFilterable: true, isSortable: true },
    { title: "Lead Source", prop: "leadsource", isFilterable: true },
    {
      title: "Created Date",
      prop: "createddate",
      isSortable: true,
      isFilterable: true,
      cell: (row) => (
        <span>{moment(row.createddate).format("DD MMM, yyyy")}</span>
      ),
    },
    {
      title: "Assigned",
      prop: "ownername",
      isFilterable: true,
      isSortable: true,
      cell: (row) => (
        <Link
          to={"/users/" + row.ownerid}
          state={row}
          className="align-items-center justify-content-start"
        >
          <img
            src="https://www.w3schools.com/bootstrap4/img_avatar3.png"
            className="rounded-circle"
            style={{ height: "30px", width: "30px", objectFit: "cover" }}
            alt=""
          />
          <span className="mx-2">{row.ownername}</span>
        </Link>
      ),
    },
  ];

  const getStatusClass = (status) => {
    const statusObj = LEAD_STATUS_VALUES.find(
      (value) => value.label === status
    );
    return statusObj ? statusObj.backgroundColor : "#FFFFFF";
  };

  const createLead = () => {
    navigate("/leads/e");
  };

  return (
    <div>
      {/* <Helmet>{LIST_TAB_TITLE.LeadList}</Helmet> */}
      {console.log(body, "body")}
      <Row className="g-0">
        <Col lg={12} className="px-4">
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 15,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                lg={5}
                className="d-flex flex-col justify-content-start align-items-center"
              >
                <PaginationOptions labels={{ beforeSelect: " " }} />
                <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Enter status"
                    name="leadstatus"
                    value={selectedLeadStatus}
                    onChange={onFilterType}
                  >
                    <option value="">--Select Type--</option>
                    {leadStatusArray.map((item, index) => (
                      <option key={index} value={item.label}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <BrowserView>
                  <InfoPill left="Total" right={body?.length} />
                </BrowserView>
              </Col>
              <Col
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary mx-2"
                  onClick={createLead}
                >
                  New Lead
                </Button>
              </Col>
            </Row>
            {body ? (
              <Table striped className="data-table" responsive="sm">
                <TableHeader />

                <TableBody />
              </Table>
            ) : (
              <ShimmerTable row={10} col={8} />
            )}
            <Pagination />
          </DatatableWrapper>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </div>
  );
};

export default LeadList;
