import { React, useEffect, useState } from "react";
import Main from "../layout/Main";
import {
  Badge,
  Card,
  Col,
  Row,
  Button,
  FormLabel,
  FormControl,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import DonutChart from "./DonutChart";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import EventEdit from "./EventEdit";
import LeadTableDasboard from "./LeadTableDasboard";

const Dashboard = () => {
  const [leadsUserWise, setLeadsUserWise] = useState([]);
  const [leadCount, setLeadCount] = useState([]);
  const [projectCount, setProjectCount] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState([]);
  const [lostLeads, setLostLeads] = useState([]);
  const [repairCount, setRepairCount] = useState([]);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [showEventModel, setShowEventModel] = useState(false);
  const [filterDate, setFilterDate] = useState({});
 
  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); 
    const currentYear = String(currentDate.getFullYear());

    setFilterDate({
      month: currentMonth,
      year: currentYear
    });
    // fetchLeadsData();
  }, []);

  // useEffect(() => {
  //   console.log("useEffect filterDate",filterDate)
  //   fetchLeadsData(filterDate);
  // }, []);

  useEffect(() => {
    if (filterDate.month && filterDate.year) {
      fetchLeadsData(filterDate);
    }
  }, [filterDate]);

  

  console.log("Filter Date Back",filterDate);

  async function fetchLeadsData(filterDate) {
    try {
      const fetchLeadsUserWise = await WhiteObjectApi.fetchLeadUserWise(filterDate);
      console.log("fetchLeadsUserWise", fetchLeadsUserWise);
      setLeadsUserWise(fetchLeadsUserWise ? fetchLeadsUserWise : [] );
      const projects = await WhiteObjectApi.fetchProjectTotal(filterDate);
      const leads = await WhiteObjectApi.fetchLeadCount(filterDate);
      const repairs = await WhiteObjectApi.fetchRepairTicketTotal(filterDate);
      const totalRevenueProject = await WhiteObjectApi.fetchProjectRevenueTotal(filterDate);
      // const leadsNotConverted = await WhiteObjectApi.fetchLeadNotConverted();
      const allMeetings = await WhiteObjectApi.fetchAllMeetings("today");
      // if (leadsNotConverted) { 
      //   setLostLeads(leadsNotConverted);
      // }

      console.log("heooRepair", totalRevenueProject);
      //console.log('leadCountByStatus' , leadCountByStatus)
      setTodayMeetings(allMeetings ? allMeetings : []);

      if (repairs.length > 0) {
        setRepairCount(repairs[0].totalrepair);
      } else {
        setRepairCount([]);
      }
      if (projects.length > 0) {
        setProjectCount(projects[0].totalprojects);
      } else {
        setProjectCount([]);
      }

      if (leads.length > 0) {
        setLeadCount(leads[0].totalleads);
      } else {
        setLeadCount([]);
      }
      if (totalRevenueProject.length > 0) {
        setTotalRevenue(totalRevenueProject[0].totalrevenue);
      } else {
        setTotalRevenue([]);
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  }
  const data = {
    ordersByLocation: {
      "Saint Lucia": 845,
      Liberia: 548,
      "Saint Helena": 624,
      Kenya: 624,
      "Christmas Island": 412,
    },
    salesByLocation: {
      Germany: 25,
      Australia: 45,
      "United Kingdom": 10,
      Brazil: 5,
      Romania: 15,
    },
    detailedReport: {
      orders: 3450,
      users: 3450,
      newUsers: 18390,
      visitors: 23461,
    },
    salesByTime: [4, 5, 6, 7, 8, 9, 10, 11, 12],
    usersFromUS: {
      Mohit: 56,
      Farhan: 86,
      Aminudin: 12,
      Gourav: 15,
      Amir: 68,
    },
  };

  const pieDataOrders = {
    labels: Object.keys(data.ordersByLocation),
    datasets: [
      {
        data: Object.values(data.ordersByLocation),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const pieDataSales = {
    labels: Object.keys(data.salesByLocation),
    datasets: [
      {
        data: Object.values(data.salesByLocation),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const barDataSalesByTime = {
    labels: ["2am", "4am", "6am", "8am", "10am", "12pm", "2pm", "4pm", "6pm"],
    datasets: [
      {
        label: "Project Started",
        data: data.salesByTime,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
      {
        label: "Project Completed",
        data: data.salesByTime.map((val) => val * 1.5),
        backgroundColor: "rgba(153,102,255,0.4)",
        borderColor: "rgba(153,102,255,1)",
        borderWidth: 1,
      },
    ],
  };
  const tableHeaderStyle = {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    padding: "8px",
    textAlign: "left",
  };

  const tableCellStyle = {
    padding: "8px",
    textAlign: "left",
  };

  const formattedRevenue = new Intl.NumberFormat("en-IN").format(totalRevenue);

  const submitEvents = async (eventRec) => {
    ////console.log('eventRec home submit', eventRec)
    setShowEventModel(false);
    fetchLeadsData();
  };

  const handleMonth = async (e) => {
    console.log('Month', e.target.value)
    const { name, value } = e.target;
    setFilterDate({ ...filterDate, [name]: value });
  };

  const handleYear = async (e) => {
    console.log('Month', e.target.value)
    const { name, value } = e.target;
    setFilterDate({ ...filterDate, [name]: value });
    handleFilterApi();
  };

  const handleFilterApi = async (e) => {
  //  if(filterDate.month && filterDate.year){
    fetchLeadsData(filterDate);
    console.log("Called API")
  //  }
  };
  console.log("final",filterDate)

  return (
    <Main>
      <Row>
        <Col lg={4}>
          <Form.Group className="mx-3">
            <Form.Label
              className="form-view-label"
              htmlFor="formBasicFirstName"
            >
              Month
            </Form.Label>
            <Form.Select
              aria-label="Select Month"
              name="month"
              value={filterDate.month}
              onChange={handleMonth}
            >
              <option value="">--Select--</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide month.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group className="mx-3">
            <Form.Label
              className="form-view-label"
              htmlFor="formBasicFirstName"
            >
              Year
            </Form.Label>
            <Form.Select
              aria-label="Select Year"
              name="year"
              value={filterDate.year}
              onChange={handleYear}
            >
              <option value="">--Select--</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide year.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Link to="/projects" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center m-3"
              style={{
                backgroundColor: "white",
                borderLeft: "4px solid #198754",
              }}
            >
              <span className="fa-stack fa-2x">
                <i
                  className="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#198754" }}
                ></i>
                <i
                  className="fa-solid fa-diagram-project fa-stack-1x"
                  style={{ color: "white", fontSize: "2rem" }}
                ></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">TOTAL PROJECTS</h6>
                <h3 className="mb-0 d-inline ">{projectCount}</h3>
                <Badge bg="light" text="dark">
                  Total
                </Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/leads" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center m-3"
              style={{
                backgroundColor: "white",
                borderLeft: "4px solid #C92234",
              }}
            >
              <span className="fa-stack fa-2x">
                <i
                  className="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#C92234" }}
                ></i>
                <i
                  className="fa-solid fa-user-tie fa-stack-1x"
                  style={{ color: "white", fontSize: "2rem" }}
                ></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">New Leads</h6>
                <h3 className="mb-0 d-inline ">{leadCount}</h3>
                <Badge bg="light" text="dark">
                  Total
                </Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link
            to="/repairprojects"
            className="text-decoration-none text-reset"
          >
            <div
              className="p-3 d-flex align-items-center m-3"
              style={{
                backgroundColor: "white",
                borderLeft: "4px solid #DAA520",
              }}
            >
              <span className="fa-stack fa-2x">
                <i
                  className="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#DAA520" }}
                ></i>
                <i
                  className="fa-solid fa-money-bill-trend-up fa-stack-1x"
                  style={{ color: "white", fontSize: "2rem" }}
                ></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">Repair Tickets</h6>
                <h3 className="mb-0 d-inline ">{repairCount}</h3>
                <Badge bg="light" text="dark">
                  Total
                </Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/projects" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center m-3"
              style={{
                backgroundColor: "white",
                borderLeft: "4px solid #4798b5",
              }}
            >
              <span className="fa-stack fa-2x">
                <i
                  className="fa-solid fa-circle fa-stack-2x"
                  style={{ color: "#4798b5" }}
                ></i>
                <i
                  className="fa-solid fa-arrow-trend-up fa-stack-1x"
                  style={{ color: "white", fontSize: "2rem" }}
                ></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">REVENUE</h6>
                {/* <h3 className="mb-0 d-inline"></h3> */}
                {/* <Badge bg="light" text="dark"> */}
                {/* {totalRevenue} {parseFloat(totalRevenue/100000).toFixed(2)} */}
                <Badge bg="light" text="dark">
                  {" "}
                  ₹
                </Badge>
                <h3 className="mb-0 d-inline ">{formattedRevenue}</h3>
                {/* </Badge> */}
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col lg={6} style={{ border: "12px" }}>
          <Card>
            <Card.Body>
              <h4 className="text-center">LEAD STAGE REPORT</h4>
                <DonutChart filterDateData={filterDate}/>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col lg={6}>
          <Card style={{ height: "100%" }}>
            <Card.Body>
              <div style={{ width: "100%" }}>
                <h4 className="text-center">PROJECT STAGE REPORT</h4>
                <BarChart data={barDataSalesByTime} />
              </div>
            </Card.Body>
          </Card>
        </Col> */}
        <Col lg={6}>
          <div style={{ height: "350px" }}>
            <Card className="">
              <Card.Header
                style={{
                  backgroundColor: "#009ab6",
                  borderBottom: "none",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                QUICK ACTIONS
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Text>
                      <div>
                        <Button
                          className=" btn-custom btn-sm btn-block"
                          style={{ width: "80%" }}
                          onClick={() => setShowEventModel(true)}
                        >
                          {" "}
                          <i className="fa-solid fa-list-check"></i> NEW
                          MEETINGS
                        </Button>
                      </div>
                    </Card.Text>
                  </Col>
                  <Col>
                    <center>
                      <div>
                        <i
                          className="fa-regular fa-calendar-days fa-2x"
                          style={{ color: "#E73F59" }}
                        ></i>
                      </div>
                      <p className="pt-2">
                        <i>
                          You have{" "}
                          <Link to={"/meetings/today"}>
                            <b>{todayMeetings?.length}</b>
                          </Link>{" "}
                          meetings today
                        </i>
                      </p>
                    </center>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-muted">
                Followup with{" "}
                <Link to={"/followup"} style={{ textDecoration: "none" }}>
                  these
                </Link>{" "}
                leads today
              </Card.Footer>
              <div className="user-container">
                <div className="flex-container">
                  <div className="user-stats-section">
                    <h4 className="text-center">USER WISE LEAD COUNT</h4>
                    <div className="user-stats">
                      {leadsUserWise?.map((userLead) => (
                        <div key={userLead.ownername} className="user-stat">
                          <span className="state-name">
                            {userLead.ownername}
                          </span>
                          <div className="progress-bar">
                            <div
                              className="progress"
                              style={{
                                width: `${userLead.lead_count}%`,
                                backgroundColor: "#36A2EB",
                              }}
                            ></div>
                          </div>
                          <span className="percentage">
                            {userLead.lead_count}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="not-contacted-leads-section">
                    <h4 className="text-center">Not Contacted Leads</h4>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <LeadTableDasboard filterDateData={filterDate}/>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        {/* <Col lg={6}>
          <Card>
            <Card.Body>
              
            </Card.Body>
          </Card>
        </Col> */}
        <Col lg={12} className="mt-4">
          <Card>
            <Card.Body>
              <div style={{ marginBottom: "10px" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ width: "100%" }}>
                    <h4 className="text-center">PROJECT REVENUE LAST MONTH</h4>
                    <LineChart filterDateData={filterDate} />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                ></div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showEventModel && (
        <EventEdit
          show={showEventModel}
          onHide={() => setShowEventModel(false)}
          parentid="abc"
          //eventRec={event}
          table="user"
          submitEvents={submitEvents}
        />
      )}
    </Main>
  );
};

export default Dashboard;
