import React, { useEffect, useState } from "react";

import "./App.css";
import "./resources/css/Sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import io from "socket.io-client";
import Login from "./components/Login";
import Home from "./components/Home/Home";
import Dashboard from "./components/Home/Dashboard";
import ProjectDashboard from "./components/Dashboard/ProjectDashboard";
import Calendar from "./components/Home/Calendar";
import MainNotify from "./components/layout/MainNotify";
import { ReportList, ReportView } from "./components/Report";
import { UserProfileEdit } from "./components/UserProfile";
import { BackupList } from "./components/Backup";

import {EnquiryForm,ServiceForm} from "./components/EnquiryForm";

import {
  LeadEdit,
  LeadList,
  LeadView,
  LeadEditByModel,FollowUpLeadsToday
} from "./components/Lead";
import { ProjectEdit, ProjectList, ProjectView } from "./components/Project";
import { ProductEdit, ProductList, ProductView } from "./components/Product";
import { AccountEdit, AccountList, AccountView } from "./components/Account";
import { ContactEdit, ContactList, ContactView } from "./components/Contact";
import { RepairTicketEdit, RepairTicketList, RepairTicketView } from "./components/RepairTicket";
import { UserEdit, UserList, UserView } from "./components/UserManagement";
import { ArchiveList, ArchiveView } from "./components/Archive";

import * as constants from "./constants/CONSTANT";
import PubSub from "pubsub-js";
import { Toast, ToastContainer } from "react-bootstrap";
// Import Font Awesome CSS
import "font-awesome/css/font-awesome.min.css";

/*********************
 * CP Code
 *******************/
//eslint-disable-next-line
import { PATHS } from "./constants/WebRouting";
import Auth from "./components/Auth/Auth";
//eslint-disable-next-line
import NoAccess from "./components/common/NoAccess";
import { getUserDetails } from "../src/utils/helper";
import Header from "./components/layout/common/Header";
// import WhiteObjectApi from "../../../api/WhiteObjectApi";

/*********************
 * CP Code
 *******************/
const ENDPOINT = constants.SOCKET_BASE_URL;

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState("Confirmation");
  const [message, setMessage] = useState("");
  const [connectedSocket, setConnectedSocket] = useState();
  const [variant, setVariant] = useState("");
  //eslint-disable-next-line
  const [userInfo, setUserInfo] = useState({});
  //eslint-disable-next-line
  const [permissions, setPermissions] = useState();
  //eslint-disable-next-line
  const [loginUser, setLoginUserList] = useState({});
  const mySubscriber = (msg, data) => {
    switch (msg) {
      case "RECORD_SAVED_TOAST":
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant("success");
        break;
      case "RECORD_ERROR_TOAST":
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant("danger");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    PubSub.subscribe("RECORD_SAVED_TOAST", mySubscriber);
    PubSub.subscribe("RECORD_ERROR_TOAST", mySubscriber);

    try {
      if (localStorage.getItem("token")) {
        let user = getUserDetails();
        setUserInfo(user);

        var perm = user.permissions
          .map(function (obj) {
            return obj.name;
          })
          .join(";");
        setPermissions(perm);

        //socket
      console.log(ENDPOINT);

      let socket = io(ENDPOINT, {     // note changed URL here
        path: '/ibs/socket.io',
        transports: ['websocket'],
      });
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to`,err);
      });
      console.log(socket);
      socket.emit("setup", user);
      socket.on("connect", (msg) => {
        console.log('connected client')
        setConnectedSocket(socket);
      })
      
      socket.on('connect_error', (err) => {
        console.error('Connection error:', err);
      });
      
      
      socket.on('disconnect', (reason) => {
        console.log('Disconnected from server:', reason);
      });
      }
      
    } catch (error) {
      console.log(error);
    }
  
    // const fetchLoginUserList = async () => {
    //   const result = await WhiteObjectApi.getLoginUserData();
    //   setLoginUserList(result);
    // }

    // fetchLoginUserList();
  }, []);

  return (
    <>
      <ToastContainer className="p-3" position="top-center">
        <Toast
          show={modalShow}
          onClose={() => setModalShow(false)}
          delay={3000}
          bg={variant}
          className="text-white"
          autohide
        >
          {variant === "success" ? (
            <div
              className="p-1 m-1"
              style={{ backgroundColor: "#198754", color: "white" }}
            >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i
                className="fa-solid fa-xmark text-white float-right"
                style={{ float: "right" }}
                role="button"
                onClick={() => setModalShow(false)}
              ></i>
            </div>
          ) : (
            <div
              className="p-1 m-1"
              style={{ backgroundColor: "#DC3545", color: "white" }}
            >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i
                className="fa-solid fa-xmark text-white float-right"
                style={{ float: "right" }}
                role="button"
                onClick={() => setModalShow(false)}
              ></i>
            </div>
          )}

          <Toast.Body>
            {message instanceof Array ? (
              message.map((item) => {
                return <span>{item.msg}</span>;
              })
            ) : (
              <span>{message}</span>
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>
          <Route path="/" exact element={<Login />} />
          
          <Route path="/" element={<MainNotify socket={connectedSocket} />}>
            
          {/* **************** My Profile Edit *********************** */}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/myprofile"} element={<UserProfileEdit />} />
          </Route>

          {/********* Home *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            {/* <Route path={"/dashboard"} element={<Home />} /> */}
          </Route>

          {/********* dashboardhome *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/dashboardhome"} element={<Dashboard />} />
          </Route>

          {/********* project Dashboard *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/projectdashboard"} element={<ProjectDashboard />} />
          </Route>

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/home"} element={<Home />} />
          </Route>

          {/******** Show All Report *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/reports"} element={<ReportList />} />
          </Route>

          {/******** Edit Report By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route
              path={"/reports/:id"}
              element={
                <>
                  {/* <Header/> */}
                  <ReportView />
                </>
              }
            />
          </Route>
          {/* Leads */}

          {/******** Show All Leads *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/leads"} element={<LeadList />} />
          </Route>

          {/******** Create a Leads *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/leads/e"} element={<LeadEdit />} />
          </Route>

          {/******** Show a Leads By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/leads/:id"} element={<LeadView />} />
          </Route>

          {/******** Edit Lead By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route
              path={"/leads/:id/e"}
              element={
                <>
                  {/* <Header/> */}
                  <LeadEdit />
                </>
              }
            />
          </Route>

          {/* Follow Up */}

           {/******** Show All Leads Follow Up Today *******/}

           <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/followup"} element={<FollowUpLeadsToday />} />
          </Route>

          {/* Projects */}

          {/******** Show All Projects *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/projects"} element={<ProjectList />} />
          </Route>
          {/******** Create a Projects *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/projects/e"} element={<ProjectEdit />} />
          </Route>

          {/******** Show a Projects By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/projects/:id"} element={<ProjectView />} />
          </Route>

          {/******** Edit Projects By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/projects/:id/e"} element={<ProjectEdit />} />
          </Route>

          {/*****************Product*******************/}
          {/******** Show All Projects *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/products"} element={<ProductList />} />
          </Route>

          {/******** Create a Product *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/products/e"} element={<ProductEdit />} />
          </Route>

          {/******** Show a Product By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/products/:id"} element={<ProductView />} />
          </Route>

          {/******** Edit Product By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/products/:id/e"} element={<ProductEdit />} />
          </Route>

          {/* Account */}

          {/******** Show All Account *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/accounts"} element={<AccountList />} />
          </Route>
          {/******** Create a Account *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/accounts/e"} element={<AccountEdit />} />
          </Route>

          {/******** Show a Account By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/accounts/:id"} element={<AccountView />} />
          </Route>

          {/******** Edit Account By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/accounts/:id/e"} element={<AccountEdit />} />
          </Route>

                        {/* Contact */}

          {/******** Show All Contact *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/contacts"} element={<ContactList />} />
          </Route>
          {/******** Create a Contact *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/contacts/e"} element={<ContactEdit />} />
          </Route>

          {/******** Show a Contact By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/contacts/:id"} element={<ContactView />} />
          </Route>

          {/******** Edit Contact By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/contacts/:id/e"} element={<ContactEdit />} />
          </Route>

          {/* User Mangement */}
          {/******** Show All User Mangement *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/users"} element={<UserList />} />
          </Route>

          {/******** Create a User Mangement *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/users/e"} element={<UserEdit />} />
          </Route>

          {/******** Show a Users By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/users/:id"} element={<UserView />} />
          </Route>

          {/******** Edit Users By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route
              path={"/users/:id/e"}
              element={
                <>
                  {/* <Header/> */}
                  <UserEdit />
                </>
              }
            />
          </Route>
          {/* Archive */}

          {/******** Show All Archive *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/archives"} element={<ArchiveList />} />
          </Route>

          {/******** Show a Archive By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/archives/:id"} element={<ArchiveView />} />
          </Route>
                  {/* Meeings */}

          <Route path="/meetings" element={<Calendar />} />

          <Route path="/meetings/:today" element={<Calendar />} />
          {/******** Backup List *******/}
          <Route element={<Auth allowedRoles={[constants.MODIFY_ALL , constants.CREATE_ALL]} />}>
            <Route path={"/backup"} element={<BackupList />} />
          </Route>

          

           {/* Repair Ticket */}

          {/******** Show All Repair Ticket *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/repairprojects"} element={<RepairTicketList />} />
          </Route>
          {/******** Create a Repair Ticket *******/}

          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/repairprojects/e"} element={<RepairTicketEdit />} />
          </Route>

          {/******** Show a Repair Ticket By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/repairprojects/:id"} element={<RepairTicketView />} />
          </Route>

          {/******** Edit Repair Ticket By Id *******/}
          <Route
            element={
              <Auth
                allowedRoles={[constants.MODIFY_ALL, constants.CREATE_ALL]}
              />
            }
          >
            <Route path={"/repairprojects/:id/e"} element={<RepairTicketEdit />} />
          </Route>
          </Route>
        </Routes>
        <Routes>
          {/******** Enquiry Form (External) *******/}
          <Route >
            <Route path={"/enquiryform"} element={<EnquiryForm />} />
          </Route>

          {/******** Service Form Form (External) *******/}
          <Route >
            <Route path={"/serviceform"} element={<ServiceForm />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
