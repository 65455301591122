import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import { useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import {isMobile, MobileView, BrowserView} from 'react-device-detect';
import jwt_decode from "jwt-decode";


import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "../InfoPill";
import Main from "../layout/Main";
import { Helmet } from "react-helmet";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from '../../constants/CONSTANT';

const LeadList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [leadname, setProjectName] = useState();
  // const[contact,setContact] = useState(location.state ? location.state : {});
  const [lead, setProject] = useState();
  const [userInfo, setUserInfo] = useState({});
  //////console.log('location.state ='+location.state)
const [projectStatusArray, setProjectStatusArray] = useState(constants.Project_Stage_Status);

  useEffect(() => {
    // setUserInfo(jwt_decode(localStorage.getItem('token')));

    async function init() {
      const projects = await WhiteObjectApi.fetchProduct();
      if (projects) {
        setBody(projects);
        setProject(projects);
      } else {
        setBody([]);
        setProject([]);
      }
    }
    init();
    setProjectName(body.firstname + " " + body.lastname);
  }, [leadname]);

  const onFilterType = (event) => {
    if (event.target.value === "") {
      setBody(lead);
    } else {
      setBody(
        lead.filter((data) => {
          if (
            (data.projectstatus || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };

const getStatusClass = (status) =>{
  ////console.log('status',status)
  let accessStatusRec = projectStatusArray.filter((value, index, array) => {
    if(value.label === status){

     return true
    }

  });
  ////console.log('accessStatusRec',accessStatusRec)
  if(accessStatusRec && accessStatusRec.length > 0){
  
  if(accessStatusRec[0].is_converted === true){
    ////console.log('if isconverted')
    
    return 'success';
  }else if (accessStatusRec[0].is_lost === true){
    return 'secondary';
  }else{
    return 'primary';
  }
}else{
  return 'secondary';
}
}


  // Create table headers consisting of 4 columns.
  const header = [];
  
  if(!isMobile){
    header.push(
      {
        title: "Product Name",
        prop: "product_name",
        isFilterable: true,isSortable: true,
        cell: (row) => (
          <Link to={"/products/" + row.id} state={row}>
            {row.product_name}
          </Link>
        ),
      },
      
     
      { title: "Product Description", prop: "product_description", isFilterable: true },
      { title: "External Source Name", prop: "external_source_name", isFilterable: true },
      
        { title: "Created Date", prop: "createddate",  isSortable: true, isFilterable: true, cell: (row) => (
         <span>
            {moment(row.createddate).format('DD MMM, yyyy')}
          </span>
        )  }
    )
  }else{
    //for mobile device
    
    header.push(
      {
        title: "Info",
        prop: "leadname",
        isFilterable: true,isSortable: true,
        cell: (row) => (
          <div className="mobilecard">
          <Link to={"/projects/" + row.id} state={row} style={{fontSize: "1.2rem"}}>
            {row.leadname} 
          </Link>
          <Link to={"/users/" + row.ownerid} state={row}>
          <i class="fa-solid fa-user"></i> {row.ownername}
            </Link>
            <span><i class="fa-solid fa-phone"></i> {row.phone}</span>
            <span style={{width: "80%"}}><i class="fa-solid fa-envelope"></i> {row.email}</span>
            <Badge bg={getStatusClass(row.projectstatus)} style={{paddingBottom : "5px", width: "80%"}}>
              {row.projectstatus} 
            </Badge>
          </div>
        ),
      },
      )
  }
   

    


  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createProject = () => {
    navigate(`/products/e`);
  };

  return (
    <Main>
      <>
      <Helmet>{LIST_TAB_TITLE.ProductList}</Helmet>
      </>

    
    <Row className="g-0">
     
      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 15,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >

              <Filter/>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={5}
              className="d-flex flex-col justify-content-start align-items-center"
            >
              <PaginationOptions labels={labels} />
              
              <BrowserView>
              <InfoPill left="Total" right={body?.length} />
              </BrowserView>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={3}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary mx-2"
                onClick={() => createProject(true)}
              >
                New Product
              </Button>
              {/* <Button className="btn-sm" variant="outline-primary" onClick={()=>location.state ? navigate(`/contacts/${contact.id}`) : navigate('/contacts/')}>Back to Contact</Button> */}
            </Col>
          </Row>
          {body ? (
            <Table striped className="data-table" responsive = "sm">
              <TableHeader />

              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}

          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
    </Main>
  );
};
export default LeadList;
