import React, { useState, useEffect } from 'react'

import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BarChart from '../charts/BarChart';
import BarChart1 from '../charts/BarChart1';
import SplineLineChart from '../charts/SplineLineChart';
import PieChart from '../charts/PieChart';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import EventEdit from './EventEdit'
// import WhiteObjectApi from "../api/WhiteObjectApi";
// import RelatedListTask from "./RelatedListTask";
// import TaskEdit from "./TaskEdit";
// import EventEdit from './EventEdit'
import GroupBarChart from '../charts/GroupBarChart';
import Main from "../layout/Main";
import Helmet from "react-helmet";
import {LIST_TAB_TITLE} from "../../constants/CONSTANT";

const Home = () => {

  const [newLead, setNewLad] = useState("");
  const [allContacts, setAllCOntacts] = useState("");
  const [allProjects, setAllProjects] = useState("");
  const [totalBusiness, setTotalBusiness] = useState("");
  const [totalExpectedCoast, setTotalExpectedCoast] = useState("");
  const [showEventModel, setShowEventModel] = useState(false);
  const calendarRef = React.createRef();
  const [reletedListTask, setReletedListTask] = useState(false);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [workingLeads, setWorkingLeads] = useState(0);
  const navigate = useNavigate();

  // useEffect(() => {
  //   //const st = "Open - Not Contactecd"
  //   async function init() {
  //     // const fetchNewLeads = await WhiteObjectApi.fetchNewLeads();
  //     const fetchallcontacts = await WhiteObjectApi.fetchallcontacts();
  //     const fetchallprojects = await WhiteObjectApi.fetchallprojects();
  //     //const fetchTotalBusiness = await WhiteObjectApi.fetchTotalBusiness();
  //     const fetchTotalExpectedCoast = await WhiteObjectApi.fetchTotalExpectedCoast();
  //     //const leadCountByStatus = await WhiteObjectApi.fetchLeadReports('lead_count_by_status');
  //     const allMeetings = await WhiteObjectApi.fetchAllMeetings('today');
  //     //console.log('leadCountByStatus' , leadCountByStatus)
  //      setTodayMeetings(allMeetings ? allMeetings : []);
  //    // console.log('fetchTotalBusiness:', fetchTotalBusiness);
  //     // setNewLad(fetchNewLeads.total);
  //     setAllCOntacts(fetchallcontacts.total);
  //     setAllProjects(fetchallprojects.total)
  //     //setTotalBusiness(fetchTotalBusiness.total)
  //     setTotalExpectedCoast(fetchTotalExpectedCoast.total)
  //     //console.log("fetchNewLeads" , fetchNewLeads);
  //     //console.log("fetchLeads=>" + JSON.stringify(fetchNewLeads));
  //     //console.log("fetchallcontacts=>" + JSON.stringify(fetchallcontacts));
  //     //console.log("fetchallActiveusers=>" + JSON.stringify(fetchallActiveusers));
  //    // console.log('lead counts', leadCountByStatus);
  //     let allLeadStatus = JSON.parse(localStorage.getItem("lead_status"));
  //     let countWorking = 0;
  //     // leadCountByStatus.forEach(function(item){
        
  //     //   allLeadStatus.forEach(function(val){
  //     //     if(val.is_converted === false && val.is_lost === false && val.label === item.leadstatus){
  //     //       countWorking += parseInt(item.count);
  //     //     }
  //     //   })
  //     //   })
     

  //     setWorkingLeads(countWorking);
      

  //   }

  //   init();
  // }, []);

  const createLead = () => {
    navigate(`/leads/e`);
  };


  const submitEvents = async (eventRec) => {
    //console.log('eventRec home submit', eventRec)
    setShowEventModel(false);
  }

  return (
    <Main>
      <>
      <Helmet>
          <title>{LIST_TAB_TITLE.Home}</title>
        </Helmet>
      </>
    <Container>

      <Row>
      <Col lg={3}>
          <Link to="/projects" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #198754' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#198754' }}></i>
                <i className="fa-solid fa-diagram-project fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
                {/* <i class="fa-solid fa-diagram-project"></i> */}
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">TOTAL PROJECTS</h6>
                <h3 className='mb-0 d-inline '>{allProjects}</h3><Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col>
        {/* <Col lg={3}>
          <Link to="/leads" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #f7981c' }}> */}
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              {/* <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#f7981c' }}></i>
                <i className="fa-solid fa-bolt fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">NEW LEADS</h6>
                <h3 className='mb-0 d-inline '>{newLead}</h3><Badge bg="light" text="dark">Running</Badge>
              </div>
            </div>
          </Link>
        </Col> */}
        {/* <Col lg={3}>
          <Link to="/leads" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #FFD700' }}> */}
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              {/* <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#FFD700' }}></i>
                <i className="fa-solid fa-coins fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">WORKING LEADS</h6>
                <h3 className='mb-0 d-inline '>{workingLeads}</h3> <Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col> */}
         <Col lg={3}>
          <Link to="/projects" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #4798b5' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#4798b5' }}></i>
                <i className="fa-solid fa-money-bill-trend-up fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
                {/* <i class="fa-solid fa-money-bill-trend-up"></i> */}
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">EXPECTED COST</h6>
                <h3 className='mb-0 d-inline '>{parseFloat(totalExpectedCoast/100000).toFixed(2)}</h3><Badge bg="light" text="dark"> ₹ Lakh</Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/contacts" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #198754' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#198754' }}></i>
                <i className="fa-solid fa-user-tie fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">CUSTOMERS</h6>
                <h3 className='mb-0 d-inline '>{allContacts}</h3><Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/users" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center m-3" style={{ backgroundColor: 'white', borderLeft: '4px solid #4798b5' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span className="fa-stack fa-2x">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#4798b5' }}></i>
                <i className="fa-solid fa-arrow-trend-up fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">REVENUE</h6>
                <h3 className='mb-0 d-inline '>{parseFloat(totalBusiness/100000).toFixed(2)}</h3><Badge bg="light" text="dark"> ₹ Lakh</Badge>
              </div>
            </div>
          </Link>
        </Col>

        
      </Row>
      < Row className="">
        <Col lg={6} className="text-center">
          <Card className="" >
            <Card.Header >STAGE WISE REPORT</Card.Header>
            <Card.Body>
              <Card.Text>
                <div style={{ height: '510px' }}>
                  {/* <BarChart1 /> */}
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

        </Col>
        
        

        <Col lg={6} className="text-center">
          <Card className="" >
            <Card.Header >COST AND TIME DURATION REPORT</Card.Header>
            <Card.Body>
              <Card.Text>
                <div style={{ height: '510px' }}>
                  {/* <SplineLineChart /> */}
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

        </Col>
       
        < Row className="mt-5">
          {/* <Col lg={6} className="text-center">
            <Card  className="custom-card">
              <Card.Header >Stage Wise</Card.Header>
              <Card.Body >
                <Card.Text>
                  <div style={{ height: '510px' }}>
                    <PieChart />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
        
        
        <Col lg={6} className="text-center">
          <Card className="" >
            <Card.Header >STAFF LEADS PROGRESS</Card.Header>
            <Card.Body>
              <Card.Text>
                <div style={{ height: '510px' }}>
                {/* <GroupBarChart /> */}
                </div>
              </Card.Text>
            </Card.Body>
          </Card>

        </Col>


        <Col lg={6} className="mt-3">

          <div style={{ height: '350px' }}>

            <Card className="">
              <Card.Header style={{ backgroundColor: "#009ab6", borderBottom: "none", color: "#fff", textAlign: "center" }}>QUICK ACTIONS</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Text>
                      <div width>
                        <Button className=" btn-primary btn-sm my-2 btn-block" style={{ width: '80%' }} onClick={() => createLead(true)}> <i className="fa-solid fa-bolt"></i> NEW LEAD</Button>

                      </div>
                      <div>
                        <Button className=" btn-custom btn-sm btn-block" style={{ width: '80%' }} onClick={() => setShowEventModel(true)}> <i className="fa-solid fa-list-check" ></i> NEW METINGS</Button>

                      </div>
                    </Card.Text>
                  </Col>
                  <Col>
                    <center>
                      <div ><i className="fa-regular fa-calendar-days fa-2x" style={{ color: "#E73F59" }}></i></div>
                      <p className='pt-2'><i>You have <Link to={"/meetings/today"}>
                      <b>{todayMeetings?.length}</b>
        </Link> meetings today</i></p>
                    </center>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-muted">Followup with <a href="/leads" style={{ textDecoration: "none" }}>these</a> leads today</Card.Footer>
            </Card>
          </div>
        </Col>
      </Row>
      {showEventModel && (
        <EventEdit
          show={showEventModel}
          onHide={() => setShowEventModel(false)}
          parentid="abc"
          //eventRec={event}
          table="user"
          submitEvents={submitEvents}
        />
      )} 
      </Row>
    </Container>
    </Main>

  )
}

export default Home
