import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Nav,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import authApi from "../../../api/authApi";
import jwt_decode from "jwt-decode";
import "./Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import WhiteObjectApi from "../../../api/WhiteObjectApi";
import Notification from "../common/Notification";

const Header = ({ socket ,leadCreationIndicator }) => {
  console.log("socket", socket);
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({});
  const [notificationCount, setNotificationCount] = useState(2);
  const [refreshNotification, setRefreshNotification] = useState(new Date());
  const [showBellAlert, setShowBellAlert] = useState(false);
  const [unreadMsgCount, setUnreadMsgCount] = useState(0);
  const [messages, setMessages] = useState(0);
  console.log("showBellAlert",showBellAlert)
  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const notifications = JSON.parse(
        localStorage.getItem("notifications") || "[]"
      );
      if (notifications.length > 0) {
        navigate("/maintanance");
      }
      try {
        const decodedToken = jwt_decode(token);
        setUserInfo(decodedToken);
      } catch (error) {
        console.log(error);
      }
    }
     // Added by shivani 13 aug
  if (location.pathname === "/dashboardhome" && unreadMsgCount != 0) {
    playBellSound();
  }
  messageList(false, false); //Added by shivani 13 aug
    console.log("socket =>", socket)
    // messageList(false); //Added by shivani 9 aug
    socket?.on("greetings", (data) => {
      console.log("greetings mesg received ", data);
      messageList(false, true);   // Added by shivani 13 aug

    })
    
  }, [socket]);

  useEffect(() => { messageList(true, false)}, [unreadMsgCount]);   // Added by shivani 13 aug

  const formatMessage = (mystr) => {
    let oldStr = mystr;
    mystr = mystr.substring(1);
    var arry = mystr.split(" @");
    var returnArr = [];

    for (var a of arry) {
      var obj = {};
      var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));
      obj[`username`] = value;
      obj[`id`] = key;
      if (key) returnArr.push(obj);
    }

    for (var a of returnArr) {
      oldStr = oldStr.replace(
        `@[${a.username}](${a.id})`,
        `<a href="/users/${a.id}">${a.username}</a>`
      );
    }

    return oldStr;
  };

  const messageList = (open,play) => {
    console.log("task")
    async function init() {
      let tasks = await WhiteObjectApi.fetchUnreadMessages();
      console.log("tasks.length",tasks)
      if (tasks && tasks?.length > 0) {
        tasks.forEach((msg) => {
          msg.description = formatMessage(msg.description);
        });
        

        const readMessages = tasks.filter((msg) => msg.status === "New"); //Added by shivani 9 aug
        setUnreadMsgCount(readMessages.length); //Added by shivani 9 aug

        setMessages(tasks);
        setShowNotification(open);
        console.log("task3333",open)
        setShowBellAlert(!open);
         // Added by shivani 13 aug
         if(play){
          playBellSound();
        }
      } else {
        setMessages([]);
        setUnreadMsgCount(0);
        setShowNotification(open);
      }
    }
    init();
  };

  const playBellSound = () => {
    const audio = new Audio("/notification_sound.mp3");
    audio.play();
  };

  const logout = () => {
    authApi.logout();
    navigate("/");
  };

  const [showNotification, setShowNotification] = useState(false);

  const closeNotification = () => {
    setShowNotification(false);
  };

  const openNotificationArea = () => {
    if (!showNotification) messageList(true, true);   // Added by shivani 13 aug
    else setShowNotification(false);

    setShowBellAlert(false);
  };

  return (
    <Navbar expand="lg" className="bg-body-header" variant="light">
      <Container fluid>
        <Navbar.Brand>
          <img
            src="https://i.ibb.co/W6mXkS4/white-object-logo.png"
            alt="White Object"
            style={{
              width: "230px",
              height: "53px",
              marginRight: "151px",
              filter: "unset",
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ marginLeft: "-172px" }}>
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => handleNavigation("/dashboardhome")}
              className={`${
                location.pathname.includes("/dashboardhome") ? "active" : ""
              }`}
              id="home"
            >
              Home
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigation("/contacts")}
              className={`${
                location.pathname.includes("/contacts") ? "active" : ""
              } `}
              id="contacts"
            >
              Contacts
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigation("/leads")}
              className={`${
                location.pathname.includes("/leads") ? "active" : ""
              }`}
              id="leads"
            >
              Leads
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigation("/products")}
              className={`${
                location.pathname.includes("/products") ? "active" : ""
              } `}
              id="products"
            >
              Products
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigation("/projects")}
              className={`${
                location.pathname.includes("/projects") ? "active" : ""
              } `}
              id="projects"
            >
              Projects
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigation("/users")}
              className={`${
                location.pathname.includes("/users") ? "active" : ""
              } `}
              id="users"
            >
              Users
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigation("/projectdashboard")}
              className={`${
                location.pathname.includes("/projectdashboard") ? "active" : ""
              } `}
              id="projectdashboard"
            >
              Dashboard
            </Nav.Link>
            <Nav.Link
              onClick={() => handleNavigation("/reports")}
              className={`${
                location.pathname.includes("/reports") ? "active" : ""
              } `}
              id="reports"
            >
              Reports
            </Nav.Link>

            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                id="dropdown-basic"
                className="mx-3"
                style={{
                  fontSize: "18px", 
                  padding: "10px 15px", 
                  lineHeight: "1.5em",
                }}
              >
                {/* <i className="fa fa-angle-down"></i> */}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleNavigation("/archives")}
                  className={`${
                    location.pathname.includes("/archives") ? "active" : ""
                  } `}
                  id="archives"
                >
                  Archives
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleNavigation("/backup")}
                  className={`${
                    location.pathname.includes("/backup") ? "active" : ""
                  } `}
                  id="blue"
                >
                  Backup
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => handleNavigation("/repairprojects")}
                  className={`${
                    location.pathname.includes("/repairprojects")
                      ? "active"
                      : ""
                  } `}
                  id="blue" //
                >
                  Repair Ticket
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <Nav className="ms-auto">
            <div className="d-flex align-items-center">
              <Nav.Link
                className="m-2 p-0"
                style={{ fontSize: ".9rem" }}
                onClick={() => handleNavigation("/myprofile")}
              >
                <img
                  src="https://www.w3schools.com/bootstrap4/img_avatar3.png"
                  className="rounded-circle"
                  style={{ height: "30px" }}
                  alt=""
                />
                <Badge
                  style={{ fontSize: ".9rem", color: "#1b1313" }}
                  bg="#244c7e"
                >
                  {userInfo.username}
                </Badge>
                <Badge
                  bg="secondary"
                  style={{ fontSize: "10px !important",position: "relative"}}
                >
                  {userInfo.userrole === "SUPER_ADMIN"
                    ? "SUPER ADMIN"
                    : userInfo.userrole === "ADMIN"
                    ? "Office User"
                    : userInfo.userrole === "USER"
                    ? "Factory User"
                    : userInfo.userrole}
                </Badge>
              </Nav.Link>
              {/* Added by shivani 9 aug start */}
              <div className="icon-wrapper" style={{ position: "relative" }}>
                <img
                  src="https://i.ibb.co/qj9rj0X/notifcation-icon.jpg"
                  alt=""
                  onClick={openNotificationArea}
                  style={{
                    cursor: "pointer",
                    fontSize: "1.5rem",
                    width: "52px",
                    mixBlendMode: "hard-light",
                    color: "black",
                  }}
                />
                {(
                  <span className="notify-number">{unreadMsgCount}</span>
                )}
              </div>
        {/* Added by shivani 9 aug end */}
              {showNotification && (
                <Notification
                  notifications={messages}
                  closeNotification={closeNotification}
                  refreshNotification={refreshNotification}
                  messageList={messageList}
                />
              )}
              <Button
                onClick={() => logout()}
                className="btn btn-dark mx-2 btn-light"
                style={{
                  color: "white",
                  fontSize: ".9rem",
                  marginLeft: "10px",
                  marginRight: "100px",
                  marginTop: "-5px",
                  marginBottom: "-1rem",
                }}
              >
                <i className="fa-solid fa-right-from-bracket" />
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
