import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table, Form } from "react-bootstrap";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from "react-bootstrap/Modal";

import moment from "moment";
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";

const CourseRegistrationList = ({ filterDateData }) => {
  console.log("Lead Table", filterDateData);
  const [body, setBody] = useState([]);
  const [tempBody, setTempBody] = useState([]);
  const [showGenerateReceiptModal, setShowGenerateReceiptModal] =
    useState(false);

  useEffect(() => {
    init(filterDateData);
  }, [filterDateData]);

  async function init() {
    if (filterDateData && filterDateData.month && filterDateData.year) {
      const admissions = await WhiteObjectApi.fetchLeadNotConverted(filterDateData);

      if (admissions) {
        setBody(admissions);
      } else {
        setBody([]);
      }
    }
  }

  const header = [
    { title: "Lead Name", prop: "leadname" },
    { title: "Phone", prop: "phone" },
    // {
    //   title: "Student Name",
    //   prop: "studentname",
    //   isFilterable: true,
    //   isSortable: true,
    // },
    //{ title: "Course Name", prop: "coursename" },
    // { title: "Status", prop: "status", isFilterable: true, isSortable: true },
    { title: "Owner Name", prop: "ownername" },
    // {
    //   title: 'Actions', prop: 'id', cell: (row) => (
    //     <Button
    //     className="btn-sm"
    //     variant="primary"
    //     onClick={() => ShowChequeDetails(row)}
    //   >
    //     Get Details
    //   </Button>
    //   )
    // }
  ];

  // const labels = {
  //   beforeSelect: " ",
  // };

  //eslint-disable-next-line

  const closeModalDetails = () => {
    setShowGenerateReceiptModal(false);
  };
  return (
    // <Main>
    <div>
      <Row className="g-0">
        <Col lg={12} className="p-lg-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 2,
                  options: [5, 10, 15, 20],
                },
              }}
              sortProps={{
                initialState: {
                  sort: "leadname",
                  order: "desc",
                },
              }}
            >
              <Table striped className="data-table">
                <TableHeader />

                <TableBody />
              </Table>

              {body.length > 2 && <Pagination />}
            </DatatableWrapper>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
        </Col>
        <Col lg={2}></Col>
      </Row>
    </div>
  );
};
export default CourseRegistrationList;
