import React, { useState, useEffect, useCallback } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Alert
} from "react-bootstrap";
import { Shimmer } from "react-shimmer";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import jwt_decode from "jwt-decode";
import PubSub from "pubsub-js";

const LeadView = (props) => {
  const location = useLocation();
  const [lead, setLead] = useState(props.parent ? props.parent : {});
  const [files, setFiles] = useState([]);
  const [selectedVisitType, setSelectedVisitType] = useState("");
  const [selectUser, setSelectUser] = useState([]);
  const [option, setOption] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [alert, setAlert] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    latitude: null,
    longitude: null,
    address: "",
  });
  const [locationRecord, setLocationRecord] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem("token")));
    fetchLead();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 3000);
    }
  }, [alert]);

  const fetchLead = () => {
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
    }
  };

  const fetchUsers = async () => {
    try {
      const fetchUser = await WhiteObjectApi.fetchUsers();
      const usr = fetchUser.map((item) => ({
        value: item.id,
        label: item.username,
      }));
      setSelectUser(usr);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles(selectedFiles);
  };

  const handleRoleChange = (e) => {
    setOption(e);
  };

  const checkRequredFields = () => {
    if (files.length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (event, checkIn) => {
    event.preventDefault();
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`files${i}`, files[i]);
    }
    formData.append("folder_name", "Check-In-Documents");
    try {
      const result = await WhiteObjectApi.createFile(lead.id, formData);
      if (result) {
        const locate = window.navigator && window.navigator.geolocation;
        if (locate) {
          locate.getCurrentPosition(
            async (position) => {
              if (checkIn) {
                try {
                  const res = await WhiteObjectApi.createCheckInRecord({
                    logindatetime: new Date(),
                    loginlattitude: position.coords.latitude.toString(),
                    loginlongitude: position.coords.longitude.toString(),
                    remarks,
                    location: locationDetails.address,
                    parentid: option ? option.value : lead.lead_id,
                    visittype: selectedVisitType,
                    project_id: lead.id
                  });
                  if (res) {
                    PubSub.publish("RECORD_SAVED_TOAST", {
                      title: "Record Saved",
                      message: "You have been Successfully Checked In",
                    });
                    props.submitCheckIn();
                  } else {
                    setAlert({ message: "Something Went Wrong.", type: "danger" });
                  }
                } catch (error) {
                  console.error("Error creating check-in record:", error);
                  setAlert({ message: "Something Went Wrong.", type: "danger" });
                }
              } else {
                try {
                  const res = await WhiteObjectApi.handlCheckOut({
                    ...locationRecord,
                    logoutdatetime: new Date(),
                    logoutlattitude: position.coords.latitude.toString(),
                    logoutlongitude: position.coords.longitude.toString(),
                    remarks,
                  });
                  if (res) {
                    setAlert({
                      message: "You have been Successfully Checked out.",
                      type: "success",
                    });
                  } else {
                    setAlert({ message: "Something Went Wrong.", type: "danger" });
                  }
                } catch (error) {
                  console.error("Error handling check-out:", error);
                  setAlert({ message: "Something Went Wrong.", type: "danger" });
                }
              }
            },
            (error) => {
              console.log("error in location -> ", error);
            }
          );
        }
      }
    } catch (error) {
      console.error("File upload failed", error);
    }
  };

  const myMap = useCallback(() => {
    setSpinner(true);
    navigator.geolocation.getCurrentPosition((position) => {
      setSpinner(false);
      const currentCoords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      setLocationDetails((prev) => ({
        ...prev,
        latitude: currentCoords.lat,
        longitude: currentCoords.lng,
      }));
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: currentCoords }, (results, status) => {
        if (status === "OK" && results[0]) {
          const formattedAddress = results[0].formatted_address;
          setLocationDetails((prev) => ({
            ...prev,
            address: formattedAddress.replace(/,?\s?[A-Z0-9]{4}\+[A-Z0-9]{3}/, ''),
          }));
        } else {
          console.error("Geocode was not successful for the following reason:", status);
        }
        const map = new window.google.maps.Map(document.getElementById("googleMap"), {
          zoom: 15,
          center: currentCoords,
        });
        const marker = new window.google.maps.Marker({ position: currentCoords });
        marker.setMap(map);
      });
    });
  }, []);

  useEffect(() => {
    if (!window.google) {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDtztwOW6bufAlbBWfO1k5FdZTkpUP_gmI&callback=initMap`;
      googleMapScript.async = true;
      googleMapScript.defer = true;
      window.initMap = myMap;
      document.body.appendChild(googleMapScript);
    } else {
      myMap();
    }
  }, [myMap]);

  const handleCheckIn = () => {
    setLocationRecord({});
  };

  const handleremarkChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleAddressChange = (e) => {
    const updatedAddress = e.target.value.replace(/,?\s?[A-Z0-9]{4}\+[A-Z0-9]{3}/, '');
    setLocationDetails({ ...locationDetails, address: updatedAddress });
  };

  const handleRadioChange = (event) => {
    setSelectedVisitType(event.target.value);
  };

  const handleCloseModal = () => {
    // props.setModalShowLocationHistory(false);
    // props.onHide();
    console.log("Prpss",props.onHide)
  };

  return (
    <Modal {...props} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Check In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Row>
            <Col lg={8}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Select Files</Form.Label>
                <Form.Control type="file" required multiple onChange={handleFileChange} />
                <Form.Control.Feedback type="invalid">
                  Please select files
                </Form.Control.Feedback>
                <p style={{ color: "red" }} className="mt-2">
                  ***All uploaded documents will be placed in the Check-In-Document folder***
                </p>
              </Form.Group>

              <Form.Group controlId="formVisitType" className="mt-3">
                <Form.Label>Visit Type</Form.Label>
                <div className="d-flex">
                  <Form.Check
                    type="radio"
                    label="ShowRoom Visit"
                    name="visitType"
                    id="showroomVisit"
                    className="me-3"
                    value="Showroom Visit"
                    onChange={handleRadioChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Site Survey"
                    name="visitType"
                    id="siteSurvey"
                    value="Site Survey"
                    onChange={handleRadioChange}
                  />
                </div>
              </Form.Group>
              {selectedVisitType === 'Site Survey' && (
                // <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label mt-2"
                    htmlFor="formBasicFirstName"
                  >
                    Assigned Staff User
                  </Form.Label>
                  <Select
                    required
                    value={option}
                    className="custom-select username"
                    onChange={(e) => handleRoleChange(e)}
                    options={selectUser}
                  ></Select>
                  {/* added by shivani ends */}
                </Form.Group>
              // </Col>
              )

              }

              <Form.Group controlId="formLocation" className="mt-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  value={locationDetails.address}
                  onChange={handleAddressChange}
                  placeholder="Enter address"
                />
              </Form.Group>

              <Form.Group controlId="formRemarks" className="mt-3">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Remarks"
                  value={remarks}
                  onChange={handleremarkChange}
                />
              </Form.Group>
            </Col>

            <Col lg={4} className="mt-3">
              {spinner && <Shimmer width={300} height={300} />}
              <div id="googleMap" style={{ width: "100%", height: "300px" }}></div>
            </Col>
          </Row>
          {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button> */}
            <Button
              variant="primary"
              type="submit"
              onClick={(e) => handleSubmit(e, true)}
              onSubmit={(e) => handleCheckIn(e, true)}
            >
              Check-In
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LeadView;
