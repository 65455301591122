import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "./EnquiryForm.css"; // Ensure this file exists for custom styling
import WhiteObjectApi from "../../api/WhiteObjectApi";

const LeadEdit = () => {
  const [validated, setValidated] = useState(false);
  const [timeSlot, setTimeSlot] = useState({});
  const [typeOfComplaint, setTypeOfComplaint] = useState({});
  const [enquiryData, setEnquiryData] = useState({});
  const [locationFetched, setLocationFetched] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [enquiryFormHide, setEnquiryFormHide] = useState(true);
  const [latitudev1, setLatitudev1] = useState(null);
  const [longitudev1, setLongitudev1] = useState(null);
  const [addressv1, setAddressv1] = useState(null);
  const [files, setFiles] = useState([]);
  const [locationLink, setLocationLink] = useState(""); // Added state for location link
  const [errors, setErrors] = useState({}); // Added by shivani 7 aug s

  // const handleChange = (e) => {
  //   setEnquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  // };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let temp = { ...enquiryData, [name]: value };
  
    // Check if the field being changed is 'invoice_number'
    if (name === 'invoice_number') {
      if (value) {
        try {
          // Call the API to check if there are any projects with this invoice number
          let result = await WhiteObjectApi.fetchProjectsListServiceForm(value);
          console.log("Result Projects Invoices", result);
  
          if (result && result.length > 0) {
            // Handle the case where records are found
            console.log("Records found:", result);
  
            // Update the description field with leadname and project name
            const descriptions = result.map(project => 
              `Lead Name: ${project.leadname}, Project Name: ${project.project_name}`
            ).join('; ');
  
            // Update the lead state with the new description
            temp.description = descriptions;
          } else {
            // Handle the case where no records are found
            console.log("No records found for this invoice number.");
  
            // Update the lead state with 'No Invoice data found' in the description
            temp.description = 'No Invoice data found';
          }
        } catch (error) {
          console.error("Error fetching projects:", error);
          temp.description = '';
        }
      } else {
        // Handle the case where the invoice number field is cleared
        temp.description = '';
      }
    }
  
    // Update the state with the new field value
    setEnquiryData(temp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Added by shivani 7 aug start
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    
    // Added by shivani 7 aug end
    enquiryData.time_slot = timeSlot;
    enquiryData.type_of_complaint = typeOfComplaint;
    enquiryData.repair_status = "Ticket Created";
    enquiryData.createdbyid = "59b02983-536c-4899-9c2e-86c7145b29b9";
    enquiryData.lastmodifiedbyid = "59b02983-536c-4899-9c2e-86c7145b29b9";
    enquiryData.projectmanagerid = '59b02983-536c-4899-9c2e-86c7145b29b9';
    const result = await WhiteObjectApi.createRepairTicketExternal(enquiryData);

    if (result) {
     console.log("Log Executed By Client Side");
    let formData = new FormData();
  
    // Append files
    for (let i = 0; i < files.length; i++) {
      formData.append(`files${i}`, files[i]);
    }
    
    // Append other form data
    // formData.append("description", fileDescription);    
    formData.append("folder_name", "RepairTicket");
    formData.append("createdbyid", '59b02983-536c-4899-9c2e-86c7145b29b9');
    formData.append("lastmodifiedbyid", '59b02983-536c-4899-9c2e-86c7145b29b9');
        
    if(result.id){
      console.log("Second Log Excuted")
      const resultFile = await WhiteObjectApi.createFileService(result.id, formData);
      if(resultFile){
        setEnquiryFormHide(false);
        setSuccessMessage(true);
      }
    }
      
      
    } else {
      alert("Submission failed. Please try again.");
    }
  };

  const handleRadioChange = (event) => {
    setTypeOfComplaint(event.target.value);
  };

  const handleTimeSlotChange = (event) => {
    setTimeSlot(event.target.value);
  };

  // Added by shivani 7 aug start
  const checkValidation = () => {
    let formErrors = {};
    if (!enquiryData.firstname) formErrors.firstname = "First Name is required";
    if (!enquiryData.lastname) formErrors.lastname = "Last Name is required";
    if (!enquiryData.email) formErrors.email = "Email is required";
    if (!enquiryData.phone) formErrors.phone = "Phone is required";
    if (!enquiryData.address) formErrors.address = "Complete Address is required";
    if (files.length === 0) formErrors.files = "Please select at least one file";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  // Added by shivani 7 aug end
  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const locationLink = `https://www.google.com/maps/?q=${latitude},${longitude}`;
          
          // Log the received coordinates and link for debugging
          console.log("Latitude: ", latitude);
          console.log("Longitude: ", longitude);
          console.log("Google Maps Link: ", locationLink);
  
          setEnquiryData({ ...enquiryData, location_link: locationLink });
          setLocationLink(locationLink);
          setLocationFetched(true);
        },
        (error) => {
          // Log the error for debugging
          console.error("Error fetching location: ", error);
          setLocationFetched(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLocationFetched(false);
    }
  };
  

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google || !window.google.maps) {
        const existingScript = document.querySelector(
          'script[src*="maps.googleapis.com"]'
        );
        if (!existingScript) {
          const googleMapScript = document.createElement("script");
          googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDtztwOW6bufAlbBWfO1k5FdZTkpUP_gmI&callback=initMap`;
          googleMapScript.async = true;
          googleMapScript.defer = true;
          window.document.body.appendChild(googleMapScript);
        }
      } else {
        window.initMap();
      }
    };

    window.initMap = () => {
      myMapFunction();
    };

    loadGoogleMapsScript();
  }, [latitudev1, longitudev1, addressv1]);

  const myMapFunction = async () => {
    if (!window.google) {
      console.error("Google Maps API is not loaded.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();

    if (latitudev1 && longitudev1) {
      const location = new window.google.maps.LatLng(latitudev1, longitudev1);
      const mapProp = {
        center: location,
        zoom: 14,
      };

      const map = new window.google.maps.Map(
        document.getElementById("googleMapaddress"),
        mapProp
      );

      const marker = new window.google.maps.Marker({
        map,
        position: location,
        title: addressv1,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: addressv1,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    } else if (addressv1) {
      geocoder.geocode({ address: addressv1 }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          setLatitudev1(location.lat());
          setLongitudev1(location.lng());

          const mapProp = {
            center: location,
            zoom: 14,
          };

          const map = new window.google.maps.Map(
            document.getElementById("googleMapaddress"),
            mapProp
          );

          const marker = new window.google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          setAddressv1(results[0].formatted_address);
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    } else {
      console.error("Address is not provided.");
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles(selectedFiles);
    console.log("Selected Files:", selectedFiles);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      {enquiryFormHide && (
        <Row className="w-100">
          <Col lg={8} className="mx-auto">
            <Form
              className="mt-3"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <img
                    src="whiteObjectFormLogo.png"
                    alt="Logo"
                    className="header-img-right"
                  />
                  <h5 className="mb-0 text-center flex-grow-1">Repair Ticket Form</h5>
                  <img
                    src="naveen.png"
                    alt="Naveen"
                    className="header-img-left"
                  />
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicName" className="mt-2">
                        <Form.Label className="form-view-label">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstname"
                          placeholder="Enter Your Firstname"
                          onChange={handleChange}
                          required // Added by shivani 7 aug 
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicName" className="mt-2">
                        <Form.Label className="form-view-label">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="lastname"
                          placeholder="Enter Your Last Name"
                          onChange={handleChange}
                          required // Added by shivani 7 aug s
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicEmail" className="mt-2">
                        <Form.Label className="form-view-label">
                          Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter Your Email"
                          onChange={handleChange}
                          required // Added by shivani 7 aug s
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicPhone" className="mt-2">
                        <Form.Label className="form-view-label">
                          Phone
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          name="phone"
                          placeholder="Enter Your Phone Number"
                          onChange={handleChange}
                          required // Added by shivani 7 aug s
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicInvoice" className="mt-2">
                        <Form.Label className="form-view-label">
                          Invoice No.
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="invoice_number"
                          placeholder="Enter Your Invoice Number"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group
                        controlId="formBasicSiteVisitDate"
                        className="mt-2"
                      >
                        <Form.Label className="form-view-label">
                          Site Visit Date
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="site_visit_date"
                          placeholder="Select Site Visit Date"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicDetails" className="mt-2">
                        <Form.Label className="form-view-label">
                          Other Details
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="other_details"
                          placeholder="Enter Additional Details"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicWindows" className="mt-2">
                        <Form.Label className="form-view-label">
                          No. Of Windows
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="qty_windows"
                          placeholder="Enter No. Of Windows"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    
                   
                    <Col lg={6}>
                      <Form.Group controlId="formBasicAddress" className="mt-2">
                        <Form.Label className="form-view-label">
                          Complete Address
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Enter Your Full Address"
                          onChange={handleChange}
                          required // Added by shivani 7 aug s
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Button
                        variant="success"
                        type="button"
                        onClick={handleGetLocation}
                      >
                        Get Location
                      </Button>
                      {locationFetched && locationLink && (
                        <div className="mt-2">
                          <a href={locationLink} target="_blank" rel="noopener noreferrer">
                            View Location on Google Maps
                          </a>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                 
                 
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group
                        controlId="formBasicComplaintType"
                        className="mt-2"
                      >
                        <Form.Label className="form-view-label">
                          Type of Complaint
                        </Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            label="Glass Broken"
                            name="type_of_complaint"
                            value="Glass Broken"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Hardware"
                            name="type_of_complaint"
                            value="Hardware"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Alteration"
                            name="type_of_complaint"
                            value="Alteration"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Working"
                            name="type_of_complaint"
                            value="Working"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Wiremesh"
                            name="type_of_complaint"
                            value="Wiremesh"
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Others"
                            name="type_of_complaint"
                            value="Others"
                            onChange={handleRadioChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        controlId="formBasicTimeSlot"
                        className="mt-2"
                      >
                        <Form.Label className="form-view-label">
                          Time Slot Of Visit
                        </Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            label="9 am to 12 noon"
                            name="time_slot"
                            value="9 am to 12 noon"
                            onChange={handleTimeSlotChange}
                          />
                          <Form.Check
                            type="radio"
                            label="12 noon to 3 pm"
                            name="time_slot"
                            value="12 noon to 3 pm"
                            onChange={handleTimeSlotChange}
                          />
                          <Form.Check
                            type="radio"
                            label="3 pm to 6 pm"
                            name="time_slot"
                            value="3 pm to 6 pm"
                            onChange={handleTimeSlotChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Specific"
                            name="time_slot"
                            value="Specific"
                            onChange={handleTimeSlotChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    {timeSlot === "Specific" && (
                      <Col lg={6}>
                        <Form.Group
                          controlId="formBasicSpecificTime"
                          className="mt-2"
                        >
                          <Form.Label className="form-view-label">
                            Specific Time
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="specific_time"
                            placeholder="Enter Specific Time"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                  )}
                  <Col lg={6}>
                  <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Select Files</Form.Label>
                      <Form.Control
                      required //Aded  by shivani 7 aug
                        type="file"
                        multiple
                        onChange={handleFileChange}
                      />
                    </Form.Group>
                  </Col>
                    
                  </Row>
                  
                  <Row>
                    <Col lg={6}>
                      <a
                      target="_blank" //Added by shivani 7 aug
                        href="https://www.saps.in/tapmecards/whiteobject/naveen.pdf"
                        className="mt-2"
                      >
                        Details White Object
                      </a>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Button
                    className="d-flex justify-content-end align-items-end float-end" //Added by shivani 6 aug bug 74

                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Card className="text-center mt-3">
          <Card.Body>
            <div className="d-flex justify-content-center mt-3">
              <div className="blink green-tick">✔️</div>
            </div>
            <h5>
              Thank you for reaching out. Your Service Details has been
              submitted, and our team will contact you soon.
            </h5>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default LeadEdit;