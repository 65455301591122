/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PubSub from "pubsub-js";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import CityState from "../../constants/CityState.json";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from "../../constants/CONSTANT";

import jwt_decode from "jwt-decode";
import Helmet from "react-helmet";
import Main from "../layout/Main";

const LeadEdit = () => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [optionContact, setOptionContact] = useState({});
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedLeadSource, setSelectedLeadSource] = useState({});
  const [selectStatus, setSelectStatus] = useState({});
  const [selectIndustry, setSelectIndustry] = useState({});
  const [selectSalutation, setSalutation] = useState({});
  const [lostReason, setLostReason] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [name, setName] = useState("");
  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [option, setoption] = useState();
  const [leadStatusArray, setleadStatusArray] = useState(
    constants.Repair_CATEGORY_VALUES
  );
  const [showModal, setShowModal] = useState(false);

  //const [rating,setRating]=useState([{}]);
  const industryValue = [
    { value: "", label: "--None--" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Apparel", label: "Apparel" },
    { value: "Banking", label: "Banking" },
    { value: "Chemicals", label: "Chemicals" },
    { value: "Communications", label: "Communications" },
  ];
  const salutationValue = [
    { value: "", label: "--None--" },
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Dr.", label: "Dr." },
    { value: "Mrs..", label: "Mrs.." },
    { value: "Prof.", label: "Prof.." },
  ];
  const roleValue = [
    { value: "", label: "--None--" },
    { value: "ADMIN", label: "Admin" },
    { value: "USER", label: "User" },
    // {value:"Dr.",label:"Dr."},
    // {value:"Mrs..",label:"Mrs.."},
    // {value:"Prof.",label:"Prof.."},
  ];
  const leadSource = [
    { value: "Web", label: "Web" },
    { value: "Phone Enquiry", label: "Phone Enquiry" },
    { value: "Partner Referral", label: "Partner Referral" },
    { value: "Purchased List", label: "Purchased List" },
    { value: "Other", label: "Other" },
  ];
  const leadStatus = leadStatusArray;
  const [show, setShow] = React.useState(false);
  const [lead, setLead] = useState(location.state ? location.state : {});

  // ////console.log(:)
  let userInfo;
  useEffect(() => {
    if (!lead.id) {
      setShowModal(true);
    }
    let userInfo = jwt_decode(localStorage.getItem("token"));
    ////console.log('userInfo', userInfo);

    if (location?.state) {
      ////console.log(location.state)
      // let salutation = salutationValue.filter(salutation => salutation.value === location.state.salutation)
      let source = leadSource.filter(
        (source) => source.value === location.state.leadsource
      );
      let industry = industryValue.filter(
        (industry) => industry.value === location.state.industry
      );
      let status = leadStatus.filter(
        (status) => status.value === location.state.leadstatus
      );

      ////console.log('source',leadStatus)
      // setSalutation(salutation[0]);
      setSelectedLeadSource(source[0]);
      setSelectIndustry(industry[0]);
      setSelectStatus(status[0]);
      setLostReason(location.state.iswon === false);

      ////console.log('Lead Data ', location.state)
      if (lead.id) {
        let temp = {};
        temp.value = location.state.projectmanagerid;
        temp.label = location.state.projectmanagername;
        setoption(temp);
      } else {
        let temp = {};
        temp.value = userInfo.id;
        temp.label = userInfo.username;
        setoption(temp);
        lead.ownername = userInfo.username;
        lead.projectmanagerid = userInfo.id;
      }
    } else {
      let temp = {};
      temp.value = userInfo.id;
      temp.label = userInfo.username;
      setoption(temp);
      lead.ownername = userInfo.username;
      lead.projectmanagerid = userInfo.id;
    }

    async function init() {
      const fetchUser = await WhiteObjectApi.fetchUsers();
      ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
      let usr = [];
      fetchUser.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.username;
        ////console.log(" obj.label >" + obj.label)
        usr.push(obj);
      });
      setSelectUser(usr);

      //Fetch Contacts for Repair Ticket Selection.
      const fetchContacts = await WhiteObjectApi.fetchContacts();
      ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
      let con = [];
      fetchContacts.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.firstname + " " + item.lastname;
        ////console.log(" obj.label >" + obj.label)
        con.push(obj);
      });
      setOptionContact(con);

      let st = [];
      CityState.forEach((item) => {
        let obj = {
          value: item.state,
          label: item.state,
        };
        st.push(obj);
      });

      // Remove duplicates using a Set
      const uniqueStates = [...new Set(st.map((item) => item.value))];

      // Create an array of objects with unique values
      const uniqueObjects = uniqueStates.map((state) => ({
        value: state,
        label: state,
      }));

      // Sort the array in ascending order
      uniqueObjects.sort((a, b) => a.label.localeCompare(b.label));

      // Set the state
      setState(uniqueObjects);
      //console.log('st:', st);
      setState(st);
    }

    init();
  }, []);

  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setLead({ ...lead, state: e.value });
  };

  const handleSelectListChange = (value, name) => {
    ////console.log('contact:', lead);
    setLead({ ...lead, [name]: value.value });
    setSelectedCity(value.value);
  };

  //Access all the value input values
  const handleChange = async (e) => {
    const { name, value } = e.target;
    let temp = { ...lead, [name]: value };

    if (name === "invoice_number") {
      if (value) {
        try {
          let result = await WhiteObjectApi.fetchProjectsList(value);
          console.log("Result Projects Invoices", result);

          if (result && result.length > 0) {
            console.log("Records found:", result);
            const descriptions = result
              .map(
                (project) =>
                  `Lead Name: ${project.leadname}, Project Name: ${project.project_name}`
              )
              .join("; ");
            temp.description = descriptions;
          } else {
            console.log("No records found for this invoice number.");
            temp.description = "No Invoice data found";
          }
        } catch (error) {
          console.error("Error fetching projects:", error);
          temp.description = "Error fetching project data";
        }
      } else {
        temp.description = "";
      }
    }
    if (name === "repair_lead_name") {
      if (value) {
        try {
          let result2 = await WhiteObjectApi.fetchRepairLeadByLeadName(value);
          console.log("Result Projects Invoices", result2);

          if (result2 && result2.length > 0) {
            console.log("Records found:", result2);

            // Update the description field with leadname and project name
            const descriptions = result2
              .map((lead) => `Lead Name: ${lead.leadname},`)
              .join("; ");

            // Update the lead state with the new description
            temp.description = descriptions;
          } else {
            // Handle the case where no records are found
            console.log("No records found for this Lead Name.");

            // Update the lead state with 'No Invoice data found' in the description
            temp.description = "No Invoice data found";
          }
        } catch (error) {
          console.error("Error fetching projects:", error);
          temp.description = "Error fetching project data";
        }
      } else {
        // Handle the case where the invoice number field is cleared
        temp.description = "";
      }
    }

    // Update the state with the new field value
    setLead(temp);
  };

  const handleRoleChange = (e) => {
    ////console.log('event', e)
    setoption(e);
    setLead({
      ...lead,
      projectmanagerid: e.value,
      projectmanagername: e.label,
    });
  };

  const handleContact = (e) => {
    setSelectedContact(e);
    console.log("E", e);
    setLead({ ...lead, contactid: e.value });
  };

  console.log("Lead Data on Contact Select", lead);
  const handleSubmit = async (e) => {
    ////console.log('handle submit call', lead)
    e.preventDefault();

    if (checkRequredFields()) {
      setValidated(true);
      return;
    }

    //========= Logic to perform Create or Edit ======
    let result = {};
    let resultContact = {};
    let iswon = null;
    let archiveRecord = {};
    let conact_create = {};

    if (lead.id) {
      result = await WhiteObjectApi.saveRepairTicket(lead);

      ////console.log("result ===>", result);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/repairprojects/${lead.id}`, { state: lead });
      }
    } else {
      console.log("Lead Object for Save", lead);

      // Fetch contact by phone number
      let resultContact = await WhiteObjectApi.fetchContactByPhone(lead.phone);

      if (!resultContact) {
        // If no contact is found, create a new contact
        let contactCreate = await WhiteObjectApi.createContact(lead);
        console.log("contactCreate",contactCreate)

        // Set the lead's contact ID with the newly created contact ID
        resultContact = lead.contactid = contactCreate.id;

        console.log("New Contact Created", contactCreate);

        // Optionally, you can set selected contact with a label and value
        // let obj = {label: contactCreate.firstname, value: contactCreate.id};
        // setSelectedContact(obj);
      } else {
        // If contact is found, assign the contact ID to the lead
        resultContact = lead.contactid = resultContact[0].id;
        console.log("Contact Found", resultContact);
      }

      // Set lead to not be archived
      lead.isarchive = false;

      // Create the repair ticket using the lead data
      lead.contactid = resultContact;
      console.log("Contactid",lead.contactid)
      let result = await WhiteObjectApi.createRepairTicket(lead);

      console.log("Repair Ticket Created", result);

      // if (result) {

      //   console.log("Log Excuted for Api Responce",resultContact);
      //   // lead.lead_id = result.id;
      //   // resultContact = await WhiteObjectApi.createContact(lead);
      // }
      ////console.log('result', result)
      if (result || conact_create) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/repairprojects/${result.id}`, { state: result });
      }
    }
  };

  //Close Modal Function
  const handleCloseModal = () => setShowModal(false);

  const checkRequredFields = () => {
    if (!option) {
      ////console.log('if call');
      setShow(true);
      document.querySelector(".username").style.border = "1px solid red";
    }
    if (
      lead.firstname &&
      lead.firstname.trim() !== "" &&
      lead.lastname &&
      lead.lastname.trim() !== "" &&
      lead.phone &&
      lead.repair_status
    ) {
      return false;
    }
    return true;
  };

  const handleCancel = () => {
    navigate("/repairprojects/", { state: lead });
  };

  console.log("Leads Data", lead);
  return (
    <Main>
      <>
        <Helmet>
          <title>{LIST_TAB_TITLE.LeadEdit}</title>
        </Helmet>
      </>
      <Container className="view-form">
        <Row className="view-form">
          <Col></Col>
          <Col lg={8} className="pb-1 pt-2">
            <Link className="nav-link" to="/leads">
              Home <i className="fa-solid fa-chevron-right"></i>{" "}
              <div style={{ color: "#23468c", display: "inline" }}>
                Repair Ticket
              </div>
            </Link>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col lg={8}>
            <Form
              className="mt-3"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Row className="view-form-header align-items-center">
                <Col lg={9}>
                  {name === "" ? (
                    <h6>Create Repair Ticket</h6>
                  ) : (
                    <h6> Edit Repair Ticket</h6>
                  )}

                  <h5>{name}</h5>
                </Col>
                <Col lg={3} style={{ textAlign: "right" }}>
                  <Button className="btn-sm mx-2" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="ibs-edit-form mb-4" lg={12}>
                <Alert
                  variant="danger"
                  show={show}
                  className="error-alert my-3 mx-2"
                >
                  Please Select Assign Staff
                </Alert>

                <Col lg={2}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Salutation
                    </Form.Label>
                    <Form.Select
                      aria-label="Select Salutation"
                      name="salutation"
                      value={lead.salutation}
                      onChange={handleChange}
                    >
                      <option value="">---Select---</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Prof.">Prof.</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      required={true}
                      placeholder="Enter First Name"
                      value={lead.firstname}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide First Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLastName"
                    >
                      Last Name
                    </Form.Label>
                    <Form.Control
                      required={true}
                      type="text"
                      name="lastname"
                      placeholder="Enter LastName"
                      value={lead.lastname}
                      //defaultValue="Ali"
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter LastName.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Phone
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="phone"
                      required
                      placeholder="Enter Phone"
                      value={lead.phone}
                      //defaultValue={['Asif']}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Phone.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} className="">
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Contact Name
                    </Form.Label>
                    <Select
                      placeholder="Contact Name... "
                      onChange={handleContact}
                      options={optionContact}
                      value={selectedContact}
                    ></Select>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Qty of Window/Door
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="qty_windows"
                      required
                      placeholder="Enter Qty"
                      value={lead.qty_windows}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Qty.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Invoice Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="invoice_number"
                      required
                      placeholder="Enter Invoice Number"
                      value={lead.invoice_number}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Invoice Number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Repair Total Amount
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="repair_total_amount"
                      value={lead.repair_total_amount}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Repair Total Amount.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Site Visit Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="site_visit_date"
                      placeholder="Select Site Visit Date"
                      value={
                        lead.site_visit_date
                          ? moment(lead.site_visit_date).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide Site Visit Date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Other Details
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="other_details"
                      placeholder="Enter Other Details"
                      value={lead.other_details}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Other Details.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      value={lead.email}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Assigned User
                    </Form.Label>
                    <Select
                      required
                      value={option}
                      className="custom-select username"
                      onChange={(e) => handleRoleChange(e)}
                      options={selectUser}

                      //value={selectSalutation}
                    ></Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide Select-Role.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFees"
                    >
                      Status
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Enter Status"
                      name="repair_status"
                      onChange={handleChange}
                      value={lead.repair_status}
                    >
                      <option value="">--Select-Status--</option>
                      {leadStatusArray.map((item, index) => (
                        <option value={item.label} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      Enter Status.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFees"
                    >
                      Repair Lead Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="repair_lead_name"
                      placeholder="Enter Repair Lead Name"
                      value={lead.repair_lead_name}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFees"
                    >
                      Description
                    </Form.Label>
                    <Form.Control
                      disabled
                      as="textarea"
                      name="description"
                      placeholder="Enter Description"
                      value={lead.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={12} className="section-header">
                  ADDRESS INFORMATION
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Location Link
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="location_link"
                      placeholder="Enter Location Link"
                      value={lead.location_link}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Complete Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="street"
                      placeholder="Enter Complete Address"
                      value={lead.street}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Complete Address.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Zip / PostalCode
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="zipcode"
                      placeholder="Enter PostalCode"
                      value={lead.zipcode}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter PostalCode.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      State
                    </Form.Label>
                    <Select
                      placeholder="State"
                      defaultValue={{ label: lead.state, value: lead.state }}
                      onChange={handleState}
                      options={state}
                    ></Select>
                    <Form.Control.Feedback type="invalid">
                      Enter State.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      City
                    </Form.Label>
                    <Select
                      options={cities}
                      placeholder="Enter City"
                      onChange={(e) => {
                        handleSelectListChange(e, "city");
                      }}
                      name="city"
                      defaultValue={{ label: lead.city, value: lead.city }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter City.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} className="pb-3">
                  <Form.Group>
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Country
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      placeholder="Enter Country"
                      value={lead.country}
                      onChange={(e) => handleChange(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Country.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col className="mt-5"></Col>
              </Row>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Main>
  );
};
export default LeadEdit;
