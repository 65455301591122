/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PubSub from "pubsub-js";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import CityState from "../../constants/CityState.json";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from "../../constants/CONSTANT";
import jwt_decode from "jwt-decode";
import Helmet from "react-helmet";
import Main from "../layout/Main";
import { LeadEdit } from "../Lead";

const ProductEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState(location.state ? location.state : {});
  const [productCategoryArray, setProductCategoryArray] = useState(JSON.parse(localStorage.getItem("product_category")));
  const [optionContact, setOptionContact] = useState({});
  const [selectedContact, setSelectedContact] = useState({});
  const [producSubcategoryArray, setProductSubcategoryArray] = useState(
    JSON.parse(localStorage.getItem("product_subcategory"))
  );
  const [source, setSource] = useState(product.source || "");

  const productCategory = productCategoryArray;
  const productSubcategory = producSubcategoryArray;

  useEffect(() => {
    console.log("product",product);
    if (product.id) {
      let temp = {};
    }
    if (location?.state) {
      let prodCategory = productCategory.filter(
        (status) => status.value === location.state.productCategory
      );
    }

    async function init(){
      const fetchContacts = await WhiteObjectApi.fetchContactsSupllier();

    if (fetchContacts) {
      let contactOptions = fetchContacts.map((item) => ({
        value: item.id,
        label: item.firstname + " " + item.lastname,
        contact: item
      }));
      
      setOptionContact(contactOptions);
      if (location?.state) {
        let acc = contactOptions.filter(
          (acc) => acc.value === location.state.contact_id
        );
        setSelectedContact(acc);
      }
      // Added by shivani start
        if (product.supplier_id) {
          let supplier = contactOptions.find(
            (contact) => contact.value === product.supplier_id
          );
          setSelectedContact(supplier);
        }
      // Added by shivani end
    } else {
      setOptionContact([]);
    }
    }
    init()
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });

    if (name === "source") {
      setSource(value);
    }
  };
 // Added validation by Shivani mehra starts
  const checkRequredFields = () => {
    if (
      product.product_name &&
      product.product_name.trim() !== "" &&
      product.product_name &&
      product.product_name.trim() !== "" &&
      product.source &&
      product.source.trim() !== "" &&
      product.source &&
      product.source.trim() !== ""
    ) {
      return false;
    }
    return true;
  };

  
  // Added validation by Shivani mehra ends

  const handleSubmit = async (e) => {
    e.preventDefault();
      // Added validation by Shivani mehra starts
    if (checkRequredFields()) {
      setValidated(true);
      return;
    }
      // Added validation by Shivani mehra ends
    //========= Logic to perform Create or Edit ======
    let result = {};
    if (product.id) {
      result = await WhiteObjectApi.saveProduct(product);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/products/${product.id}`, { state: product });
      }
    } else {
      result = await WhiteObjectApi.createProduct(product);
      if (result != null) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/products/${result.id}`, { state: result });
      }
    }
  };

  const handleCancel = () => {
    if (product.id) {
      navigate("/products/" + product.id, { state: product });
    } else {
      navigate(`/products`);
    }
  };

  //Contact Handler Change
  const handleContact = (e) => {
    console.log("Event on Contact",e.contact);
    setSelectedContact(e); 
    const selectedContactDetails = e.contact;
    if(!product.id){
        
        console.log("Staate =>",product);
        setProduct({
            ...product,
            supplier_id: e.value,
            external_source_dob : selectedContactDetails.date_of_birth,
            external_source_contact: selectedContactDetails.phone,
            external_source_email: selectedContactDetails.email,
            external_source_whatsapp_number : selectedContact.whatsapp_no,
            external_source_address: selectedContactDetails.street + " " + selectedContactDetails.city + " " + selectedContactDetails.state + " " + selectedContactDetails.country,
        });
      
    }else{
      setProduct({ ...product, supplier_id: e.value });
    }       
};
  return (
    <Main>
      <>
        <Helmet>
          <title>{LIST_TAB_TITLE.ProductEdit}</title>
        </Helmet>
      </>
      <Container className="view-form">
        <Row>
          <Col></Col>
          <Col lg={8} className="pb-1 pt-2">
            <Link className="nav-link" to="/products">
              Home <i className="fa-solid fa-chevron-right"></i>{" "}
              <div style={{ color: "#23468c", display: "inline" }}>
                Products
              </div>
            </Link>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
          <Col lg={8}>
            <Form
              className="mt-3"
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
            >
              <Row className="view-form-header">
                <Col lg={8}>
                  {product.id ? <h5>Edit Product</h5> : <h5>Create Product</h5>}
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button className="btn-sm mx-2" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              
              <Row className="ibs-edit-form pb-3 ">
                <Row lg={12}>
                {product.id && (
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicTitle">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicTitle"
                    >
                      Product Code
                    </Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      name="product_code"
                      placeholder="Enter Product Code"
                      value={product.product_code}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              )}
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Product Name
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="product_name"
                        placeholder="Enter Product Name"
                        value={product.product_name}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Product Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mx-3">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Source
                      </Form.Label>
                      <Form.Select
                        aria-label="Select source"
                        name="source"
                        value={product.source}
                        onChange={handleChange}
                        required // Added by shivani
                      >
                        <option value="">--Select--</option>
                        <option value="internal">Internal</option>
                        <option value="external">External</option>
                      </Form.Select>
                      {/*  Added by shivani start */}
                      <Form.Control.Feedback type="invalid">
                        Please provide source.
                      </Form.Control.Feedback>
                      {/*  Added by shivani end */}
                    </Form.Group>
                  </Col>
                  {source === "external" && (
                    <>
                     {/* <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            External Source Details
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="external_source_details"
                            placeholder="Enter External Source Details"
                            value={product.external_source_details}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            External Source Product
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="external_source_product"
                            placeholder="Enter External Source Product"
                            value={product.external_source_product}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col> */}
                    <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicTitle">
                      <Form.Label
                        className="form-view-label mt-2"
                        htmlFor="formBasicFirstName"
                      >
                        Supplier Name
                      </Form.Label>
                      <Select
                        placeholder="Supplier Name... "
                        onChange={handleContact}
                        options={optionContact}
                        value={selectedContact}
                      ></Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide Supplier Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            External Source Address
                          </Form.Label>
                          <Form.Control
                            // as="textarea"
                            type="text"
                            name="external_source_address"
                            placeholder="Enter External Address"
                            value={product.external_source_address}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            External Source Contact Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="external_source_contact"
                            placeholder="Enter External Source Contact"
                            value={product.external_source_contact}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            External Source Whatsapp Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="external_source_whatsapp_number"
                            placeholder="Enter External Source Whatapp Number"
                            value={product.external_source_whatsapp_number}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>  
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            Source Order Date
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="source_order_date"
                            placeholder="Enter Source Order Date"
                            value={
                              product.source_order_date
                                ? moment(product.source_order_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""
                            }
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>  
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            Expected Date of Arrival
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="expected_date_of_arrival"
                            placeholder="Enter Expected Date of Arrival"
                            value={
                              product.expected_date_of_arrival
                                ? moment(product.expected_date_of_arrival).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""
                            }
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6}>  
                        <Form.Group className="mx-3" controlId="formBasicTitle">
                          <Form.Label
                            className="form-view-label"
                            htmlFor="formBasicTitle"
                          >
                            PI Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="pi_number"
                            placeholder="Enter PI Number"
                            value={
                              product.pi_number}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                     
                    </>
                  )}
                  <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicLastName">
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Remark
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="product_description"
                        placeholder="Enter Product Remark"
                        value={product.product_description}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Main>
  );
};

export default ProductEdit;