/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PubSub from "pubsub-js";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import CityState from "../../constants/CityState.json";
import { LIST_TAB_TITLE } from "../../constants/CONSTANT";
import * as constants from "../../constants/CONSTANT";
import jwt_decode from "jwt-decode";
import Helmet from "react-helmet";
import Main from "../layout/Main";

const ProjectEdit = () => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [optionContact, setOptionContact] = useState({});
  const [selectedContact, setSelectedContact] = useState({});
  const [selectStatus, setSelectStatus] = useState({});
  const [selectUser, setSelectUser] = useState({});
  const [name, setName] = useState("");
  const [optionLead, setOptionLead] = useState({});
  const [selectedLead, setSelectedLead] = useState("");
  const [option, setoption] = useState();
  const [productOptions, setOptionProduct] = useState();
  const [quotationError, setErrorMessage] = useState("");
  const [factoryUserError, setErrorMessageFactory] = useState("");

  const [projectStatusArray, setProjectStatusArray] = useState(
    constants.Project_Stage_Status
  );
  const [total_amount_product, setProductTotalAmount] = useState({});
  const [productsLineItems, setProjectProductLineitems] = useState({});
  const [projectLineItem, setProjectLineItem] = useState({});
  const [currentProductIndex, setCurrentProductIndex] = useState(null); //Added by shivani 8 aug
  const [showExternalProductModel, setShowExternalProductModel] =
    useState(false);
  const initialProduct = {
    project_id: "",
    product_id: "",
    product_code: "",
    product_price: "",
    product_qty: "",
    status: "",
    remarks: "",
    size: "",
    glass: "",
    hardware: "",
    accessories: "",
    iron: "",
    pvc_profile: "",
    pi_number_glass: "",
    pi_number_hardware: "",
    pi_number_accessories: "",
    pi_number_iron: "",
    pi_number_pvc_profile: "",
    date_of_delivery_glass: "",
    date_of_delivery_hardware: "",
    date_of_delivery_accessories: "",
    date_of_delivery_iron: "",
    date_of_delivery_pvc_profile: "",
    source: "",
  };
  const [products, setProducts] = useState([]);
  const [selectedProductsIds, setSelectedProductsIds] = useState(
    Array.from({ length: products.length }, () => "")
  );

  const projectStatus = projectStatusArray;
  const [show, setShow] = React.useState(false);
  const [lead, setLead] = useState(location.state ? location.state : {});
  const [imageData, setImageData] = useState();
  const [isValid, setIsValid] = useState(true);
  const [showValidationMessage, setShowValidationMessage] = useState(false); //Added by shivani

  let userInfo;
  useEffect(() => {
    setProductTotalAmount(location?.state?.project_total_amount);
    let userInfo = jwt_decode(localStorage.getItem("token"));

    if (location?.state) {
      let status = projectStatus.filter(
        (status) => status.value === location.state.project_status
      );

      setSelectStatus(status[0]);

      if (lead.id) {
        let temp = {};
        temp.value = location.state.projectmanagerid;
        temp.label = location.state.projectmanagername;
        setoption(temp);
      } else {
        let temp = {};
        temp.value = userInfo.id;
        temp.label = userInfo.username;
        setoption(temp);
        lead.ownername = userInfo.username;
        lead.projectmanagerid = userInfo.id;
      }
    } else {
      let temp = {};
      temp.value = userInfo.id;
      temp.label = userInfo.username;
      setoption(temp);
      lead.ownername = userInfo.username;
      lead.projectmanagerid = userInfo.id;
    }

    async function init() {
      const fetchUser = await WhiteObjectApi.fetchUsers();
      ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
      let usr = [];
      fetchUser.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.username;
        ////console.log(" obj.label >" + obj.label)
        usr.push(obj);
      });

      setSelectUser(usr);
      if (selectUser) {
        // setLead({...lead ,projectmanagerid : usr.id})
        // console.log("Option On Pro",selectUser)
      }
      // const fetchContacts = await WhiteObjectApi.fetchContactsLeadByid();
      const fetchLeads = await WhiteObjectApi.fetchLead(); //fetchLeadConverted() // this lead is fetching Converted Leads Only.
      const fetchProducts = await WhiteObjectApi.fetchProductPriceBooks();

      console.log("fetchProducts", fetchProducts);

      // Product list filling here for dropdown values (ADD ROW functionlity).
      if (fetchProducts) {
        let productOptions = fetchProducts.map((item) => ({
          value: item.id,
          label: item.product_name,
          product_code: item.product_code,
          product_price: item.amount,
          source: item.source,
        }));
        setOptionProduct(productOptions);
      } else {
        setOptionProduct({});
      }

      if (fetchLeads) {
        // Map the filtered leads to options
        let leadOptions = fetchLeads.map((item) => ({
          value: item.id,
          label: item.firstname + " " + item.lastname,
        }));
        setOptionLead(leadOptions);

        if (location?.state) {
          let acc = leadOptions.filter(
            (acc) => acc.value === location.state.lead_id
          );
          setSelectedLead(acc);

          if (acc.length > 0) {
            fetchContacts(acc[0].value); // to Show Selected Contact (On Edit Time)
          }
        }
        // For InCase User is Comming from lead table.
        const fromTable = location?.state?.fromTable || "";
        const leadView = location?.state?.leadView || null;
        // const contactView = location?.state?.contactView || null; //Added by shivani 7 aug

        if (fromTable === "leadtable" && leadView) {
          let acc = leadOptions.filter((acc) => acc.value === leadView);
          // console.log("accaccacc", acc);
          setSelectedLead(acc);
          setLead((prevLead) => ({
            ...prevLead,
            lead_id: leadView,
            // contact_id: contactView,
          }));
          if (acc[0].value && acc[0]) {
            //Added by shivani 7 aug
            await fetchContacts(acc[0].value);
          } // to Show All Related Contact (When User Comming from Lead Page) // to Show All Related Contact (When User Comming from Lead Page)
        }

        //End Here
      } else {
        setOptionLead({});
      }
      const id = location?.state?.id || null;
      if (id) {
        // console.log("PROJECT_ID", location.state.id);
        const projects = await WhiteObjectApi.fetchProjectProductLineItemById(
          id
        );
        if (projects) {
          setProjectProductLineitems(projects);
          setProducts(
            projects.map((project) => ({
              id: project.id,
              project_id: project.project_id,
              product_id: project.product_id,
              product_code_line_item: project.product_code_line_item,
              product_price: project.product_price,
              product_qty: project.product_qty,
              size: project.size,
              total_amount_line_item: project.total_amount_line_item,
              status: project.status,
              remarks: project.remarks,
              glass: project.glass,
              hardware: project.hardware,
              accessories: project.accessories,
              iron: project.iron,
              pvc_profile: project.pvc_profile,
              pi_number_glass: project.pi_number_glass,
              pi_number_hardware: project.pi_number_hardware,
              pi_number_accessories: project.pi_number_accessories,
              pi_number_iron: project.pi_number_iron,
              pi_number_pvc_profile: project.pi_number_pvc_profile,
              date_of_delivery_glass: project.date_of_delivery_glass,
              date_of_delivery_hardware: project.date_of_delivery_hardware,
              date_of_delivery_accessories:
              project.date_of_delivery_accessories,
              date_of_delivery_iron: project.date_of_delivery_iron,
              date_of_delivery_pvc_profile:
              project.date_of_delivery_pvc_profile,
              source: project.source,
            }))
          );
          // handleProduct(projects.id)
          // calculateTotalAmount(projects.map((project) => project.product_price * project.product_qty));
        } else {
          setProjectProductLineitems([]);
        }
      }
    }

    init();
    // console.log("lead.project_total_amount",lead.project_total_amount)
    //eslint-disable-next-line
  }, []);

  console.log("Project Edit =>", location.state);
  //Access all the value input values
  // const handleChange = (e) => {
  //   let temp = { ...lead };
  //   if(e.target.name === 'tax_amount'){
  //     let updatedProjectAmount = lead.tax_amount + lead.expense_amount
  //     setProductTotalAmount(updatedProjectAmount)
  //     console.log("Project Total",updatedProjectAmount);
  //   }
  //   setLead({ ...lead, [e.target.name]: e.target.value });
  // };
  const handleChange = (e) => {
    let temp = { ...lead };
    const { name, value } = e.target;

    const parsedValue = parseFloat(value);
    temp[name] = isNaN(parsedValue) ? value : parsedValue;

    if (name === "expected_date_delivery" || name === "quote_approval_date") {
      temp[name] = value ? new Date(value).toISOString() : null;
    }

    if (name === "tax_amount" || name === "expense_amount") {
      temp[name] = value === "" ? null : parseFloat(value);
      calculateTotalAmount(products, temp.tax_amount, temp.expense_amount);
    }
    //Added for Auto Assign Factory User if Trasfer To Production.
    if (temp.project_status === "Transfer To Production") {
      let obj = {label : "Factory User",value : "77bcaed4-d9b6-4da3-b04c-f726727944d7"}
      setoption(obj);
      temp.projectmanagerid = obj.value;
    }

    setLead(temp);
  };

  const handleRoleChange = (e) => {
    setoption(e);
    console.log("Role Change Function", e);
    setLead({ ...lead, projectmanagerid: e.value });
  };

  //Contact Handler Change
  const handleContact = (e) => {
    setSelectedContact(e);
    setLead({ ...lead, contact_id: e.value });
  };

  //Lead Handler Change
  const handleLead = async (e) => {
    setSelectedLead(e);
    setLead({ ...lead, lead_id: e.value });
    // console.log("SelectedLead##", e);
    // Fetch contacts related to the selected lead
    fetchContacts(e.value);
  };

  const fetchContacts = async (leadId) => {
    // Fetch contacts related to the selected lead
    // console.log("Selected Lead ON API", leadId);
    const fetchContacts = await WhiteObjectApi.fetchContactsLeadByid(leadId.value ? leadId.value : leadId);

    if (fetchContacts) {
      let contactOptions = fetchContacts.map((item) => ({
        value: item.id,
        label: item.firstname + " " + item.lastname,
      }));
      setOptionContact(contactOptions);
      if (location?.state) {
        let acc = contactOptions.filter(
          (acc) => acc.value === location.state.contact_id
        );
        setSelectedContact(acc);
      }
    } else {
      setOptionContact([]);
    }
  };

  const handleProduct = async (index, selectedOption) => {
    const newProducts = [...products];

    // Reset state for selected product
    newProducts[index].product_id = selectedOption.value;
    newProducts[index].product_code_line_item = selectedOption.product_code;
    newProducts[index].product_price = "";
    newProducts[index].product_qty = "";
    newProducts[index].status = "";
    newProducts[index].size = "";
    newProducts[index].source = selectedOption.source;
    newProducts[index].total_amount_line_item = "";

    setProducts(newProducts);
    calculateTotalAmount(newProducts); // Recalculate total project amount

    const newSelectedProductsIds = [...selectedProductsIds];
    newSelectedProductsIds[index] = selectedOption.value;
    setSelectedProductsIds(newSelectedProductsIds);
    setIsValid(!!selectedOption);

    if (selectedOption) {
      let productImageResult = await WhiteObjectApi.fetchPrimaryImages(selectedOption.value);

      let productImageData = await WhiteObjectApi.downloadFiles(productImageResult.id,"ProductImages");

      let reader = new FileReader();
      reader.readAsDataURL(productImageData);
      reader.onloadend = () => {
        let base64data = reader.result;

        const newProductsWithImages = [...products];
        newProductsWithImages[index].imageData = base64data;
        setProducts(newProductsWithImages);
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkRequiredFields()) {
      setValidated(true);
      return;
    }

    
    if (lead.project_status === "Project Complete") {
      if(lead.id){
        const fetchPayment = await WhiteObjectApi.fetchProjectPayments(lead.id);
          if(fetchPayment){
            const pendingAmount = lead.project_total_amount - fetchPayment.paidamount;
            if(pendingAmount){
              console.log("pendingAmount",pendingAmount);
              PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: "Project Cannot be Completed Due to Pending Payments of ₹ " + parseInt(pendingAmount)  });
              return
            }
          }
      }
    }

    lead.tax_amount = lead.tax_amount
      ? parseFloat(lead.tax_amount).toFixed(2)
      : "0.00";
    lead.expense_amount = lead.expense_amount
      ? parseFloat(lead.expense_amount).toFixed(2)
      : "0.00";

    //========= Logic to perform Create or Edit ======
    let result = {};
    let insertLineItems = {};
    let UpdateItems = {};
    let archiveRecord = {};
    //Lead Status Logic : This method is using for As Project and Lead assing to staff Member when Project Status Change then Lead Status Should Be Project.
    if (lead.project_status === "Transfer To Production") {
      const lead_result = await WhiteObjectApi.fetchLeadById(lead.lead_id);
      console.log("Lead ka Status",lead_result.leadstatus);
      if(lead_result.leadstatus !== "Project"){
        PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Save Error', message: "Cannot Transfer to Production: Lead status must be 'Project' to Proceed"  });
        return
      }
    }
    // Lead Status Logic End here.
    
    if (
        lead.project_status === "Transfer To Production" ||
        lead.project_status === "Production InProgress" ||
        lead.project_status === "Production Completed" ||
        lead.project_status === "Part Dispatched" ||
        lead.project_status === "Dispatched" ||
        lead.project_status === "Installation Started" ||
        lead.project_status === "Installation Completed" ||
        lead.project_status === "Fabricator Gets a Signed Slip" ||
        lead.project_status === "Payment Received" ||
        lead.project_status === "Project Complete") {
          let lead_assing_id = {};
          // lead.projectmanagerid =
          lead_assing_id.ownerid = lead.projectmanagerid;
          //lead.projectmanagerid;
          lead_assing_id.id = lead.lead_id;
          const lead_result = await WhiteObjectApi.saveLead(lead_assing_id);
  }
    //End Here.
    if (lead.id) {
      if (lead.project_status === "Project Complete") {
        lead.isarchive = true;
      }
      lead.project_total_amount = total_amount_product; // Added by shivani

      result = await WhiteObjectApi.saveProject(lead);
      if (result) {
        // console.log("Products:", products);
        // console.log("Selected Products IDs:", selectedProductsIds);
        if (lead.project_status === "Project Complete") {
          lead.project_id = lead.id;
          lead.project_lead_id = lead.lead_id;
          lead.project_contact_id = lead.contact_id;
          lead.lead_id = null;
          archiveRecord = await WhiteObjectApi.createArchive(lead);
        }
        if (archiveRecord) {
          const updatedProducts = products.map((product, index) => ({
            ...product,
            project_id: lead.id,
            product_id: selectedProductsIds[index]
              ? selectedProductsIds[index]
              : product.product_id,
              sr_no: index + 1,
          }));

          for (const updatedProduct of updatedProducts) {
            // console.log("Updating Product:", updatedProduct);
            if (!updatedProduct.hasOwnProperty("id")) {
              //insertLineItems.push(updatedProduct);
              console.log("UpdatedProductINSERT", updatedProduct);
              WhiteObjectApi.createProjectProductLineItem(updatedProduct);
            }
            if (updatedProduct.hasOwnProperty("id")) {
              //UpdateItems.push(updatedProduct);
              // console.log("Inside  Update");
              await WhiteObjectApi.saveProjectProductLineItem(updatedProduct);
            }
          }
        }
      }

      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/projects/${lead.id}`, { state: lead });
      }
    } else {
      // console.log("Create Time Project", lead);
      lead.isarchive = false;
      lead.project_total_amount = total_amount_product;
      result = await WhiteObjectApi.createProject(lead);
      if (result && result.id) {
        const updatedProducts = products.map((product, index) => ({
          ...product,
          project_id: result.id,
          product_id: selectedProductsIds[index],
          sr_no: index + 1,
        }));
        // console.log("selected ids", updatedProducts);
        console.log("Products Create", products);
        const lineItemResults = await Promise.all(
          updatedProducts.map((product) =>
            WhiteObjectApi.createProjectProductLineItem(product)
          )
        );

        if (lineItemResults && result) {
          PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Record Saved",
            message: "Record saved successfully",
          });
          navigate(`/projects/${result.id}`, { state: result });
        }
      }
    }
  };
  // Added validation by shivani
  const checkRequiredFields = () => {
    let checkRequired = false;

    if (!option) {
      setShow(true);
      checkRequired = true;
      setShowValidationMessage(true);
      document.querySelector(".username").style.border = "1px solid #dc3545";
    }
    // Added by shivani bug 70 1 july start
    if (!selectedContact || Object.keys(selectedContact).length === 0) {
      setShowValidationMessage(true);
      checkRequired = true;
    }
    if (!selectedLead || Object.keys(selectedContact).length === 0) {
      setShowValidationMessage(true);
      checkRequired = true;
    }
    // Added by shivani bug 70 1 july end
    const allProductsValid = products.every(
      (product) =>
        product.product_qty?.trim() &&
        product.size &&
        product.status?.trim() &&
        product.product_price?.trim() &&
        product.product_id?.trim()
    );

    if (!allProductsValid) {
      setShowValidationMessage(true);
      checkRequired = true;
    }

    if (!checkRequired) {
      const leadValid =
        lead.project_name?.trim() && lead.project_status?.trim();
      // lead.quote_approval_date?.trim() &&
      // lead.expected_date_delivery?.trim();
      checkRequired = !leadValid;
    }

    //Added By Farhan Khan on 04-Sep-2024 As per Client Need Custom Validation For Trasfer to Production.
    if (lead.project_status === "Transfer To Production" && lead.quote_approval_date === null) {
      checkRequired = true;
      setErrorMessage("Enter a Quote Approval Date");
    }

    // //Added By Farhan Khan on 04-Sep-2024 As per Client Need Custom Validation For Trasfer to Production.
    if (lead.project_status === "Advance Received" || lead.project_status === "Quote Sent" || 
         lead.project_status === "Quote Rejected" || 
         lead.project_status === "Quote Approved" ) {  
          if(option.label === "Factory User"){
            checkRequired = true;
            setErrorMessageFactory("Can't Assign Factory User to this status");
          }
    }

    return checkRequired;
  };

  const handleCancel = () => {
    navigate("/projects/", { state: lead });
  };

  /*  Product Add Row Code Starts here */

  // Function to handle adding a new product row
  const handleAddProduct = () => {
    // setProducts([
    //   ...products,
    //   {
    //     project_id: "",
    //     product_id: "",
    //     product_code: "",
    //     product_price: "",
    //     product_qty: "",
    //     status: "",
    //     remarks: "",
    //   },
    // ]);
    setProducts([...products, { ...initialProduct }]);
  };

  const handleRemoveProduct = async (index, id) => {
    if (id) {
      try {
        await WhiteObjectApi.deleteProjectProductLineItem(id);
        const updatedProducts = products.filter((_, i) => i !== index);
        setProducts(updatedProducts);
        calculateTotalAmount(updatedProducts);
      } catch (error) {
        console.error("Failed to delete the product line item", error);
      }
    } else {
      const updatedProducts = products.filter((_, i) => i !== index);
      setProducts(updatedProducts);
      calculateTotalAmount(updatedProducts);
    }
  };

  //Added by shivani 8 aug start
  const handleProductExternal = (index) => {
    setCurrentProductIndex(index);
    setShowExternalProductModel(true);
  };

  const handleExternalProductClose = () => {
    setShowExternalProductModel(false);
    setCurrentProductIndex(null);
  };
  //Added by shivani 8 aug end

  // Function to handle change in product inputs
  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const newProducts = [...products];
    newProducts[index][name] = value;

    if (name === "product_qty" || name === "product_price") {
      const qty = parseFloat(newProducts[index].product_qty) || 0;
      const price = parseFloat(newProducts[index].product_price) || 0;
      const totalAmount = qty * price;
      newProducts[index].total_amount_line_item = totalAmount.toFixed(2);
    }

    setProducts(newProducts);
    calculateTotalAmount(newProducts);
  };

  //  const calculateTotalAmount = (products) => {

  //   const total = products.reduce((sum, product) => {

  //     console.log("Sum",sum);
  //     console.log("Product CalFunction",product);
  //     const qty = parseFloat(product.product_qty) || 0;
  //     const price = parseFloat(product.product_price) || 0;
  //     const productTotal = qty * price;

  //     const expense = lead.expense_amount ? parseFloat(lead.expense_amount) : 0;
  //     const tax = lead.tax_amount ? parseFloat(lead.tax_amount) : 0;

  //     return sum + productTotal + (expense + tax);
  //   }, 0);
  //   setProductTotalAmount(total?.toFixed(2));
  // }; // 25-Aug-2024 Comment Added
  const calculateTotalAmount = (
    products,
    taxAmount = lead.tax_amount,
    expenseAmount = lead.expense_amount
  ) => {
    const productTotal = products.reduce((sum, product) => {
      const qty = parseFloat(product.product_qty) || 0;
      const price = parseFloat(product.product_price) || 0;
      return sum + qty * price;
    }, 0);

    const expense = expenseAmount ? parseFloat(expenseAmount) : 0;
    const tax = taxAmount ? parseFloat(taxAmount) : 0;

    const total = productTotal + expense + tax;

    setProductTotalAmount(total?.toFixed(2));
  };

  // const calculateTotalAmount = (products) => {

  //   const productTotal = products.reduce((sum, product) => {
  //     const qty = parseFloat(product.product_qty) || 0;
  //     const price = parseFloat(product.product_price) || 0;
  //     return sum + qty * price;
  //   }, 0);

  //   setProductTotalAmount(total.toFixed(2));
  //   console.log("Project Total", total.toFixed(2));
  // };

  console.log("ON Load Products", products);
  // End here
  console.log("Option Assing User", option);
  return (
    <div>
      <>
        <Helmet>
          <title>{LIST_TAB_TITLE.ProjectEdit}</title>
        </Helmet>
      </>
      <Container>
        <Card>
          <Row>
            <Col lg={11} className="mx-5">
              <Form
                className="mt-3"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Row className="view-form-header align-items-center">
                  <Col lg={9}>
                    {name === "" ? (
                      <h6>Create Project</h6>
                    ) : (
                      <h6>Edit Project</h6>
                    )}
                    <h5>{name}</h5>
                  </Col>
                  <Col lg={3} style={{ textAlign: "right" }}>
                    <Button
                      className="btn-sm mx-2"
                      variant="primary"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>

                <Row className="mb-4" lg={12}>
                  <Alert
                    variant="danger"
                    show={show}
                    className="error-alert my-3 mx-2"
                  >
                    Please Select Assign Staff
                  </Alert>
                  {lead.id && (
                    <Col lg={4}>
                      <Form.Group>
                        <Form.Label
                          className="form-view-label mt-2"
                          htmlFor="formBasicFirstName"
                        >
                          Project code
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="project_code"
                          // placeholder="Enter Total Amount"
                          value={lead.project_code}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label mt-2"
                        htmlFor="formBasicFirstName"
                      >
                        Project Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="project_name"
                        required={true}
                        placeholder="Enter Project Name"
                        className="custom-select username"
                        value={lead.project_name}
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide Project Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label mt-2"
                        htmlFor="formBasicFees"
                      >
                        Project Status
                      </Form.Label>
                      <Form.Select
                        required
                        aria-label="Select Project Status"
                        name="project_status"
                        className="custom-select username"
                        onChange={handleChange}
                        value={lead.project_status}
                      >
                        <option value="">--Select-Status--</option>
                        {projectStatusArray.map((item, index) => (
                          <option value={item.label} key={index}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">
                        Please provide Proejct Status.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label mt-2"
                        htmlFor="formBasicFirstName"
                      >
                        Lead Name
                      </Form.Label>
                      <Select
                        required
                        placeholder="Lead Name... "
                        className="custom-select username"
                        onChange={handleLead}
                        options={optionLead}
                        value={selectedLead}
                      ></Select>
                      {/* Added by shivani start */}
                      {/* Added by shivani start bug 70 1 july */}
                      {showValidationMessage && !selectedLead && (
                        <div className="invalid-feedback d-block">
                          Please provide a lead name
                        </div>
                      )}
                      {/* added by shivani ends bug 70 1 july */}
                      {/* added by shivani ends */}
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label mt-2"
                        htmlFor="formBasicFirstName"
                      >
                        Contact Name
                      </Form.Label>
                      <Select
                        required
                        disabled
                        placeholder="Contact Name... "
                        onChange={handleContact}
                        options={optionContact}
                        value={selectedContact}
                        isDisabled={!selectedLead} //Added by shivani
                        className="custom-select username" //Added by shivani
                        // isDisabled={Object.keys(optionContact).length === 0}
                      ></Select>
                      {/* Added by shivani start */}
                      {showValidationMessage &&
                        Object.keys(selectedContact).length === 0 && (
                          <div className="invalid-feedback d-block">
                            Please provide a contact name
                          </div>
                        )}
                      {/* added by shivani ends */}
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label
                        className="form-view-label mt-2"
                        htmlFor="formBasicFirstName"
                      >
                        Assigned Staff User
                      </Form.Label>
                      <Select
                        required
                        value={option}
                        className="custom-select username"
                        onChange={(e) => handleRoleChange(e)}
                        options={selectUser}
                      ></Select>

                        <p style={{ color: "red" }}>{factoryUserError}</p>
                      {/* Added by shivani start */}
                      {showValidationMessage && !option.value && (
                        <div className="invalid-feedback d-block">
                          Please provide a staff User name
                        </div>
                      )}
                      {/* added by shivani ends */}
                    </Form.Group>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Expected Delivery Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="expected_date_delivery"
                        placeholder="Enter Expected Delivery Date"
                        value={
                          lead.expected_date_delivery
                            ? moment(lead.expected_date_delivery).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={(e) => handleChange(e)}
                      />
                      {/* // Shivani start */}
                      <Form.Control.Feedback type="invalid">
                        Please provide Expected Delivery Date.
                      </Form.Control.Feedback>
                      {/* Shivani end */}
                    </Form.Group>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Quote Approval Date
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="quote_approval_date"
                        placeholder="Enter Quote Approval Date"
                        value={
                          lead.quote_approval_date
                            ? moment(lead.quote_approval_date).format(
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        onChange={(e) => handleChange(e)}
                      />
                      <p style={{ color: "red" }}>{quotationError}</p>
                      <Form.Control.Feedback type="invalid">
                        Please Provide Quote Approval Date.
                      </Form.Control.Feedback>
                      {/* Shivani end */}
                    </Form.Group>
                  </Col>
                  {/* <Col lg={4} className="mt-2">
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Expected Project Amount
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="expected_project_amount"
                        placeholder="Enter Expected Project Amount"
                        value={lead.expected_project_amount}
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Provide Expected Project Amount.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col> */}
                  <Col lg={4} className="mt-2">
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Invoice Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="invoice_number"
                        placeholder="Enter Invoice Number"
                        value={lead.invoice_number}
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Provide Sales Amount Project.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Taxes
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="tax_amount"
                        placeholder="Enter Taxes Amount"
                        value={lead.tax_amount}
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Provide Tax Amount Project.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Expense Amount
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="expense_amount"
                        placeholder="Enter Expenses Amount"
                        value={lead.expense_amount}
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Provide Expenses Amount Project.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <Form.Group>
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicFirstName"
                      >
                        Total Amount Project
                      </Form.Label>
                      <Form.Control
                        type="number"
                        disabled
                        name="project_total_amount"
                        Total
                        Amount
                        Project
                        // placeholder="Enter Total Amount"
                        value={
                          // lead.project_total_amount
                          //   ? parseInt(lead.project_total_amount)
                          //   : total_amount_product
                          total_amount_product
                        }
                        //}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Provide Total Amount.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="mt-5"></Col>
                </Row>

                {/* Product Rows */}

                <Container style={{ width: "100%" }} className="">
                  <Row
                    className="card-header"
                    style={{
                      padding: "4px",
                      backgroundColor: "#474d57",
                      color: "#fff",
                    }}
                  >
                    <Col className=" text-start my-2">
                      {" "}
                      <h5>Product Information</h5>
                    </Col>
                    <Col className="text-end">
                      <Button variant="light" onClick={handleAddProduct}>
                        Add Product
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    {products && (
                      <h2 style={{ fontSize: "11px", color: "red" }}>
                        **Yellow highlighted Products Are External Product**
                      </h2>
                    )}

                    <Col lg={12}>
                      {products.map((product, index) => (
                        <Card
                          key={index}
                          className="mb-2"
                          style={{
                            borderWidth:
                              product.source === "external" ? "medium" : "thin",
                            borderColor:
                              product.source === "external"
                                ? "#FFCC00"
                                : "#000000",
                            display: "flex",
                            flexDirection: "row", // Ensure all elements are in a row
                            flexWrap: "nowrap", // Prevent wrapping
                            alignItems: "center", // Vertically center the content
                            padding: "10px", // Optional: Add some padding for better spacing
                          }}
                        >
                          <Card.Body
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                marginRight: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              {index + 1}.
                            </div>
                            <Row
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Col lg={2}>
                                <Form.Label>Product Name</Form.Label>
                                <Form.Group className="select-container">
                                  <Select
                                    required
                                    isDisabled={!productOptions}
                                    name={`product_id_${index}`}
                                    value={
                                      Array.isArray(productOptions)
                                        ? productOptions.find(
                                            (option) =>
                                              option.value ===
                                              product.product_id
                                          )
                                        : null
                                    }
                                    onChange={(e) => handleProduct(index, e)}
                                    options={productOptions}
                                    className="custom-select username"
                                  />
                                  {showValidationMessage &&
                                    !products[index].product_id && (
                                      <div className="invalid-feedback d-block">
                                        Please provide a Product Name.
                                      </div>
                                    )}
                                </Form.Group>
                              </Col>

                              <Col lg={1}>
                                <Form.Group
                                  style={{ width: "87px", marginLeft: "-9px" }}
                                >
                                  <Form.Label>Product Code</Form.Label>
                                  <Form.Control
                                    disabled
                                    type="text"
                                    name="product_code_line_item"
                                    value={product.product_code_line_item}
                                    onChange={(e) =>
                                      handleProductChange(index, e)
                                    }
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={1}>
                                <Form.Group>
                                  <Form.Label>Price Per</Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    name="product_price"
                                    value={product.product_price}
                                    onChange={(e) =>
                                      handleProductChange(index, e)
                                    }
                                    min={0}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Required
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col lg={1}>
                                <Form.Group>
                                  <Form.Label>Sqr Ft</Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    name="product_qty"
                                    value={product.product_qty}
                                    onChange={(e) =>
                                      handleProductChange(index, e)
                                    }
                                    min={0}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Required
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col lg={1}>
                                <Form.Group>
                                  <Form.Label>Size</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    name="size"
                                    value={product.size}
                                    onChange={(e) =>
                                      handleProductChange(index, e)
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Required
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col lg={1}>
                                <Form.Group>
                                  <Form.Label>Total Amt</Form.Label>
                                  <Form.Control
                                    type="text"
                                    disabled
                                    name="total_amount_line_item"
                                    value={product.total_amount_line_item}
                                    onChange={(e) =>
                                      handleProductChange(index, e)
                                    }
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={2}>
                                <Form.Group>
                                  <Form.Label>Status</Form.Label>
                                  <Form.Select
                                    aria-label="Select Status"
                                    name="status"
                                    value={product.status}
                                    onChange={(e) =>
                                      handleProductChange(index, e)
                                    }
                                    required
                                  >
                                    <option value="">--Select--</option>
                                    <option value="ExternalProductOrdered">
                                      External Product Ordered
                                    </option>
                                    <option value="ExternalProductReceived">
                                      External Product Received
                                    </option>
                                    <option value="SiteNotReady">
                                      Site Not Ready
                                    </option>
                                    <option value="MaterialOrdered">
                                      Material Ordered
                                    </option>
                                    <option value="MaterialReceived">
                                      Material Received
                                    </option>
                                    <option value="WorkInProgress">
                                      Work In Progress
                                    </option>
                                    <option value="WorkCompleted">
                                      Work Completed
                                    </option>
                                    <option value="Dispatched">
                                      Dispatched
                                    </option>
                                    <option value="InStock">In Stock</option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Required
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>

                              <Col lg={1}>
                                {product.source === "internal" && (
                                  <Button
                                    className="btn btn-primary btn-sm mt-4"
                                    onClick={() => handleProductExternal(index)}
                                    style={{ width: "153%" }}
                                  >
                                    Add More Info
                                  </Button>
                                )}
                              </Col>

                              <Col lg={1}>
                                {product.imageData && (
                                  <img
                                    className="mx-4"
                                    src={product.imageData}
                                    alt=""
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </Col>

                              <Col lg={1} className="text-center">
                                <i
                                  className="fa-solid fa-trash-can mt-2"
                                  style={{
                                    color: "red",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleRemoveProduct(index, product.id)
                                  }
                                ></i>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Modal show={showExternalProductModel} size="xl">
                            <Modal.Header>
                              <Modal.Title>Additional Information</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row className="mx-2">
                                {/* Glass Row */}
                                <Col lg={12}>
                                  <Row>
                                    <Col lg={4}>
                                      <Form.Group>
                                        <Form.Label>Glass</Form.Label>
                                        <Form.Select
                                          aria-label="Select Glass Status"
                                          name="glass"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .glass
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        >
                                          <option value="">--Select--</option>
                                          <option value="ordered">
                                            Ordered
                                          </option>
                                          <option value="recieved">
                                            Received
                                          </option>
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          PI Number Glass
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="pi_number_glass"
                                          placeholder="Enter PI Number Glass"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .pi_number_glass
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          Delivery Date Glass
                                        </Form.Label>
                                        <Form.Control
                                          type="date"
                                          name="date_of_delivery_glass"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .date_of_delivery_glass
                                                ? moment(
                                                    products[
                                                      currentProductIndex
                                                    ].date_of_delivery_glass
                                                  ).format("YYYY-MM-DD")
                                                : ""
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Col>

                                {/* Hardware Row */}
                                <Col lg={12} className="mt-2">
                                  <Row>
                                    <Col lg={4}>
                                      <Form.Group>
                                        <Form.Label>Hardware</Form.Label>
                                        <Form.Select
                                          aria-label="Select Hardware Status"
                                          name="hardware"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .hardware
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        >
                                          <option value="">--Select--</option>
                                          <option value="ordered">
                                            Ordered
                                          </option>
                                          <option value="recieved">
                                            Received
                                          </option>
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          PI Number Hardware
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="pi_number_hardware"
                                          placeholder="Enter PI Number Hardware"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .pi_number_hardware
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          Delivery Date Hardware
                                        </Form.Label>
                                        <Form.Control
                                          type="date"
                                          name="date_of_delivery_hardware"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .date_of_delivery_hardware
                                                ? moment(
                                                    products[
                                                      currentProductIndex
                                                    ].date_of_delivery_hardware
                                                  ).format("YYYY-MM-DD")
                                                : ""
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Col>

                                {/* Accessories Row */}
                                <Col lg={12} className="mt-2">
                                  <Row>
                                    <Col lg={4}>
                                      <Form.Group>
                                        <Form.Label>Accessories</Form.Label>
                                        <Form.Select
                                          aria-label="Select Accessories Status"
                                          name="accessories"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .accessories
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        >
                                          <option value="">--Select--</option>
                                          <option value="ordered">
                                            Ordered
                                          </option>
                                          <option value="recieved">
                                            Received
                                          </option>
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          PI Number Accessories
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="pi_number_accessories"
                                          placeholder="Enter PI Number Accessories"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .pi_number_accessories
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          Delivery Date Accessories
                                        </Form.Label>
                                        <Form.Control
                                          type="date"
                                          name="date_of_delivery_accessories"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .date_of_delivery_accessories
                                                ? moment(
                                                    products[
                                                      currentProductIndex
                                                    ]
                                                      .date_of_delivery_accessories
                                                  ).format("YYYY-MM-DD")
                                                : ""
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Col>

                                {/* Iron Row */}
                                <Col lg={12} className="mt-2">
                                  <Row>
                                    <Col lg={4}>
                                      <Form.Group>
                                        <Form.Label>Iron</Form.Label>
                                        <Form.Select
                                          aria-label="Select Iron Status"
                                          name="iron"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .iron
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        >
                                          <option value="">--Select--</option>
                                          <option value="ordered">
                                            Ordered
                                          </option>
                                          <option value="recieved">
                                            Received
                                          </option>
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          PI Number Iron
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="pi_number_iron"
                                          placeholder="Enter PI Number Iron"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .pi_number_iron
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          Delivery Date Iron
                                        </Form.Label>
                                        <Form.Control
                                          type="date"
                                          name="date_of_delivery_iron"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .date_of_delivery_iron
                                                ? moment(
                                                    products[
                                                      currentProductIndex
                                                    ].date_of_delivery_iron
                                                  ).format("YYYY-MM-DD")
                                                : ""
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Col>

                                {/* PVC Profile Row */}
                                <Col lg={12} className="mt-2">
                                  <Row>
                                    <Col lg={4}>
                                      <Form.Group>
                                        <Form.Label>PVC Profile</Form.Label>
                                        <Form.Select
                                          aria-label="Select PVC Profile Status"
                                          name="pvc_profile"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .pvc_profile
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        >
                                          <option value="">--Select--</option>
                                          <option value="ordered">
                                            Ordered
                                          </option>
                                          <option value="recieved">
                                            Received
                                          </option>
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          PI Number PVC Profile
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="pi_number_pvc_profile"
                                          placeholder="Enter PI Number PVC Profile"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .pi_number_pvc_profile
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                      <Form.Group
                                        className="mx-3"
                                        controlId="formBasicTitle"
                                      >
                                        <Form.Label className="form-view-label">
                                          Delivery Date PVC Profile
                                        </Form.Label>
                                        <Form.Control
                                          type="date"
                                          name="date_of_delivery_pvc_profile"
                                          //Added by shivani 8 aug start
                                          value={
                                            currentProductIndex !== null
                                              ? products[currentProductIndex]
                                                  .date_of_delivery_pvc_profile
                                                ? moment(
                                                    products[
                                                      currentProductIndex
                                                    ]
                                                      .date_of_delivery_pvc_profile
                                                  ).format("YYYY-MM-DD")
                                                : ""
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleProductChange(
                                              currentProductIndex,
                                              e
                                            )
                                          }
                                          //Added by shivani 8 aug end
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col lg={12}>
                                  <Form.Group
                                    className="mx-3"
                                    controlId="formBasicLastName"
                                  >
                                    <Form.Label className="form-view-label">
                                      Notes & Remarks
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      name="remarks"
                                      placeholder="Enter Notes & Remarks"
                                      //Added by shivani 8 aug start
                                      value={
                                        currentProductIndex !== null
                                          ? products[currentProductIndex]
                                              .remarks
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleProductChange(
                                          currentProductIndex,
                                          e
                                        )
                                      }
                                      //Added by shivani 8 aug end
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Modal.Footer className="mt-2">
                                <Button
                                  // className="btn btn-secondary"
                                  variant="secondary"
                                  onClick={handleExternalProductClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  className="btn btn-primary mx-2"
                                  onClick={handleExternalProductClose}
                                >
                                  Save
                                </Button>
                              </Modal.Footer>
                            </Modal.Body>
                          </Modal>
                        </Card>
                      ))}
                    </Col>
                  </Row>
                </Container>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
};
export default ProjectEdit;
