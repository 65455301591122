import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import axios from "axios";
import useFileUpload from "react-use-file-upload";
import moment from "moment";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import PubSub from "pubsub-js";

const FilesEdit = (props,onFileUpdate) => {
  const [files, setFiles] = useState(props.file.row);
  const [filesProducts, setAllFiles] = useState({});
  const [quoteStatus, setQuoteStatus] = useState(props.file.row.quote_status);
  console.log("Props",props.table)
    useEffect(() => {
      async function init() {
        if(props.table === "Product" || props.table === "Contact"){
          let foldername = props.table === "Product" ?  "ProductImages" : "ContactImages" ;

          console.log("foldername",foldername)
          const activePrimaryImage = await WhiteObjectApi.fetchFiles(props.parent.id,foldername);
          console.log("activePrimaryImage",activePrimaryImage);
          setAllFiles(activePrimaryImage);
        }
     
      }
      init();
    }, []);
  
  console.log("filesProductsfilesProducts",filesProducts)

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check if product and parent exist, and if primary_image is set to true
    if(filesProducts!=null){
      if (props.table === "Product" || props.table === "Contact" ) {
          if (files.primary_image) {
            // Check if there's already a primary image set for the product
            const existingPrimaryImage = Object.values(filesProducts).find(
              (file) => file.primary_image === true
            );
      
            if (existingPrimaryImage) {
              PubSub.publish("RECORD_ERROR_TOAST", {
                title: "Record Saved",
                message: "Primary Image Already Exists for this Product",
              });
    
              return; // Prevent further execution
            }
        
        
        }
  
      }
    }
   
    if(quoteStatus === "Approved"){
      let obj = {}
      obj.id = props?.parent.id;
      // obj.quote_approval_date = moment().startOf('day').toISOString();
      obj.quote_approval_date = moment().toISOString();
      await WhiteObjectApi.saveProject(obj)
    }
    const result = await WhiteObjectApi.saveFiles(files);
    console.log("Data Before Update", result);
  
    if (result) {
      submitFiles();
    }
  };
    
  const submitFiles = () => {
    // props.parentsubmitFiles(); //Added by shivani
    console.log("proppropspropspropss", props)
    props.submitFiles();
  };

  const handleQuoteStatusChange = (event) => {
    const selectedQuoteStatus = event.target.value;
    setFiles({...files , quote_status : event.target.value })
    setQuoteStatus(selectedQuoteStatus);
    console.log("Quote Status:", selectedQuoteStatus);
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {props.table === "Product" || props.table === "Contact" && (
          <Row>
            <Col md={6}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Group controlId="formBasicTitle">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicTitle"
                  >
                    Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={files.title}
                    onChange={(e) =>
                      setFiles({ ...files, [e.target.name]: e.target.value })
                    }
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-view-label">
                Primary Image
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  id="primary_image_checkbox"
                  name="primary_image"
                  checked={files.primary_image}
                  onChange={(e) =>
                    setFiles({ ...files, [e.target.name]: e.target.checked })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        )} */}

        {/* NOt using Later */}
        <Row>
        <Col md={6}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Group controlId="formBasicTitle">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicTitle"
                  >
                    Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={files.title}
                    onChange={(e) =>
                      setFiles({ ...files, [e.target.name]: e.target.value })
                    }
                  />
                </Form.Group>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-view-label">
                  Primary Image
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  id="primary_image_checkbox"
                  name="primary_image"
                  checked={files.primary_image}
                  onChange={(e) =>
                    setFiles({ ...files, [e.target.name]: e.target.checked })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

        {/* End */}
        {/* {props.table !== "Product"  || props.table !== "Contact" && (
          <>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Group controlId="formBasicTitle">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicTitle"
                >
                  Title
                </Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Enter title"
                  value={files.title}
                  onChange={(e) =>
                    setFiles({ ...files, [e.target.name]: e.target.value })
                  }
                />
              </Form.Group>
            </Form.Group>
          </>
        )} */}
        {files.quote_status && (
          <Form.Group>
            <Form.Label className="form-view-label mt-2">
              Quote Status
            </Form.Label>
            <Form.Select
              aria-label="Select Quote Status"
              name="quote_status"
              onChange={handleQuoteStatusChange}
              value={quoteStatus}
            >
              <option value="PendingForApproved">Pending For Approved</option>
              <option value="Approved">Approved</option>
              <option value="Declined">Declined</option>
            </Form.Select>
          </Form.Group>
        )}
        <Form.Group controlId="formBasicDescription">
          <Form.Label
            className="form-view-label"
            htmlFor="formBasicDescription"
          >
            Description
          </Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            placeholder="Enter description"
            value={files.description}
            onChange={(e) =>
              setFiles({ ...files, [e.target.name]: e.target.value })
            }
          />
        </Form.Group>
        
      </Modal.Body>
      <Modal.Footer>
        <div className="submit">
          <Button variant="success" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
        <Button onClick={props.onHide} variant="light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default FilesEdit;
