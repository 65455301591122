import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Col, Row, Table, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import moment from "moment";
import Confirm from "../Confirm";
// import PricebookEdit from './PricebookEdit';

const RelatedListPricebook = ({ parent, refreshPricebookList }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [externalProducts, setExternalProducts] = React.useState("");
  const [modalShowExternalProductsModel, setExternalProductsModel] =
    useState(false);
  const [showExternalProductModel, setModalShowExternalProducts] =
    React.useState(false);

  const [body, setBody] = useState([]);
  useEffect(() => {
    pricebookList();
  }, []);

  useEffect(() => {
    pricebookList();
  }, [refreshPricebookList]);

  const pricebookList = () => {
    async function init() {
      let pricebooks = await WhiteObjectApi.fetchProductByProjectId(parent.id);
      if (pricebooks && pricebooks?.length > 0) {
        setBody(pricebooks);
      } else {
        setBody([]);
      }
    }
    init();
  };
  const handleDelete = (row) => {
    setModalShow(true);
    setExternalProducts(row);
  };

  const labels = {
    beforeSelect: " ",
  };

  const deletePricebook = async () => {
    const result = await WhiteObjectApi.deletePriceBook(externalProducts.id);
    if (result) {
      setExternalProducts("");
      setModalShow(false);
      pricebookList();
    }
  };

  const submitPricebooks = () => {
    setExternalProductsModel(false);
    pricebookList();
  };

  const showExternalData = (row) => {
    setExternalProductsModel(true);
    setExternalProducts(row.row);
  };

  const viewPricebook = (row) => {
    setModalShowExternalProducts(true);
    setExternalProducts(row);
  };

  console.log("Body Is it",body)

  const header = [
    { title: "Product Code", prop: "product_code", isSortable: false },
    { title: "Product Name", prop: "product_name", isSortable: true },
    { title: "Product Price Per", prop: "product_price", isSortable: true },
    { title: "Product Size", prop: "size", isSortable: true },
    { title: "Sqr Ft", prop: "product_qty", isSortable: true },
    // { title: 'Assigned User', prop: 'projectmanagername' ,isSortable : true},
    {
      title: "Created Date",
      prop: "createddate",
      cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
      isSortable: true,
    },
    { title: "Created By", prop: "createdbyname" },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button
            className="btn-sm mx-2"
            onClick={() => showExternalData({ row })}
          >
            <i class="fa-solid fa-eye"></i>
          </Button>
          {/* <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}><i className="fa-regular fa-trash-can"></i></Button> */}
        </>
      ),
    },
  ];

  const handleExternalProductClose = () => {
    setExternalProductsModel(false);
  };

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deletePricebook={deletePricebook}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="pricebook"
        />
      )}
      {/* {modalShowPricebook &&
          <PricebookEdit
            show={modalShowPricebook}
            onHide={() => setExternalProductsModel(false)}
            parent={parent}
            pricebooks={pricebook}
            submitPricebooks={submitPricebooks}
          />
        } */}

      {body ? (
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5,
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            ></Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />
        </DatatableWrapper>
      ) : (
        ""
      )}

      <Modal show={modalShowExternalProductsModel} size="xl">
        <Modal.Header>
          <Modal.Title>Products Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Category</th>
                <th>Status</th>
                <th>PI Number</th>
                <th>Delivery Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Glass</td>
                <td
                  style={{
                    backgroundColor:
                      externalProducts.glass === "ordered"
                        ? "#FA8072"
                        : externalProducts.glass === "recieved"
                        ? "#3CB371"
                        : "",
                    color: "black",
                  }}
                >
                  {externalProducts.glass === "ordered"
                    ? "Ordered"
                    : externalProducts.glass === "recieved"
                    ? "Received"
                    : ""}
                </td>
                <td>{externalProducts.pi_number_glass}</td>
                <td>
                  {externalProducts.date_of_delivery_glass
                    ? moment(externalProducts.date_of_delivery_glass).format(
                        "DD-MM-Y"
                      )
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Hardware</td>
                <td
                  style={{
                    backgroundColor:
                      externalProducts.hardware === "ordered"
                        ? "#FA8072"
                        : externalProducts.hardware === "recieved"
                        ? "#3CB371"
                        : "",
                    color: "black",
                  }}
                >
                  {/* {externalProducts.hardware} */}
                  {externalProducts.hardware === "ordered"
                    ? "Ordered"
                    : externalProducts.hardware === "recieved"
                    ? "Received"
                    : ""}
                </td>
                <td>{externalProducts.pi_number_hardware}</td>
                <td>
                  {externalProducts.date_of_delivery_hardware
                    ? moment(externalProducts.date_of_delivery_hardware).format(
                        "DD-MM-Y"
                      )
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Accessories</td>
                <td
                  style={{
                    backgroundColor:
                      externalProducts.accessories === "ordered"
                        ? "#FA8072"
                        : externalProducts.accessories === "recieved"
                        ? "#3CB371"
                        : "",
                    color: "black",
                  }}
                >
                  {externalProducts.accessories === "ordered"
                    ? "Ordered"
                    : externalProducts.accessories === "recieved"
                    ? "Received"
                    : ""}
                </td>
                <td>{externalProducts.pi_number_accessories}</td>
                <td>
                  {externalProducts.date_of_delivery_accessories
                    ? moment(
                        externalProducts.date_of_delivery_accessories
                      ).format("DD-MM-Y")
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Iron</td>
                <td
                  style={{
                    backgroundColor:
                      externalProducts.iron === "ordered"
                        ? "#FA8072"
                        : externalProducts.iron === "recieved"
                        ? "#3CB371"
                        : "",
                    color: "black",
                  }}
                >
                  {/* {externalProducts.iron} */}
                  {externalProducts.iron === "ordered"
                    ? "Ordered"
                    : externalProducts.iron === "recieved"
                    ? "Received"
                    : ""}
                </td>
                <td>{externalProducts.pi_number_iron}</td>
                <td>
                  {externalProducts.date_of_delivery_iron
                    ? moment(externalProducts.date_of_delivery_iron).format(
                        "DD-MM-Y"
                      )
                    : ""}
                </td>
              </tr>
              <tr>
                <td>PVC Profile</td>
                <td
                  style={{
                    backgroundColor:
                      externalProducts.pvc_profile === "ordered"
                        ? "#FA8072"
                        : externalProducts.pvc_profile === "recieved"
                        ? "#3CB371"
                        : "",
                    color: "black",
                  }}
                >
                  {/* {externalProducts.pvc_profile} */}
                  {externalProducts.pvc_profile === "ordered"
                    ? "Ordered"
                    : externalProducts.pvc_profile === "recieved"
                    ? "Received"
                    : ""}
                </td>
                <td>{externalProducts.pi_number_pvc_profile}</td>
                <td>
                  {externalProducts.date_of_delivery_pvc_profile
                    ? moment(
                        externalProducts.date_of_delivery_pvc_profile
                      ).format("DD-MM-Y")
                    : ""}
                </td>
              </tr>
            </tbody>
          </Table>

          <Form.Group className="mt-2" controlId="formBasicLastName">
            <Form.Label className="form-view-label">Notes & Remarks</Form.Label>
            <Form.Control
              as="textarea"
              name="remarks"
              // placeholder="Enter Notes & Remarks"
              value={externalProducts.remarks}
              disabled
            />
          </Form.Group>
          <Modal.Footer className="mt-2">
            <Button
              // className="btn btn-secondary"
              variant="secondary"
              onClick={handleExternalProductClose}
            >
              Close
            </Button>
            {/* <Button
                className="btn btn-primary mx-2"
                onClick={handleExternalProductClose}
              >
                  Save
                </Button> */}
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RelatedListPricebook;
