import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import { HEADERS } from "../../constants/CONSTANT";
import ProgressTracker from "./ProgressTracker";
import Main from "../layout/Main";

const Dashboard = () => {
  const [body, setBody] = useState([]);
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await WhiteObjectApi.fetchProject();
        if (result && Array.isArray(result)) {
          const updatedProjects = result.map((item) => ({
            ...item,
            isShowProduct: true, // Assuming you need this for filtering
          }));
          console.log("Fetched Projects:", result); // Add logging here
          setProjects(updatedProjects);
          setBody(updatedProjects); // Ensure `body` is set with the same data
        } else {
          setProjects([]);
          setBody([]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setProjects([]);
        setBody([]);
      }
    }
    fetchData();
  }, []);

  // const handleChange = (e) => {
  //   const value = e.target.value.trim();
  //   setSearch(value);
  //   setIsSearch(!value);
  // };
  

  // const handleSearch = () => {
  //   console.log("Searching for:", search);
  //   const filteredProjects = body.filter((item) =>
  //     item.project_name?.toLowerCase().includes(search.toLowerCase())
  //   );
  //   console.log("Filtered Projects:", filteredProjects);
  //   setProjects(filteredProjects);
  // };

  const handleChange = (e) => {
    const value = e.target.value; //Added by shivani 14 aug remove trim
    setSearch(value);
    setIsSearch(!value);
  };

  const handleSearch = () => {
    console.log("Searching for:", search);
    const filteredProjects = body.filter((item) =>
      item.project_name.toLowerCase().includes(search.toLowerCase().trim()) //Added by shivani 14 aug
    );
    console.log("Filtered Projects:", filteredProjects);
    setProjects(filteredProjects);
  };

  const handleClear = () => {
    console.log("Clearing search. Current projects:", body);
    setSearch("");
    setIsSearch(true);
    setProjects(body);
  };

  const getProgress = (status) => {
    switch (status) {
      case "Quote Sent": return 0;
      case "Quote Rejected": return 1;
      case "Quote Approved": return 2;
      case "Advance Received": return 3;
      case "Transfer To Production": return 4;
      case "Production InProgress": return 5;
      case "Production Completed": return 6
      case "Part Dispatched" : return 7
      case "Dispatched": return 8;
      case "Installation Started": return 9;
      case "Installation Completed": return 10;
      case "Fabricator Gets a Signed Slip": return 11;
      case "Payment Received": return 12;
      case "Project Complete": return 13;
      default: return 0;
    }
  };

  return (
    <Main>
      <Container>
        <Row>
          <Col lg={2} className="mx-3"></Col>
          <h4 className="text-center mb-4">Project Stages Report</h4>
        </Row>
        <Row className="text-center">
          <Col lg={4} style={{ marginLeft: "267px" }}>
            <Form.Control
              type="text"
              placeholder="Search by Project Name"
              value={search}
              onChange={handleChange}
            />
          </Col>
          <Col style={{ marginRight: "420px" }}>
            <Button
              className="btn-md"
              disabled={isSearch}
              onClick={handleSearch}
            >
              Search
            </Button>
            <Button
              variant="link"
              className="btn-md"
              onClick={handleClear}
              disabled={isSearch}
            >
              Clear
            </Button>
          </Col>
        </Row>
        <Row className="mx-3 kanban" style={{ marginTop: "3rem" }}>
          <Row>
            <Col lg={2}></Col>
            <Col lg={10} style={{ paddingLeft: "3rem" }}>
              <Row>
                {HEADERS.map((item) => (
                  <Col
                    className="kanban-column-title"
                    key={item.headerName}
                    style={{
                      backgroundColor: item.backgroundColor,
                      color: item.color,
                    }}
                  >
                    {item.headerName}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          {projects.map((project) => (
            <Row
              key={project.id}
              className="mt-2"
              style={{ border: "2px solid black" }}
            >
              <Col
                lg={2}
                className="project-col mt-3 mb-2 pb-2"
                style={{
                  backgroundColor: "#6c757d",
                  padding: ".5rem",
                  color: "#fff",
                  borderLeft: "10px solid rgb(241 170 42)",
                }}
              >
                {project.project_name}
              </Col>
              <Col lg={10} style={{ paddingLeft: "3rem" }}>
                <Row className="flex-nowrap" style={{ overflowX: "auto" }}>
                  <Col
                    key={project.project_status}
                    className="kanban-item-col"
                    style={{ marginTop: "10px", flex: "0 0 auto" }}
                  >
                    <ProgressTracker
                      currentStep={getProgress(project.project_status)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Row>
      </Container>
    </Main>
  );
};

export default Dashboard;