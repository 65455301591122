import React, { useRef, useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import WhiteObjectApi from "../../api/WhiteObjectApi";
import { useNavigate } from "react-router-dom";
import { LEAD_STATUS_VALUES } from "../../constants/CONSTANT";
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

const DonutChart = ({filterDateData}) => {
  console.log("Donout Chart",filterDateData);
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const [refreshPage,setRefreshPage] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Lead Stage",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  });

  useEffect(() => {
    fetchLeadsData(filterDateData);
    setRefreshPage(true);
 }, [filterDateData]); 

  // Function to assign colors based on lead statuses
  const assignColors = (labels) => {
    const leadStatusMap = LEAD_STATUS_VALUES.reduce((acc, status) => {
      acc[status.label] = status.backgroundColor;
      return acc;
    }, {});

    const backgroundColor = labels.map((label) => {
      return leadStatusMap[label] || "#CCCCCC"; // Default color if not found
    });

    const borderColor = backgroundColor.map((color) =>
      color.replace("0.2", "1")
    );

    return { backgroundColor, borderColor };
  };


    
  
  
  async function fetchLeadsData(filterDateData) {
    try {
      
      console.log("Donout Chart Filter on Constructor",filterDateData)

      if (filterDateData) {
        console.log("Month:", filterDateData.month);
        console.log("Year:", filterDateData.year);
      } else {
        console.error("filterDateData is undefined or null");
      }

      if(filterDateData  && filterDateData.month &&  filterDateData.year){
        console.log("Logs excuted")
        const fetchLeads = await WhiteObjectApi.fetchLeadCurrentMonth(filterDateData);
        console.log("Fetch Leads", fetchLeads);

        if(fetchLeads.length > 0){
          const stageCount = fetchLeads.reduce((acc, lead) => {
            acc[lead.leadstatus] = (acc[lead.leadstatus] || 0) + 1;
            return acc;
          }, {});
    
          const labels = Object.keys(stageCount);
          const data = Object.values(stageCount);
          const { backgroundColor, borderColor } = assignColors(labels);
    
          setChartData({
            labels: labels,
            datasets: [
              {
                ...chartData.datasets[0],
                data: data,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
              },
            ],
          });
          }
        }
      
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  }

  const options = {
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
      datalabels: {
        color: '#fff',
        formatter: (value, context) => {
          return value;
        },
      },
    },
    layout: {
      padding: {
        bottom: 10,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const leadStatus = chartData.labels[index];
        onLeadStatusClick(leadStatus);
      }
    },
  };
  
  

  const onLeadStatusClick = (status) => {
    console.log("STATUS", status);
    navigate("/leads", { state: { leadStatus: status } });
  };

  return (
    <div
      style={{
        width: "600px",
        height: "400px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {refreshPage && 
        <div style={{ width: "100%", height: "100%" }}>
          <Doughnut ref={chartRef} data={chartData} options={options} />
        </div>
      }
      
    </div>
  );
};

export default DonutChart;